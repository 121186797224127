import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Button,
  FormHelperText,
  Autocomplete
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';

import FormHelpText from 'rsuite/esm/FormHelpText';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { /* getAlldvertiserList, */ getCampaignNameExist } from 'store/thunk/userThunk';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import CampaignBrandContext from 'context/brand';
import { useContext } from 'react';

const CampaignSettingStep = () => {
  const navigate = useNavigate();
  const { allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const { campaign, setCampaign, handleForwordButtonClick, setCampaignData, campaignData, dateType, setDateType } =
    useContext(CampaignBrandContext);

  const dispatch = useAppDispatch();
  const [budgetType, setBudgetType] = useState('both');
  const [dailyBudget, setDailyBudget] = useState(null);
  const [advertiserType, setAdvertiserType] = useState('');
  const [nameValid, setNameValid] = useState(false);

  const selectedProfiles = localStorage.getItem('selectedProfiles');

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // let data = "";
    // dispatch(getAlldvertiserList({ data }));

    if (campaign?.advertiser) {
      const advertiseObjArray = allUserDetails.filter((obj) => obj.id == campaign?.advertiser);
      if (advertiseObjArray?.length) {
        const advertiseObj = advertiseObjArray[0];
        setAdvertiserType(advertiseObj?.advertiser_type || '');
      }
    }
  }, []);

  const dailyBudgetValid = advertiserType == '3p' ? 'For 3p, Minimum daily budget is 10' : 'For 1P, Minimum daily budget is 50';
  const totalBudgetValid = advertiserType == '3p' ? 'For 3p, Minimum total budget is 50' : 'For 1P, Minimum total budget is 100';

  const formSchema = Yup.object().shape({
    name: nameValid
      ? Yup.string()
          .required('Name is required')
          .test('name', 'Name is already exist.', function () {
            return false;
          })
      : Yup.string().required('Name is required'),
    advertiser: Yup.string().required('Advertiser is required'),
    budget_type: Yup.string().required('Budget Type is required'),
    daily_budget:
      budgetType == 'daily' || budgetType == 'both'
        ? Yup.string()
            .required('Daily Budget is required')
            .test('daily_budget', dailyBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 10 : Number(value) >= 50;
            })
        : '',
    total_budget:
      budgetType == 'total' || budgetType == 'both'
        ? Yup.string()
            .required('Total Budget is required')
            .test('total_budget', 'Total budget is greter than daily', function (value) {
              return Number(value) >= Number(dailyBudget);
            })
            .test('total_budget', totalBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 50 : Number(value) >= 100;
            })
        : '',
    start_date: dateType == 'select' ? Yup.string().required('Date is required').nullable() : null,
    end_date: Yup.string().when('start_date', (start_date, schema) => {
      if (start_date) {
        return schema.test('is-after-start', 'End date must be greater than start date', function (end_date) {
          if (!end_date) {
            return true;
          } else {
            return dayjs(end_date).isAfter(dayjs(start_date));
          }
        });
      }
      return schema;
    })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: campaign?.name,
      advertiser: campaign?.advertiser,
      budget_type: campaign?.budget_type,
      daily_budget: campaign?.daily_budget,
      total_budget: campaign?.total_budget,
      start_date: campaign?.start_date,
      end_date: campaign?.end_date
    }
  });

  useEffect(() => {
    const campaign_name = watch('name');
    dispatch(getCampaignNameExist({ campaign_name })).then((res) => {
      if (res?.payload?.data?.error) {
        setNameValid(true);
      } else {
        setNameValid(false);
      }
    });
  }, [watch('name')]);

  const startDateValue = watch('start_date');

  useEffect(() => {
    const budget_type_value = watch('budget_type');
    if (budget_type_value) {
      setBudgetType(budget_type_value);
    }
  }, [watch('budget_type')]);

  useEffect(() => {
    const dailyBudgetValue = watch('daily_budget');
    if (dailyBudgetValue) {
      setDailyBudget(dailyBudgetValue);
    }
  }, [watch('daily_budget')]);

  //Auto select First Profile while page load and we have allUserDetails
  useEffect(() => {
    if (allUserDetails?.length) {
      if (selectedProfiles) {
        const selectedProfileFirstId = selectedProfiles?.split(',')?.pop();
        setValue('advertiser', selectedProfileFirstId, { shouldValidate: true });
      } else {
        setValue('advertiser', null);
      }
    }
  }, [allUserDetails, selectedProfiles]);

  useEffect(() => {
    const advertiserTypeValue = watch('advertiser');
    if (advertiserTypeValue) {
      const advertiseObjArray = allUserDetails.filter((obj) => obj.id === advertiserTypeValue);
      if (advertiseObjArray?.length) {
        const advertiseObj = advertiseObjArray[0];
        setAdvertiserType(advertiseObj?.advertiser_type || '');
      }
    }
  }, [watch('advertiser')]);

  const onButtonClick = (event) => {
    var _request = {};
    if (event.start_date && dateType == 'select') {
      _request = {
        ...campaign,
        ...event,
        daily_budget: Number(event.daily_budget),
        total_budget: Number(event.total_budget),
        advertiser: Number(event.advertiser)
      };
      if (!event.end_date) {
        delete _request['end_date'];
      }
    } else {
      _request = {
        ...campaign,
        ...event,
        daily_budget: Number(event.daily_budget),
        total_budget: Number(event.total_budget),
        advertiser: Number(event.advertiser)
      };
      delete _request['start_date'];
      delete _request['end_date'];
    }

    setCampaign({
      ...campaign,
      name: event.name,
      advertiser: event.advertiser,
      budget_type: event.budget_type,
      daily_budget: event.daily_budget,
      total_budget: event.total_budget,
      start_date: event.start_date,
      end_date: event.end_date,
      advertiserType: advertiserType
    });

    setCampaignData({ ...campaignData, campaign: _request });

    handleForwordButtonClick();
  };

  return (
    <form noValidate onSubmit={handleSubmit(onButtonClick)}>
      <Box>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item sm={6}>
            {/* {renderLabel('Campaign Name', true)} */}
            <Controller
              name={'name'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...register('name')}
                  {...field}
                  name={'name'}
                  fullWidth
                  type="text"
                  label="Campaign Name"
                  variant="outlined"
                  error={errors?.name}
                  helperText={errors?.name?.message}
                />
              )}
            />
          </Grid>

          <Grid item sm={6}>
            {/* {renderLabel('Profile', true)} */}
            <Controller
              name="advertiser"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...register('advertiser')}
                  {...field}
                  value={allUserDetails.find((obj) => obj.id == field.value) || null}
                  onChange={(e, value) => (value ? field.onChange(value?.id) : field.onChange(''))}
                  options={allUserDetails || []}
                  getOptionLabel={(option) => {
                    return option?.name || '';
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profile"
                      error={Boolean(errors.advertiser)}
                      helperText={errors.advertiser?.message}
                    />
                  )}
                />
              )}
            />
            {/* {renderLabel("Profile", true)}
                            <Controller
                                name={'advertiser'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...register("advertiser")}
                                            {...field}
                                            fullWidth
                                            name="advertiser"
                                            error={errors?.advertiser}
                                        >
                                            {allUserDetails?.map((item) => {
                                                return (<MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem >)
                                            })}
                                        </Select>
                                        {errors?.advertiser && (<FormHelperText error sx={{ margin: "3px 14px 0px 14px" }}>{errors?.advertiser?.message}</FormHelperText>)}
                                    </>
                                )}
                            /> */}
          </Grid>

          <Grid item sm={6}>
            {/*renderLabel('Budget Type', true)*/}
            <Controller
              name={'budget_type'}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth error={!!errors?.budget_type}>
                  <InputLabel>Budget Type</InputLabel>
                  <Select
                    {...field}
                    label="Budget Type"
                    {...register('budget_type')}
                    fullWidth
                    name="budget_type"
                    error={errors?.budget_type}
                  >
                    <MenuItem value="daily">Daily Budget</MenuItem>
                    {/* <MenuItem value="total">Total Budget</MenuItem> */}
                    <MenuItem value="both">Both</MenuItem>
                  </Select>
                  {errors?.budget_type && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      {errors?.budget_type?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Box sx={{ padding: 0.5 }}>
              <FormHelpText>Once a campaign becomes live, budget type cannot be changed</FormHelpText>
            </Box>
          </Grid>
          {(budgetType == 'daily' || budgetType == 'both') && (
            <Grid item sm={6}>
              {/* {renderLabel('Daily Budget', true)} */}
              <Controller
                name={'daily_budget'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...register('daily_budget')}
                    fullWidth
                    type="number"
                    label="Daily Budget"
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    name="daily_budget"
                    error={errors?.daily_budget}
                    helperText={errors?.daily_budget?.message}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />
                )}
              />
              <Box sx={{ padding: 0.5 }}>
                <FormHelpText>For 3p, Minimum Daily Budget is 10; for 1P, Minimum Daily Budget is 50.</FormHelpText>
              </Box>
            </Grid>
          )}
          {(budgetType == 'total' || budgetType == 'both') && (
            <Grid item sm={6}>
              {/* {renderLabel('Total Budget', true)} */}
              <Controller
                name={'total_budget'}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    {...register('total_budget')}
                    type="number"
                    label="Total Budget"
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    name="total_budget"
                    error={errors?.total_budget}
                    helperText={errors?.total_budget?.message}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />
                )}
              />
              <Box sx={{ padding: 0.5 }}>
                <FormHelpText>For 3p, Minimum Daily Budget is 50; for 1P, Minimum Daily Budget is 100.</FormHelpText>
              </Box>
            </Grid>
          )}

          <Grid item sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 2 }}>{renderLabel('Date Range', true)}</Box>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="any"
                  name="radio-buttons-group"
                  value={dateType}
                  onChange={(e, value) => {
                    setDateType(value);
                  }}
                >
                  <FormControlLabel value="any" control={<Radio />} label="Any Date Range" />
                  <FormControlLabel value="select" control={<Radio />} label="Select Date Range" />
                </RadioGroup>
              </FormControl>
            </Box>
            {dateType == 'select' && (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ mr: 5 }}>
                  <Controller
                    name={'start_date'}
                    sx={{
                      background: 'red',
                      width: '100%'
                    }}
                    control={control}
                    render={() => (
                      <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register('start_date')}
                          error={errors?.start_date}
                          minDate={dayjs(new Date())}
                          defaultValue={campaign.start_date ? dayjs(campaign.start_date) : dayjs(new Date())}
                          closeOnSelect={true}
                          onChange={(value) => {
                            const dayjsDate = dayjs(value.$d);
                            const formattedDate = dayjsDate.format('YYYY-MM-DD');
                            setValue('start_date', formattedDate);
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.start_date && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      {errors?.start_date?.message}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <Controller
                    name={'end_date'}
                    control={control}
                    render={() => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register('end_date')}
                          error={errors?.end_date}
                          defaultValue={campaign.end_date ? dayjs(campaign.end_date) : null}
                          minDate={startDateValue ? dayjs(startDateValue).add(1, 'day') : dayjs(new Date()).add(1, 'day')}
                          onChange={(value) => {
                            const dayjsDate = dayjs(value.$d);
                            const formattedDate = dayjsDate.format('YYYY-MM-DD');
                            setValue('end_date', formattedDate);
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.end_date && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      {errors?.end_date?.message}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
          <>
            <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
              CANCEL
            </Button>
            {/* <Button variant="outlined" color="secondary" sx={{ ml: 1 }} >PREVIOUS</Button> */}
            <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit">
              NEXT
            </Button>
          </>
        </Box>
      </Box>
    </form>
  );
};

export default CampaignSettingStep;
