import React from 'react';
import { useParams } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import MainCard from 'ui-component/cards/MainCard';
import ProposalIcon from '../../assets/images/dashna/proposal.svg';
import ScheduledIcon from '../../assets/images/dashna/scheduled.svg';
import PaushedBudgetManager from '../../assets/images/dashna/paushedBudgetManager.svg';
import CompletedIcon from '../../assets/images/dashna/completed.svg';
import PausedIcon from '../../assets/images/dashna/paused.svg';
import EnabledIcon from '../../assets/images/dashna/enabled.svg';
import viewcolumnsIcon from 'assets/images/dashna/viewcolumns.svg';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Grid,
  Autocomplete,
  TextField,
  Select,
  Checkbox,
  InputAdornment,
  Link,
  InputLabel,
  FormControl,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  RadioGroup,
  Radio,
  OutlinedInput,
  Switch,
  TableFooter,
  TableCell,
  Chip
} from '@mui/material';
import CustomAutocomplete from '../../ui-component/multi-select/CustomAutocomplete';
import CampaignModal from './campaignModal';
import { setCampaignDetails, setSkeletonLoader } from 'store/slices/userDetailsSlice';

//====== Colors ======
import colors from 'assets/scss/_themes-vars.module.scss';

import {
  CampaignPlacementAutoManual,
  FilterStatusEnableAndPause,
  getCampaignDetails,
  getCampaignNameList,
  getSelectedFilterList,
  postCampaignReview,
  putCancelReview,
  updateCampaignModal,
  updateMultipleBudgetCampaign,
  updateMultipleEndCampaign,
  updateMultiplePlatformCampaign
} from 'store/thunk/userThunk';
import { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import EditCampaignSetting from './editProduct/editCampaignSetting';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ProductAdGroupSetting from './editProduct/editAdgroupItems';
import TargetAdGroupSetting from './editProduct/editKeywordItems';
import {
  // setCommonLoader,
  setExistingAdgroupId,
  setExistingBrandId,
  setExistingCampaignId,
  setReviewLogData,
  setAdgroupList
} from 'store/slices/userDetailsSlice';
import EditCampaignBrandSetting from './editBrand/editCampaignSetting';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ReviewLogModal from './editBrand/editCampaignSetting/reviewLogModal';
import BasicPopover from 'ui-component/popover';
import CampaignSingleEdit from './campaignSingleEdit';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { CircularProgress } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import EditPlacementModal from './editProduct/editPlacementModal';
import EditPlateformModal from './editProduct/editPlatformModal';
import {
  alignRightColumn,
  commaStringToNumber,
  formateValue,
  formatNumber,
  formateValueCampaignTotal,
  getUpdatedColumns
} from 'helper/commonHelper';
import { Loader } from 'rsuite';
import EditCampaignVideoSetting from './editVideo';
import { endDateValidator } from 'helper/commonHelper';
import { CAMPAIGN } from 'constant/campaignConstant';
import CustomFilters from 'ui-component/Filters';
import { PageValue, attributeWindowList, signList, sortByList, campaigState } from 'store/constant';
import FilterPerformance from 'views/filterPerformance';
import FilterAdvance from 'views/filterAdvance';
import { NestedDropdown } from 'mui-nested-menu';
import { styled } from '@mui/material/styles';
import ProductAdMultipleItems from './multipleAdditems';
import TargetAdGroupSettingKeyword from './addMultipleKeyword';
import DownloadIcon from 'assets/images/dashna/Arrow.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltipPlacement from './uiComponenet';
import FilterAdvanced from 'views/filterAdvanced';
import SuccessAndFail from 'ui-component/SuccessAndFailModal';
import CustomColumnSelector from 'ui-component/CustomColumnSelector';
import { TableRow } from '@mui/material';
import CompareValueWithColor from 'ui-component/compareWithValue';
import upIcon from 'assets/images/dashna/up.svg';
import downIcon from 'assets/images/dashna/down.svg';
import __ from 'underscore';
import editIcon from 'assets/images/dashna/edit.svg';
import closeIcon from 'assets/images/dashna/close.svg';
import correctIcon from 'assets/images/dashna/correct.svg';
import plusSimpleIcon from 'assets/images/dashna/plusSimple.svg';
import plusIcon from 'assets/images/dashna/plus.svg';
import viewBlackIcon from 'assets/images/dashna/viewBlack.svg';
import lineIcon from 'assets/images/dashna/line.svg';
import { v4 as uuidv4 } from 'uuid';
import searchIcon from 'assets/images/dashna/search.svg';
import { Add } from '@mui/icons-material';
import MultiSelectIcon from 'assets/images/dashna/multiselect.svg';
import arrowDownIcon from 'assets/images/dashna/ArrowDown.svg';
import sendIcon from 'assets/images/dashna/send.svg';
import cancelSendIcon from 'assets/images/dashna/cancelSend.svg';
import actionIcon from 'assets/images/dashna/Actions.svg';
import TableSkeleton from 'ui-component/skeleton/TableSkeleton';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#b2b2b2' : '#f2b20c',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
    marginLeft: 1.5
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#b2b2b2' : '#f2b20c',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const Campaign = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [isLoadedSingleCampaignData, setIsLoadedSingleCampaignData] = useState(false);
  const [calenderView, setCalenderView] = useState('day');
  const [selectedCalenderDate, setSelectedCalenderDate] = useState('');
  const [successFailModal, setSuccessFailModal] = useState(false);
  const [SuccessFailData, setSuccessFailData] = useState([]);
  const [modalOpen, setModalOpen] = useState();
  const [campaignModal, setCampaignModal] = useState({ open: false, id: null });
  const [campaignEditModal, setCampaignEditModal] = useState({ open: false, id: null, name: '' });
  const [campaignBrandModal, setCampaignBrandModal] = useState({ open: false, id: null });
  const [campaignVideoModal, setCampaignVideoModal] = useState({ open: false, id: null });
  const [itemModal, setItemModal] = useState({ open: false, id: null, advertiser_id: null });
  const [reviewModal, setReviewModal] = useState({ open: false });
  const [reviewLogModal, setReviewLogModal] = useState({ open: false, id: null, name: null });
  const [placementEditModal, setPlacementEditModal] = useState({ open: false, data: null });
  const [plateformEditModal, setPlateformEditModal] = useState({ open: false, data: null });
  const [searchCampaign, setSearchCampaign] = useState('');
  const [searchItemId, setSearchItemId] = useState('');
  const [itemDropdown, setItemDropdown] = useState('contains_or');
  const [tableLoading, setTableLoading] = useState(false);
  const [keywordModal, setKeywordModal] = useState({ open: false, id: null, name: '' });
  const dispatch = useAppDispatch();
  const [rowPerPage, setRowPerPage] = useState(25);
  const [targetingType, setTargetingType] = useState('');
  const [
    ,
    // isManual
    setIsManual
  ] = useState('');
  const [search, setSearch] = useState('');
  const [selectedAdgroup, setSelectAdgroup] = useState({ advertiser: null, campaign: null });
  const [page, setPage] = useState(0);
  const [filterDataList, setFilterDataList] = useState({});
  const [ProfileNameModal, setProfileNameModal] = useState();
  const [ProfileModal, setProfileModal] = useState(false);
  const [checkUniqueTypeSvSb, setCheckUniqueTypeSvSb] = useState();
  const [ProfileNameModalKeyword, setProfileNameModalKeyword] = useState();
  const [OpenAddKeywordModalSbSv, setOpenAddKeywordModalSbSv] = useState();
  const [ProfileModalKeyword, setProfileModalKeyword] = useState(false);
  const [PassDataItem, setDataItem] = useState();
  const [PassDataKeyword, setPassDataKeyword] = useState();
  const [isCsvDownloadComplete, setIsCsvDownloadComplete] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const {
    campaignDetails,
    campaignDetailsTotal,
    filteredData,
    commonAdvertiser,
    commonDateRange,
    campaignNameList,
    skeletonLoader,
    isComparingDates
  } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const previousDateRange = useRef(commonDateRange);
  const isFirstRender = useRef(true);
  const shouldApplyFilter = useRef(true);
  const campaignPermission = userData?.permission?.campaign || {};

  const {
    // video_management,
    update,
    create,
    download
  } = campaignPermission;
  const selectedProfiles = localStorage.getItem('selectedProfiles');
  const [filterValue, setFilterValue] = useState({ profile: [], type: [], status: [], campaign: [], attr_windows: [] });
  const [tableFilter, setTableFilter] = useState({
    profile: '',
    type: '',
    status: '',
    campaign: '',
    targeting: '',
    attr_windows: ''
  });
  const [filterForSearch, setFilterForSearch] = useState({});
  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    campaign_search: [],
    campaign_type: [],
    status: []
  });
  const [PerformanceFilterData, setPerformanceFilterData] = useState(); //When we clicked on Search Button
  const [performanceRanges, setPerformanceRanges] = useState({}); //when we change Performance Input

  const [edit, setEdit] = useState({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });

  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [
    ,
    // hoveredRow,
    setHoveredRow
  ] = useState(null);
  const [inlineLoader, setInlineLoader] = useState(null);
  const [attribute, setAttribute] = useState('14');
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const [searchText, setSearchText] = useState({ campaign_search: null, campaign_type: null, status: null });
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedCapName, setSelectedCapName] = useState([]);
  const [SelectedBudget, setSelectedBudget] = useState([]);
  const [SelectedPlacement, setSelectedPlacement] = useState([]);
  const [showDropdown, SetShowDropDown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenCamType, setIsDropdownOpenCamType] = useState(false);
  const [isDropdownOpenCamSt, setIsDropdownOpenCamSt] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [savedDataForEdit, setSaveDataForEdit] = useState({});
  const [EditEndDateMultiple, setEditEndDateMultiple] = useState('2024-02-26');
  const [trueValue, setTrueValue] = useState(true);
  const [viewColumnsSelector, setViewColumnsSelector] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [selectedData, setSelectedData] = useState(null); // State for selected row data when click on three dot
  const [tableHeight, setTableHeight] = useState('550px');
  const [advanceRanges, setAdvanceRanges] = useState({});
  const [advanceRangesForSearch, setAdvanceRangesForSearch] = useState({});
  const [ErrorShow, setErrorShow] = useState([]);
  const [finalErrorShow, setFinalShow] = useState(false);

  /* ======================= For Viewing Single Campaign Information START ======================= */
  useEffect(() => {
    if (campaignNameList.length && commonAdvertiser.length && campaignId) {
      const selectedCampaign = campaignNameList?.filter((item) => item?.campaign_id === Number(campaignId));
      const campaignPrimaryId = selectedCampaign?.[0]?.id?.toString();
      if (selectedCampaign?.length) {
        // dispatch(setCommonLoader(true));
        let tableStateFilterKey = 'campaign__in';
        setFilterValue((prevState) => ({
          ...prevState,
          [tableStateFilterKey]: selectedCampaign
        }));
        setTableFilter((prevState) => ({
          ...prevState,
          [tableStateFilterKey]: campaignPrimaryId
        }));
      }
    }
  }, [campaignId, commonAdvertiser, campaignNameList]);

  useEffect(() => {
    if (campaignId && tableFilter?.campaign__in && filterValue?.campaign__in?.length && !isLoadedSingleCampaignData) {
      setShowAdviser(true);
      handleSearchClick();
      setIsLoadedSingleCampaignData(true);
    }
  }, [campaignId, tableFilter, filterValue]);

  /* ======================= For Viewing Single Campaign Information END ======================= */

  //for resize table based on screen size
  useEffect(() => {
    const handleResize = () => {
      const calculatedHeight = window.innerHeight * 0.8;
      setTableHeight(`${calculatedHeight}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuItemsData = {
    label: 'Bulk Edit',
    items: [
      {
        label: 'Setting',
        // leftIcon: <SaveAsIcon />,
        items: [
          {
            label: 'Daily Budget Setting',
            value: 1,
            // rightIcon: <SaveAsIcon />,
            callback: (event, item) => handleChange(item.value)
            // disabled={SelectedBudget?.DailyData?.DailyName?.length == 0},
          },
          {
            label: 'Total Budget Setting',
            value: 2,
            // leftIcon: <SaveAsIcon />,
            callback: (event, item) => handleChange(item.value)
            // disabled={SelectedBudget?.TotalData?.DailyName?.length == 0},
          },
          {
            label: 'End Date Setting',
            value: 6,
            // leftIcon: <SaveAsIcon />,
            callback: (event, item) => handleChange(item.value)
            // disabled: true
          },
          {
            label: 'Platform Bid Multiplier Setting',
            value: 10,
            // leftIcon: <SaveAsIcon />,
            callback: (event, item) => handleChange(item.value)
            // disabled: true
          },
          {
            label: trueValue ? (
              <CustomTooltipPlacement
                content="Placement Bid Multiplier Setting"
                tooltipMessage={
                  checkUniqueTypeSvSb?.length == 1
                    ? 'Campaign type sb and sv is not applicable for placement bid multiplier setting'
                    : 'Different campaign types cannot be edited at the same time.'
                }
                color="gray"
              />
            ) : (
              <Typography>Placement Bid Multiplier Setting </Typography>
            ),
            value: trueValue ? 100 : 11,
            // leftIcon: <SaveAsIcon />,
            callback: (event, item) => handleChange(item.value)
            // disabled: trueValue
          }
          // {
          //   label: 'Placement Bid Multiplier Setting',
          //   value: 11,
          //   // leftIcon: <SaveAsIcon />,
          //   callback: (event, item) => handleChange(item.value),
          //   disabled: trueValue
          // }
        ]
      },
      {
        label: 'Change State',
        // leftIcon: <SaveAsIcon />,
        items: [
          {
            label: 'Paused',
            value: 7,

            // rightIcon: <SaveAsIcon />,
            callback: (event, item) => handleChange(item.value)
          },
          {
            label: 'Enabled',
            value: 8,
            // rightIcon: <SaveAsIcon />,
            callback: (event, item) => handleChange(item.value)
          }
        ]
      },
      {
        label: 'Add Items',
        value: 'AddItem',
        callback: (event, item) => handleChange(item.value)
      },
      {
        label:
          (ProfileNameModalKeyword && ProfileNameModalKeyword.includes('auto')) ||
          (OpenAddKeywordModalSbSv && OpenAddKeywordModalSbSv.includes('sponsoredProducts auto')) ? (
            <CustomTooltipPlacement
              color="gray"
              content="Add Keywords"
              tooltipMessage={'Keywords cannot be added to auto campaigns.'}
            />
          ) : (
            'Add Keywords'
          ),
        value:
          (ProfileNameModalKeyword && ProfileNameModalKeyword.includes('auto')) ||
          (OpenAddKeywordModalSbSv && OpenAddKeywordModalSbSv.includes('sponsoredProducts auto'))
            ? 100
            : 'AddKeyword',
        callback: (event, item) => handleChange(item.value)
      }
      // {
      //   label: 'Add Items',
      //   value: 'AddItem',
      //   callback: (event, item) => handleChange(item.value)
      // },
      // {
      //   label: 'Add Keywords',
      //   value: 'AddKeyword',
      //   callback: (event, item) => handleChange(item.value)
      // }
    ]
  };

  const openAction = Boolean(anchorElAction);
  const ITEM_HEIGHT = 50;

  const setInitialPageFilters = () => {
    setFilterValue({});
    setTableFilter({});
    setFilterForSearch({});
    setPerformanceFilterData({});
    setPerformanceRanges({});
    setSearchText({}); //Reset Type Text in Autocomplete Dropdown Filter
    setSearchCampaign(''); //Reset Search Item Id
    setAdvanceRanges({});
    setAdvanceRangesForSearch({});
    setSearchItemId('');
    setItemDropdown('contains_or');
    // setFocused(false);
  };

  //Reset filters when we change Profile
  useMemo(() => {
    if (!shouldApplyFilter?.current) {
      setInitialPageFilters();
    }
  }, [shouldApplyFilter?.current]);

  /* ================================ Autocomplete Filters Start ================================*/

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'campaign_search') {
      paramKey = 'id';
    } else if (filterKey === 'campaign_type' || filterKey === 'status') {
      paramKey = 'value';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'campaign_type') {
      baseArray = [...campaign_type];
      tableStateFilterKey = 'type';
    } else if (filterKey === 'status') {
      baseArray = [...campaigState];
      tableStateFilterKey = 'status';
    }

    let targetString = '';
    if (filterKey === 'campaign_type') {
      const targetArray = newValue?.map((item) => item?.type);
      targetString = targetArray.filter((item) => item !== undefined).join(',');
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: '' }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: targetString }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'campaign_type') {
      baseArray = [...campaign_type];
      tableStateFilterKey = 'type';
    } else if (filterKey === 'status') {
      baseArray = [...campaigState];
      tableStateFilterKey = 'status';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  useEffect(() => {
    setTableLoading(skeletonLoader);
  }, [skeletonLoader]);

  useEffect(() => {
    if (selectedArray) {
      if (selectedArray.length >= 1) {
        SetShowDropDown(true);
      } else {
        SetShowDropDown(false);
      }
    }
  }, [selectedArray]);

  useEffect(() => {
    if (filteredData?.length > 0) {
      if (filteredData[0]?.campaign_column) {
        let tempColumns = [...columns];
        filteredData[0]?.campaign_column?.map((col) => {
          const columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
          if (columnsIndex !== -1) {
            tempColumns[columnsIndex].options.display = col?.display;
          }
        });
        setSelectedColumns(tempColumns);
      }

      setFilterDataList(filteredData[0]?.campaign_filter);

      let TemptableFilter = { ...tableFilter };
      let TempFilterValue = { ...filterValue };

      if (filteredData[0]?.campaign_filter?.Common?.findIndex((item) => item?.value === 'campaign') === -1) {
        TemptableFilter.campaign__in = '';
        TempFilterValue.campaign__in = [];
      }
      if (filteredData[0]?.campaign_filter?.Common?.findIndex((item) => item?.value === 'campaign_type') === -1) {
        TemptableFilter.type = '';
        TemptableFilter.targeting = '';
        TempFilterValue.type = [];
      }
      if (filteredData[0]?.campaign_filter?.Common?.findIndex((item) => item?.value === 'state') === -1) {
        TemptableFilter.status = '';
        TempFilterValue.status = [];
      }
      if (filteredData[0]?.campaign_filter?.Common?.findIndex((item) => item?.value === 'group_by') === -1) {
        TemptableFilter.attr_windows = '';
        TempFilterValue.attr_windows = [];
      }
      if (filteredData[0]?.campaign_filter?.Advanced?.findIndex((item) => item?.value === 'item') === -1) {
        TemptableFilter.itemDropdownValue = '';
        TemptableFilter.searchItemId = '';
      } else {
        TemptableFilter.itemDropdownValue = itemDropdown;
      }

      setTableFilter(TemptableFilter);
      setFilterValue(TempFilterValue);

      if (Object.keys(performanceRanges).length > 0) {
        Object.keys(performanceRanges).map((appliedFilter) => {
          let tempPerformanceRanges = { ...performanceRanges };
          if (filteredData[0]?.campaign_filter?.Performance.findIndex((obj) => obj.value === appliedFilter) === -1) {
            delete tempPerformanceRanges[appliedFilter];
          }
          setPerformanceRanges(tempPerformanceRanges);
        });
      }

      if (Object.keys(advanceRanges).length > 0) {
        Object.keys(advanceRanges).map((appliedFilter) => {
          let tempPerformanceRanges = { ...advanceRanges };
          if (filteredData[0]?.campaign_filter?.Advanced.findIndex((obj) => obj.value === appliedFilter) === -1) {
            delete tempPerformanceRanges[appliedFilter];
          }
          setAdvanceRanges(tempPerformanceRanges);
        });
      }
    }
  }, [filteredData]);

  const [newData, setNewData] = useState();
  const [newDataTotal, setNewDataTotal] = useState();

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });

    return formattedData;
  };

  const formateDataTotal = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValueCampaignTotal(key, value);
    });

    return formattedData;
  };

  useEffect(() => {
    const finalData = campaignDetails?.results?.map((item) => {
      return formateData(Object.entries(item));
    });
    if (campaignDetailsTotal) {
      const TotalRowData = formateDataTotal(Object.entries(campaignDetailsTotal));

      setNewDataTotal(TotalRowData);
    }
    if (campaignDetails?.results?.length == 0) {
      dispatch(setSkeletonLoader(false));
    }
    setNewData(finalData);
    let tempColumns = [...columns];
    if (filteredData[0]?.campaign_column) {
      filteredData[0]?.campaign_column?.map((col) => {
        const columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
        if (columnsIndex !== -1) {
          tempColumns[columnsIndex].options.display = col?.display;
        }
      });
    }
    setSelectedColumns(tempColumns);
  }, [campaignDetails]);

  useEffect(() => {
    if (newData?.length > 0) {
      dispatch(setSkeletonLoader(false));
    }
  }, [newData]);

  //Remove Skeleton loader when csv downloaded
  useEffect(() => {
    if (isCsvDownloadComplete) {
      setLoadingDownload(false);
      setIsCsvDownloadComplete(false);
      dispatch(setSkeletonLoader(false));
    }
  }, [isCsvDownloadComplete]);

  const campaign_type = [
    { id: 1, name: CAMPAIGN.CAMPAIGN_TYPE.SB, value: 'sba' },
    { id: 2, name: CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO, value: 'sponsoredProducts', type: CAMPAIGN.TARGETING_TYPE.AUTO },
    { id: 3, name: CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL, value: 'sponsoredProducts', type: CAMPAIGN.TARGETING_TYPE.MANUAL },
    { id: 4, name: CAMPAIGN.CAMPAIGN_TYPE.SV, value: 'video' }
  ];

  const automationStatusList = [
    { id: 1, name: 'Day Parting On', value: 'day_parting_on' },
    { id: 2, name: 'Day Parting Off', value: 'day_parting_off' },
    { id: 3, name: 'Budget Schedule On', value: 'budget_schedule_on' },
    { id: 4, name: 'Budget Schedule Off', value: 'budget_schedule_off' },
    { id: 4, name: 'Rules On', value: 'rules_on' },
    { id: 4, name: 'Rules Off', value: 'rules_off' },
    { id: 4, name: 'Budget Manager On', value: 'budget_manager_on' },
    { id: 4, name: 'Budget Manager Off', value: 'budget_manager_off' },
    { id: 4, name: 'AI On', value: 'ai_on' },
    { id: 4, name: 'AI On', value: 'ai_off' }
  ];

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  const handleClick = (event, value, name) => {
    setSelectAdgroup({ advertiser: name?.advertiser, campaign: name?.id });
    // setAnchorEl(event.currentTarget);

    setAnchorElAction(event.currentTarget);
    setSelectedData(name); // Set the selected data

    setTargetingType(name?.campaign_type);
    name?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL ? setIsManual(true) : setIsManual(false);
    setCampaignModal({ ...campaignModal, id: commaStringToNumber(value) });
    setItemModal({
      ...itemModal,
      id: commaStringToNumber(value),
      name: name?.name,
      advertiser_id: name?.advertiser,
      targeting_type: name?.targeting_type
    });
    setCampaignBrandModal({ ...campaignBrandModal, id: commaStringToNumber(value) });
    setCampaignVideoModal({
      ...campaignVideoModal,
      id: commaStringToNumber(value),
      advertiser_id: name?.advertiser,
      adgroup: name?.adgroup_number
    });
    setKeywordModal({ ...keywordModal, id: commaStringToNumber(value), name: name?.name });
  };

  const handleClose = () => {
    // setAnchorEl(null);

    setAnchorElAction(null);
    setSelectedData(null); // Clear the selected data
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setExistingCampaignId(null));
    dispatch(setExistingAdgroupId(null));
    dispatch(getSelectedFilterList());
    dispatch(setExistingBrandId(null));
  }, []);

  const [showSelectAdviser, setShowAdviser] = useState(false);

  const getCampaignList = (newpage, pageRow) => {
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    if (advertiserFilter.length > 0 && campaignId === undefined) {
      setShowAdviser(true);
      getAdvertiserListWithPagination(
        newpage !== 'undefined' || newpage !== null ? newpage : page,
        pageRow || rowPerPage,
        search,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        shouldApplyFilter?.current ? filterForSearch : {},
        advertiserFilter,
        commonDateRange,
        shouldApplyFilter?.current ? PerformanceFilterData : {},
        shouldApplyFilter?.current ? advanceRangesForSearch : {}
      );
    } else {
      setShowAdviser(false);
    }
  };

  // Fetch Advertiser Profiles
  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      if (commonAdvertiser?.length) {
        let id = commonAdvertiser;
        dispatch(getCampaignNameList({ id }));
      }
      setInitialPageFilters();
      shouldApplyFilter.current = false;
      getCampaignList(0, rowPerPage);
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  useEffect(() => {
    shouldApplyFilter.current = true;
    if (JSON.stringify(previousDateRange.current) !== JSON.stringify(commonDateRange)) {
      getCampaignList(0, rowPerPage);
      previousDateRange.current = commonDateRange;
    }
  }, [commonDateRange]);

  // for stop the skeleton loader when no advertiser id is selected
  useEffect(() => {
    if (!selectedProfiles) {
      dispatch(setSkeletonLoader(false)); // Stop loader if no profile selected
    } else {
      setNewData([]);
      dispatch(setSkeletonLoader(true));
    }
  }, [selectedProfiles, dispatch]);

  const getAdvertiserListWithPagination = (
    page,
    rowPerPage,
    search = '',
    changedColumn = appliedSortTing?.changedColumnSort,
    direction = appliedSortTing?.directionSort,
    tableFilter = { profile: '', type: '', status: '', campaign: '', targeting: '', attr_windows: '' },
    advertiserFilter = '',
    commonDateRange = { startDate: '', endDate: '' },
    PerformanceFilter,
    DailyBudget,
    is_csv = false,
    is_inlineEdit = false
  ) => {
    if (commonDateRange?.startDate && commonDateRange?.endDate) {
      !is_csv && !is_inlineEdit && setTableLoading(true);
      advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
      dispatch(
        getCampaignDetails({
          page,
          rowPerPage,
          search,
          changedColumn,
          direction,
          tableFilter,
          advertiserFilter,
          commonDateRange,
          PerformanceFilter,
          DailyBudget,
          is_csv,
          isComparingDates,
          is_inlineEdit,
          setIsCsvDownloadComplete,
          setLoadingDownload
        })
      ).then(() => {
        setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
        setLoading(false);
        setTableLoading(false);
        setInlineLoader(null);
      });
    } else {
      dispatch(setSkeletonLoader(false));
    }
  };

  const ViewCampaignDetails = (campaignID) => {
    navigate(`/product-campaign-view/${commaStringToNumber(campaignID)}`);
  };

  const handledReviewButton = (value) => {
    value = commaStringToNumber(value);
    const _request = {
      campaign: value
    };
    dispatch(postCampaignReview({ _request })).then((res) => {
      if (res.payload.status == 201) {
        const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
        getAdvertiserListWithPagination(page, rowPerPage, '', '', '', filterForSearch, advertiserFilter, commonDateRange);
      }
    });
  };

  const handledCancelReviewButton = (value) => {
    const _request = {
      review_id: value
    };
    dispatch(putCancelReview({ _request })).then((res) => {
      if (res.payload.status == 201) {
        const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
        getAdvertiserListWithPagination(page, rowPerPage, '', '', '', filterForSearch, advertiserFilter, commonDateRange);
      }
    });
  };

  const handleAddAdGroup = () => {
    navigate('/create-adgroup', { state: { advertise: selectedAdgroup.advertiser, id: selectedAdgroup.campaign } });
  };

  //Replace value while inline update
  const updateCampaignName = (details, id, updates) => {
    return {
      ...details,
      results: details.results.map((campaign) =>
        campaign.id === id
          ? {
              ...campaign,
              ...updates.reduce((acc, update) => ({ ...acc, ...update }), {}) // Merges the updates
            }
          : campaign
      )
    };
  };

  //While confirm Inline Update
  const handleSaveButton = (value, advertiser, id, type) => {
    setInlineLoader({ [type]: id });
    const _request = {
      [type]: value,
      advertiser: advertiser
    };
    if (type === 'status') {
      _request.is_status_changed = true;
    }
    const existingCampaignId = id;
    dispatch(updateCampaignModal({ _request, existingCampaignId })).then((res) => {
      if (isConfirmationOpen) {
        setConfirmationOpen(false);
      }
      if (res?.payload?.status == 201 || res?.payload?.status == 200) {
        const updatedCampaignDetails = updateCampaignName(campaignDetails, id, [{ [type]: value }]);
        dispatch(setCampaignDetails(updatedCampaignDetails));

        setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
        setLoading(false);
        setTableLoading(false);
        setInlineLoader(null);
      } else if (res?.payload?.status === 406) {
        //When there is error
        setInlineLoader(null);
        setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
      }
    });
  };

  const handleCampaignStatusUpdate = (event, advertiser, id, type) => {
    const sendData = {
      value: event.target.value,
      advertiser: advertiser,
      id: id,
      type: type
    };
    setSaveDataForEdit(sendData);
    setConfirmationOpen(true);
  };

  const handleDownloadCsv = () => {
    setLoadingDownload(true);
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    getAdvertiserListWithPagination(
      0,
      rowPerPage,
      search,
      '',
      '',
      shouldApplyFilter?.current ? filterForSearch : {},
      advertiserFilter,
      commonDateRange,
      PerformanceFilterData,
      advanceRangesForSearch,
      true
    );
  };

  const handleColumnSelectorOpen = () => {
    setViewColumnsSelector(true);
  };

  const handleColumnSelectorClose = () => {
    setViewColumnsSelector(false);
  };

  const handleCustomTableHead = ({
    columnMeta,
    handleToggleColumn,
    sortOrder,
    columnName,
    columnLabel_1,
    columnLabel_2 = '',
    customCss = {},
    isLabelAlignRight = false,
    isParentLabelAlignRight = false
  }) => {
    return (
      <TableCell
        sx={{
          position: 'sticky',
          top: '0px',
          zIndex: 1,
          cursor: columnMeta?.sort ? 'pointer' : 'text',
          ...customCss
        }}
        onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
        key={columnMeta?.index}
      >
        <Box display="flex" alignItems="center" justifyContent={isParentLabelAlignRight ? 'flex-end' : 'flex-start'} gap={'5px'}>
          <Box
            display="flex"
            alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Tooltip title={columnMeta?.sort ? 'Sort' : ''}>
              <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
              {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
            </Tooltip>
          </Box>
          {!__.isEmpty(sortOrder) && (
            <Box>
              {sortOrder?.direction === 'asc' && sortOrder?.name === columnName ? <img src={upIcon} alt="icon" /> : null}
              {sortOrder?.direction === 'desc' && sortOrder?.name === columnName ? <img src={downIcon} alt="icon" /> : null}
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  //Prepare Campaign name Redirect link
  const prepareCampaignNameLink = (data) => {
    let campRedirectLink = `/campaign/${commaStringToNumber(data?.campaign_id)}`;
    const campaignType = data?.campaign_type;
    if (campaignType === 'sponsoredProducts') {
      const targetingType = data?.targeting_type;
      if (targetingType === CAMPAIGN.TARGETING_TYPE.AUTO) {
        campRedirectLink = `/items/${data?.id}/${CAMPAIGN.NAVIGATION_TYPE.CAMPAIGN}`;
      } else if (targetingType === CAMPAIGN.TARGETING_TYPE.MANUAL) {
        campRedirectLink = `/keyword/${data?.id}/${CAMPAIGN.NAVIGATION_TYPE.CAMPAIGN}`;
      }
    }
    return campRedirectLink;
  };

  const columns = [
    {
      name: 'name',
      label: 'Campaign Name',
      default: true,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        width: 550,
        sticky: true,
        leftTotal: 40,
        FontSize: '22px',
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          const customCss = { zIndex: 101, left: '40px', minWidth: '380px' };
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'name',
            columnLabel_1: 'Campaign Name',
            customCss
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          const campRedirectLink = prepareCampaignNameLink(data);
          if (data)
            return (
              <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                // onMouseEnter={() => setHoveredRow({ campaign: data?.id })}
                // onMouseLeave={() => setHoveredRow(null)}
              >
                {edit.campaign == data?.id ? (
                  <>
                    <TextField
                      defaultValue={value}
                      disabled={loading || inlineLoader?.name}
                      autoFocus
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{loading ? <CircularProgress size={20} /> : ''}</InputAdornment>
                        )
                      }}
                      onBlur={(e) => {
                        if (e.target.value != value) {
                          handleSaveButton(e.target.value, data?.advertiser, data?.id, 'name');
                        } else {
                          setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
                        }
                      }}
                    />
                    {inlineLoader?.name && (
                      <Box sx={{ ml: 1 }}>
                        <Loader />
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <Box>
                      {value?.length > 40 ? (
                        <Tooltip placement="top" sx={{ fontSize: '10px' }} title={value} arrow>
                          <Link
                            href={campRedirectLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={'ffInter'}
                            underline="none"
                            sx={{ cursor: 'pointer' }}
                          >
                            {value.substring(0, 40)}...
                          </Link>
                        </Tooltip>
                      ) : (
                        <Link
                          href={campRedirectLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={'ffInter'}
                          underline="none"
                          sx={{ cursor: 'pointer' }}
                        >
                          {value}
                        </Link>
                      )}
                    </Box>

                    <Box display={'flex'} alignItems={'center'}>
                      {(update && data?.Review_status?.reviewStatus == 'pending') ||
                      data?.Review_status?.reviewStatus == 'in-progress' ? (
                        <Link
                          sx={{ marginRight: 2 }}
                          onClick={() => handledCancelReviewButton(data?.Review_status?.review_id)}
                          href="javascript:void(0)"
                        >
                          <img src={cancelSendIcon} alt="Icon" />
                        </Link>
                      ) : data?.campaign_type == 'sba' && update ? (
                        <Link sx={{ marginRight: 2 }} onClick={() => handledReviewButton(value)} href="javascript:void(0)">
                          <img src={sendIcon} alt="Icon" />
                        </Link>
                      ) : (
                        <></>
                      )}
                      <Link
                        onClick={() => {
                          setEdit({
                            ...edit,
                            campaign: data?.id,
                            total_budget: null,
                            daily_budget: null,
                            status: null,
                            end_date: null
                          });
                        }}
                        href="javascript:void(0)"
                      >
                        <Tooltip sx={{ fontSize: '10px' }} title={'Edit'} arrow>
                          {' '}
                          <img src={editIcon} alt="Icon" />
                        </Tooltip>
                      </Link>
                      <Box gap={'5px'}>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openAction ? 'long-menu' : undefined}
                          aria-expanded={openAction ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            // handleClickThreeDot(e, data);
                            handleClick(e, data?.id, data);
                          }}
                        >
                          <Tooltip sx={{ fontSize: '10px' }} title={'Action'} arrow>
                            <img src={actionIcon} alt="icon" />
                          </Tooltip>
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button'
                          }}
                          anchorEl={anchorElAction}
                          open={openAction}
                          // onClose={handleCloseThreeDot}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch',
                              borderColor: colors?.grey100,
                              borderRadius: '6px'
                            }
                          }}
                        >
                          <MenuItem key={selectedData?.id} selected={''} onClick={() => ViewCampaignDetails(selectedData?.id)}>
                            <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                              <img src={viewBlackIcon} alt="Icon" />
                              <Typography
                                sx={{
                                  color: colors?.grey900,
                                  '&:hover': {
                                    color: colors?.darkTextHover,
                                    fontWeight: 700
                                  }
                                }}
                              >
                                View
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            key={uuidv4()}
                            selected={''}
                            onClick={() => {
                              handleClose();
                              if (targetingType == 'sba') {
                                setCampaignBrandModal({ ...campaignBrandModal, open: true });
                              }
                              if (targetingType == 'video') {
                                setCampaignVideoModal({ ...campaignVideoModal, open: true });
                              } else {
                                setCampaignModal({ ...campaignModal, open: true });
                              }
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                              <Tooltip sx={{ fontSize: '10px' }} title={'Edit'} arrow>
                                {' '}
                                <img src={editIcon} alt="Icon" />
                              </Tooltip>
                              <Typography
                                sx={{
                                  color: colors?.grey900,
                                  '&:hover': {
                                    color: colors?.darkTextHover,
                                    fontWeight: 700
                                  }
                                }}
                              >
                                Campaign
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            key={uuidv4()}
                            selected={''}
                            onClick={() => {
                              handleClose();
                              setItemModal({ ...itemModal, open: true });
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                              <img src={plusSimpleIcon} alt="Icon" />
                              <Typography
                                sx={{
                                  color: colors?.grey900,
                                  '&:hover': {
                                    color: colors?.darkTextHover,
                                    fontWeight: 700
                                  }
                                }}
                              >
                                Add Items
                              </Typography>
                            </Box>
                          </MenuItem>
                          {targetingType === 'sponsoredProducts' && (
                            <MenuItem key={uuidv4()} selected={''} onClick={handleAddAdGroup}>
                              <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                                <img src={plusSimpleIcon} alt="Icon" />
                                <Typography
                                  sx={{
                                    color: colors?.grey900,
                                    '&:hover': {
                                      color: colors?.darkTextHover,
                                      fontWeight: 700
                                    }
                                  }}
                                >
                                  Add Adgroup
                                </Typography>
                              </Box>
                            </MenuItem>
                          )}
                          <MenuItem
                            key={uuidv4()}
                            selected={''}
                            onClick={() => {
                              handleClose();
                              setKeywordModal({ ...keywordModal, open: true });
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                              <img src={plusSimpleIcon} alt="Icon" />
                              <Typography
                                sx={{
                                  color: colors?.grey900,
                                  '&:hover': {
                                    color: colors?.darkTextHover,
                                    fontWeight: 700
                                  }
                                }}
                              >
                                Add Keyword
                              </Typography>
                            </Box>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            );
        },
        ...(showSelectAdviser && {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 40,
              minWidth: '380px',
              background: 'white',
              zIndex: 100
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 40,
              minWidth: '380px',
              // background: 'white',
              zIndex: 101
            }
          })
        })
      }
    },

    {
      name: 'status',
      label: 'Status',
      default: true,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        sticky: true,
        leftTotal: 410,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          const customCss = { zIndex: 101, left: '410px', width: '90px' };
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'status',
            columnLabel_1: 'Status',
            customCss
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results ? campaignDetails?.results[rowData?.rowIndex] : [];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {inlineLoader?.status && inlineLoader?.status === data?.id ? (
                  <Loader />
                ) : (
                  <Select
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 'none !important'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        boxShadow: 'none !important'
                      },
                      '.MuiSelect-select': {
                        paddingLeft: '0 !important' // Remove left padding
                      }
                    }}
                    size="small"
                    value={value}
                    disabled={!update}
                    onChange={(e) => {
                      if (update) {
                        handleCampaignStatusUpdate(e, data?.advertiser, data.id, 'status');
                      }
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={
                            selected === 'completed'
                              ? CompletedIcon
                              : selected === 'proposal'
                              ? ProposalIcon
                              : selected === 'scheduled'
                              ? ScheduledIcon
                              : selected === 'enabled'
                              ? EnabledIcon
                              : selected === 'Paused By Budget Manager'
                              ? PaushedBudgetManager
                              : PausedIcon
                          }
                          alt={selected}
                          width={22}
                          height={22}
                          style={{ marginRight: '8px' }}
                        />
                        {/* {!isOpen && selected.charAt(0).toUpperCase() + selected.slice(1)} */}
                      </Box>
                    )}
                  >
                    {value == 'completed' && (
                      <MenuItem value="completed">
                        <img src={CompletedIcon} alt="completed" width={22} height={22} style={{ marginRight: '8px' }} />
                        Completed
                      </MenuItem>
                    )}
                    {value == 'proposal' && (
                      <MenuItem value="proposal">
                        {' '}
                        <img src={ProposalIcon} alt="Proposal" width={22} height={22} style={{ marginRight: '8px' }} /> Proposal
                      </MenuItem>
                    )}
                    {value == 'scheduled' && (
                      <MenuItem value="scheduled">
                        {' '}
                        <img src={ScheduledIcon} alt="Scheduled" width={22} height={22} style={{ marginRight: '8px' }} />{' '}
                        Scheduled
                      </MenuItem>
                    )}

                    <MenuItem value={'enabled'}>
                      <img src={EnabledIcon} alt="EnabledIcon" width={22} height={22} style={{ marginRight: '8px' }} /> Enabled
                    </MenuItem>
                    <MenuItem value="paused">
                      <img src={PausedIcon} alt="PausedIcon" width={22} height={22} style={{ marginRight: '8px' }} /> Paused
                    </MenuItem>
                    {value == 'Paused By Budget Manager' && (
                      <MenuItem value="Paused By Budget Manager">
                        {' '}
                        <img
                          src={PaushedBudgetManager}
                          alt="Scheduled"
                          width={22}
                          height={22}
                          style={{ marginRight: '8px' }}
                        />{' '}
                        Paused By Budget Manager
                      </MenuItem>
                    )}
                  </Select>
                )}
              </Box>
              {/* <Box>
                {inlineLoader?.status && inlineLoader?.status === data?.id && (
                  <Box sx={{ ml: 1 }}>
                    <Loader />
                  </Box>
                )}
              </Box> */}
            </>
          );
        },

        ...(showSelectAdviser && {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              minWidth: '90px',
              left: 410,
              background: 'white',
              zIndex: 100,
              '&:hover': {
                backgroundColor: '#c1c1c1 !important' // Change background color on hover
              }
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 410,
              minWidth: '90px',
              // background: 'white',
              zIndex: 101,
              '&:hover': {
                backgroundColor: '#c1c1c1 !important' // Change background color on hover
              }
            }
          })
        })
      }
    },

    {
      name: 'profile_name',
      label: 'Profile Name',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'profile_name',
            columnLabel_1: 'Profile Name'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box>
              <Typography className="ffInter clr3">{value}</Typography>
            </Box>
          );
        }
      }
    },

    {
      name: 'campaign_type',
      label: 'Campaign Type',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'campaign_type',
            columnLabel_1: 'Campaign',
            columnLabel_2: 'Type'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];

          var type = '';
          if (value == 'sponsoredProducts') {
            type =
              data?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                ? CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL
                : CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO;
          } else if (value == 'sba') {
            type = CAMPAIGN.CAMPAIGN_TYPE.SB;
          } else if (value == 'video') {
            type = CAMPAIGN.CAMPAIGN_TYPE.SV;
          }
          return (
            <Box>
              <Chip sx={{ height: '26px' }} className={'ffInter fs12'} label={type} color="primary" variant="outlined" />
            </Box>
          );
        }
      }
    },

    {
      name: 'Review_status',
      label: 'Review Status',
      options: {
        filter: true,
        sort: false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'Review_status',
            columnLabel_1: 'Review',
            columnLabel_2: 'Status'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{data?.Review_status?.reviewStatus ? data?.Review_status?.reviewStatus : '--'}</Typography>
              {data?.Review_status?.reviewStatus == 'complete' ? (
                <Button focused onClick={() => setReviewLogModal({ open: true, id: data?.id, name: data?.name })}>
                  Comments
                </Button>
              ) : data?.Review_status?.reviewStatus ? (
                <Button onClick={() => setReviewLogModal({ open: true, id: data?.id, name: data?.name })}>Log</Button>
              ) : (
                <></>
              )}
            </Box>
          );
        }
      }
    },

    {
      name: 'adgroup_number',
      label: 'Adgroups',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'adgroup_number',
            columnLabel_1: 'Adgroups'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          return (
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              {value ? (
                // <Link sx={{ cursor: 'pointer' }} onClick={() => navigate(`/adgroup/${data?.id}`)}>
                //   {value}
                // </Link>
                <a className="link-dashna-ui ffInter" rel="noreferrer noopener" href={`/adgroup/${data?.id}`} target="_blank">
                  {value}
                </a>
              ) : (
                <>--</>
              )}
            </Box>
          );
        }
      }
    },

    {
      name: 'aditem_number',
      label: 'Items',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'aditem_number',
            columnLabel_1: 'Items'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          return (
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              {value ? (
                <a
                  className="link-dashna-ui ffInter"
                  rel="noreferrer noopener"
                  href={`/items/${data?.id}/${CAMPAIGN.NAVIGATION_TYPE.CAMPAIGN}`}
                  target="_blank"
                >
                  {value}
                </a>
              ) : (
                // <Link
                //   sx={{ cursor: 'pointer' }}
                //   onClick={() => navigate(`/items/${data?.id}`, { state: { type: CAMPAIGN.NAVIGATION_TYPE.CAMPAIGN } })}
                // >
                //   {value}
                // </Link>
                <>--</>
              )}
            </Box>
          );
        }
      }
    },

    {
      name: 'keyword_number',
      label: 'Keywords',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'keyword_number',
            columnLabel_1: 'Keywords'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          return (
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              {value ? (
                // <Link
                //   sx={{ cursor: 'pointer' }}
                //   onClick={() => navigate(`/keyword/${data?.id}`, { state: { type: CAMPAIGN.NAVIGATION_TYPE.CAMPAIGN } })}
                // >
                //   {value}
                // </Link>
                <a
                  className="link-dashna-ui ffInter"
                  rel="noreferrer noopener"
                  href={`/keyword/${data?.id}/${CAMPAIGN.NAVIGATION_TYPE.CAMPAIGN}`}
                  target="_blank"
                >
                  {value}
                </a>
              ) : (
                <>--</>
              )}
            </Box>
          );
        }
      }
    },

    {
      name: 'start_date',
      label: 'Start Date',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'start_date',
            columnLabel_1: 'Start Date'
          });
        },
        customBodyRender: (value) => {
          return <Typography className="ffInter clr3">{value}</Typography>;
        }
      }
    },
    {
      name: 'end_date',
      label: 'End Date',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'end_date',
            columnLabel_1: 'End Date'
          });
        },
        customBodyRender: (dateValue, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          const startDateValue = data?.start_date;
          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={2}
              // onMouseEnter={() => setHoveredRow({ end_date: data?.id })}
              // onMouseLeave={() => setHoveredRow(null)}
            >
              {edit.end_date == data?.id ? (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // {...register("end_date")}
                      // error={errors?.end_date}
                      disabled={inlineLoader?.end_date}
                      sx={{ minWidth: '156px' }}
                      value={dayjs(endDateValidator(dateValue) ? null : dateValue)}
                      minDate={startDateValue ? dayjs(startDateValue).add(1, 'day') : dayjs(new Date()).add(1, 'day')}
                      onChange={(value) => {
                        const dayjsDate = dayjs(value.$d);
                        const formattedDate = dayjsDate.format('YYYY-MM-DD');
                        if (value != dateValue && calenderView === 'day') {
                          setSelectedCalenderDate(formattedDate);
                          // handleSaveButton(formattedDate, data?.advertiser, data?.id, 'end_date');
                        } else {
                          // setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
                        }
                      }}
                      onViewChange={(view) => {
                        setCalenderView(view);
                      }}
                    />
                  </LocalizationProvider>
                  {selectedCalenderDate && (
                    <Box>
                      <Link
                        className="cursor-pointer"
                        onClick={() => {
                          handleSaveButton(selectedCalenderDate, data?.advertiser, data?.id, 'end_date');
                          setSelectedCalenderDate('');
                        }}
                      >
                        <img src={correctIcon} alt="Icon" />
                      </Link>
                    </Box>
                  )}
                  {inlineLoader?.end_date && (
                    <Box sx={{ ml: 1 }}>
                      <Loader />
                    </Box>
                  )}
                  <Box>
                    <Link
                      className="cursor-pointer"
                      onClick={() => {
                        setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
                        setSelectedCalenderDate('');
                      }}
                    >
                      <img src={closeIcon} alt="Icon" />
                    </Link>
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    <Typography className="ffInter clr3">{endDateValidator(dateValue) ? '--' : dateValue}</Typography>
                  </Box>
                  <Box>
                    {update && (
                      <Link
                        onClick={() => {
                          setEdit({
                            ...edit,
                            end_date: data?.id,
                            campaign: null,
                            total_budget: null,
                            daily_budget: null,
                            status: null
                          });
                        }}
                        href="javascript:void(0)"
                      >
                        <img src={editIcon} alt="Icon" />
                      </Link>
                    )}
                  </Box>
                </>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: 'budget_type',
      label: 'Budget Type',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'budget_type',
            columnLabel_1: 'Budget',
            columnLabel_2: 'Type'
          });
        },
        customBodyRender: (value) => {
          return <Typography className="ffInter clr3">{value.charAt(0).toUpperCase() + value.slice(1)}</Typography>;
        }
      }
    },
    {
      name: 'total_budget',
      label: 'Total Budget',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'total_budget',
            columnLabel_1: 'Budget',
            columnLabel_2: 'Total',
            isLabelAlignRight: true,
            isParentLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const defaultValue = Number(value?.replace(/,/g, ''));
          const data = campaignDetails?.results[rowData?.rowIndex];
          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              onMouseEnter={() => setHoveredRow({ total_budget: data?.id })}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {edit.total_budget == data?.id ? (
                <>
                  <TextField
                    defaultValue={defaultValue}
                    autoFocus
                    disabled={loading || inlineLoader?.total_budget}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{loading ? <CircularProgress size={20} /> : ''}</InputAdornment>
                      )
                    }}
                    inputProps={{
                      inputMode: 'decimal', // Specifies numeric input with decimal support
                      pattern: '[0-9.]*' // Allows only numbers and dots
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Remove any non-numeric characters except dots
                    }}
                    onBlur={(e) => {
                      const targetValue = Number(e.target.value?.replace(/,/g, ''));
                      if (e.target.value && targetValue != defaultValue) {
                        handleSaveButton(Number(e.target.value), data?.advertiser, data?.id, 'total_budget');
                      } else {
                        setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
                      }
                    }}
                  />
                  {inlineLoader?.total_budget && (
                    <Box sx={{ ml: 1 }}>
                      <Loader />
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box
                    onClick={() => {
                      setEdit({
                        ...edit,
                        total_budget: data?.id,
                        campaign: null,
                        daily_budget: null,
                        status: null,
                        end_date: null
                      });
                    }}
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography className="ffInter" color={colors?.primaryMain} textAlign={'right'}>
                      {value ? '$' + value : '--'}
                    </Typography>
                    <img src={lineIcon} alt="Icon" />
                  </Box>
                </>
              )}
            </Box>
          );
        }
      }
    },

    {
      name: 'daily_budget',
      label: 'Daily Budget',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'daily_budget',
            columnLabel_1: 'Budget',
            columnLabel_2: 'Daily',
            isLabelAlignRight: true,
            isParentLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const defaultValue = Number(value?.replace(/,/g, ''));
          const data = campaignDetails?.results[rowData?.rowIndex];
          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              onMouseEnter={() => setHoveredRow({ daily_budget: data?.id })}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {edit.daily_budget == data?.id ? (
                <>
                  <TextField
                    defaultValue={defaultValue}
                    autoFocus
                    disabled={loading || inlineLoader?.daily_budget}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{loading ? <CircularProgress size={20} /> : ''}</InputAdornment>
                      )
                    }}
                    inputProps={{
                      inputMode: 'decimal', // Specifies numeric input with decimal support
                      pattern: '[0-9.]*' // Allows only numbers and dots
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Remove any non-numeric characters except dots
                    }}
                    onBlur={(e) => {
                      const targetValue = Number(e.target.value?.replace(/,/g, ''));
                      if (e.target.value && targetValue != defaultValue) {
                        handleSaveButton(Number(e.target.value), data?.advertiser, data?.id, 'daily_budget');
                      } else {
                        setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
                      }
                    }}
                  />
                  {inlineLoader?.daily_budget && (
                    <Box sx={{ ml: 1 }}>
                      <Loader />
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box
                    onClick={() => {
                      setEdit({
                        ...edit,
                        daily_budget: data?.id,
                        campaign: null,
                        total_budget: null,
                        status: null,
                        end_date: null
                      });
                    }}
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography className="ffInter" color={colors?.primaryMain} textAlign={'right'}>
                      {value ? '$' + value : '--'}
                    </Typography>
                    <img src={lineIcon} alt="Icon" />
                  </Box>
                </>
              )}
            </Box>
          );
        }
      }
    },

    {
      name: 'remaining_total_budget',
      label: 'Remaining Total Budget',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'remaining_total_budget',
            columnLabel_1: 'Remaining',
            columnLabel_2: 'Total',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value) => {
          return (
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Typography className="ffInter clr3">{value}</Typography>
            </Box>
          );
        }
      }
    },

    {
      name: 'placement_multipliers',
      label: 'Placement Multipliers',
      options: {
        filter: true,
        sort: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <TableCell
              sx={{
                position: 'sticky',
                top: '0px',
                zIndex: 1,
                cursor: columnMeta?.sort ? 'pointer' : 'text'
              }}
              onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
              key={columnMeta?.index}
            >
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="clr3 fw700 ffInter">{'Placement Multipliers'}</Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={2}>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Search'}
                  </Box>
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Ingrid'}
                  </Box>
                </Box>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Item'}
                  </Box>
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Buybox'}
                  </Box>
                </Box>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Home'}
                  </Box>
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Page'}
                  </Box>
                </Box>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Stock'}
                  </Box>
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Up'}
                  </Box>
                </Box>
              </Box>
            </TableCell>
          );
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          return data?.campaign_type == 'sba' || data?.campaign_type == 'video' ? (
            <Typography>--</Typography>
          ) : inlineLoader?.placement && inlineLoader?.placement === data?.id ? (
            <Loader />
          ) : (
            <>
              {data?.placement_bid_search_ingrid ||
              data?.placement_bid_item_buybox ||
              data?.placement_bid_stock_up ||
              data?.placement_bid_home_page ? (
                <Grid container spacing={2}>
                  {/* <Box display={'flex'} gap={0}> */}

                  <Grid item xs={3}>
                    {Number(data?.placement_bid_search_ingrid) !== 0 && (
                      <Tooltip title="Search Ingrid">
                        <Box
                          sx={{ minWidth: '55px', cursor: 'pointer' }}
                          onClick={() => {
                            if (update) {
                              setPlacementEditModal({ open: true, data: data });
                            }
                          }}
                        >
                          <Typography
                            className="ffInter"
                            sx={{
                              color: colors?.primaryMain
                            }}
                          >
                            {`${data?.placement_bid_search_ingrid}%`}
                          </Typography>
                        </Box>
                      </Tooltip>
                    )}
                  </Grid>

                  <Grid item xs={3}>
                    {Number(data?.placement_bid_item_buybox) !== 0 && (
                      <Tooltip title="Item Buybox">
                        <Box
                          sx={{ minWidth: '55px', cursor: 'pointer' }}
                          onClick={() => {
                            if (update) {
                              setPlacementEditModal({ open: true, data: data });
                            }
                          }}
                        >
                          <Typography
                            className="ffInter"
                            sx={{
                              cursor: 'pointer',
                              color: colors?.metricB
                            }}
                          >{`${data?.placement_bid_item_buybox}%`}</Typography>
                        </Box>
                      </Tooltip>
                    )}
                  </Grid>

                  <Grid item xs={3}>
                    {Number(data?.placement_bid_home_page) !== 0 && (
                      <Tooltip title="Home page">
                        <Box
                          sx={{ minWidth: '55px', cursor: 'pointer' }}
                          onClick={() => {
                            if (update) {
                              setPlacementEditModal({ open: true, data: data });
                            }
                          }}
                        >
                          <Typography
                            className="ffInter"
                            sx={{
                              cursor: 'pointer',
                              color: colors?.greenFill
                            }}
                          >{`${data?.placement_bid_home_page}%`}</Typography>
                        </Box>
                      </Tooltip>
                    )}
                  </Grid>

                  <Grid item xs={3}>
                    {Number(data?.placement_bid_stock_up) !== 0 && (
                      <Tooltip title="Stock Up">
                        <Box
                          sx={{ minWidth: '55px', cursor: 'pointer' }}
                          onClick={() => {
                            if (update) {
                              setPlacementEditModal({ open: true, data: data });
                            }
                          }}
                        >
                          <Typography
                            className="ffInter"
                            sx={{
                              color: colors?.metricD
                            }}
                          >{`${data?.placement_bid_stock_up}%`}</Typography>
                        </Box>
                      </Tooltip>
                    )}
                  </Grid>

                  {/* </Box> */}
                </Grid>
              ) : (
                <Box
                  display={'flex'}
                  justifyContent={'flex-start'}
                  onClick={() => {
                    if (update) {
                      setPlacementEditModal({ open: true, data: data });
                    }
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <img src={plusIcon} alt="Icon" />
                </Box>
              )}
            </>
          );
        }
      }
    },

    {
      name: 'platform_multipliers',
      label: 'Platform Multipliers',
      options: {
        filter: true,
        sort: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <TableCell
              sx={{
                position: 'sticky',
                top: '0px',
                zIndex: 1,
                cursor: columnMeta?.sort ? 'pointer' : 'text'
              }}
              onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
              key={columnMeta?.index}
            >
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="clr3 fw700 ffInter">{'Platform Multipliers'}</Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={2} mt={3}>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'App'}
                  </Box>
                </Box>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Desktop'}
                  </Box>
                </Box>
                <Box display="flex" alignItems={'flex-start'} flexDirection="column" justifyContent="flex-start">
                  <Box className="fw600 ffInter fs12" color={colors?.grey900}>
                    {'Mobile'}
                  </Box>
                </Box>
              </Box>
            </TableCell>
          );
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          return data?.campaign_type == 'sba' || data?.campaign_type == 'video' ? (
            <Typography>--</Typography>
          ) : inlineLoader?.platform && inlineLoader?.platform === data?.id ? (
            <Loader />
          ) : data?.platform_bid_app || data?.platform_bid_desktop || data?.platform_bid_mobile ? (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {Number(data?.platform_bid_app) !== 0 && (
                  <Tooltip title="App">
                    <Box
                      onClick={() => {
                        if (update) {
                          setPlateformEditModal({ open: true, data: data });
                        }
                      }}
                      sx={{ minWidth: '50px' }}
                    >
                      <Typography
                        sx={{
                          cursor: 'pointer',
                          color: colors?.primaryMain
                        }}
                      >
                        {`${data?.platform_bid_app}%`}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={4}>
                {Number(data?.platform_bid_desktop) !== 0 && (
                  <Tooltip title="Desktop">
                    <Box
                      onClick={() => {
                        if (update) {
                          setPlateformEditModal({ open: true, data: data });
                        }
                      }}
                      sx={{ minWidth: '60px' }}
                    >
                      <Typography
                        sx={{
                          cursor: 'pointer',
                          color: colors?.metricB
                        }}
                      >
                        {`${data?.platform_bid_desktop}%`}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={4}>
                {Number(data?.platform_bid_mobile) !== 0 && (
                  <Tooltip title="Mobile">
                    <Box
                      onClick={() => {
                        if (update) {
                          setPlateformEditModal({ open: true, data: data });
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          cursor: 'pointer',
                          color: colors?.greenFill
                        }}
                      >
                        {`${data?.platform_bid_mobile}%`}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          ) : (
            <Box
              display={'flex'}
              justifyContent={'flex-start'}
              onClick={() => {
                if (update) {
                  setPlateformEditModal({ open: true, data: data });
                }
              }}
              sx={{ cursor: 'pointer' }}
            >
              <img src={plusIcon} alt="Icon" />
            </Box>
          );
        }
      }
    },

    {
      name: 'num_ads_shown',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'num_ads_shown',
            columnLabel_1: 'Impressions'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              {/* <Typography display="flex">
                <Tooltip
                  placement="top"
                  sx={{ fontSize: '10px' }}
                  title={
                    <Typography variant="body1" sx={{ fontSize: 16 }}>
                      {data?.percentage_num_ads_shown?.length > 0 ? data?.percentage_num_ads_shown : '-'}
                    </Typography>
                  }
                  arrow
                >
                  <Typography display="flex" sx={{ alignItems: 'center', flexDirection: 'row' }}>
                    <Typography sx={{ color: data?.is_higher_num_ads_shown ? '#00e676' : '#f44336' }}>
                      {data?.comparison_num_ads_shown?.length > 0 ? data?.comparison_num_ads_shown : '555,88'}
                    </Typography>
                    <Typography>
                      {' '}
                      {data?.is_higher_num_ads_shown ? <ArrowUpwardIcon color="success" /> : <ArrowDownwardIcon color="error" />}
                    </Typography>
                  </Typography>
                </Tooltip>
              </Typography> */}
              <CompareValueWithColor
                value={data?.comparison_num_ads_shown}
                Percentage={data?.percentage_num_ads_shown}
                UpDown={data?.is_higher_num_ads_shown}
                keys="num_ads_shown"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'num_ads_clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'num_ads_clicks',
            columnLabel_1: 'Clicks'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data.comparison_num_ads_clicks}
                Percentage={data.percentage_num_ads_clicks}
                UpDown={data.is_higher_num_ads_clicks}
                keys="num_ads_clicks"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'units_sold',
      label: 'Units Sold',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'units_sold',
            columnLabel_1: 'Unit Sold'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data.comparison_units_sold}
                Percentage={data.percentage_units_sold}
                UpDown={data.is_higher_units_sold}
                keys="units_sold"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'cpa_orders',
      label: 'CPA (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cpa_orders',
            columnLabel_1: 'CPA',
            columnLabel_2: '(Orders)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data.comparison_cpa_orders}
                Percentage={data.percentage_cpa_orders}
                UpDown={data.is_higher_cpa_orders}
                keys="cpa_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'attributed_orders',
      label: 'Orders',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'attributed_orders',
            columnLabel_1: 'Orders'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_attributed_orders}
                Percentage={data?.percentage_attributed_orders}
                UpDown={data?.is_higher_attributed_orders}
                keys="attributed_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'cpa_units',
      label: 'CPA (Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cpa_units',
            columnLabel_1: 'CPA',
            columnLabel_2: '(Units)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_cpa_units}
                Percentage={data?.percentage_cpa_units}
                UpDown={data?.is_higher_cpa_units}
                keys="cpa_units"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ctr',
            columnLabel_1: 'CTR'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_ctr}
                Percentage={data?.percentage_ctr}
                UpDown={data?.is_higher_ctr}
                keys="ctr"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'ad_spend',
      label: 'Spend',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ad_spend',
            columnLabel_1: 'Spend'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_ad_spend}
                Percentage={data?.percentage_ad_spend}
                UpDown={data?.is_higher_ad_spend}
                keys="ad_spend"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'attributed_sales',
      label: 'Ad Sales',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'advertised_sku_sales',
            columnLabel_1: 'Ad Sales'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_advertised_sku_sales}
                Percentage={data?.percentage_advertised_sku_sales}
                UpDown={data?.is_higher_advertised_sku_sales}
                keys="advertised_sku_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'roas',
      label: 'RoAS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'roas',
            columnLabel_1: 'RoAS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_roas}
                Percentage={data?.percentage_roas}
                UpDown={data?.is_higher_roas}
                keys="roas"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'acos',
      label: 'ACoS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'acos',
            columnLabel_1: 'ACoS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_acos}
                Percentage={data?.percentage_acos}
                UpDown={data?.is_higher_acos}
                keys="acos"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'average_cpc',
      label: 'Average CPC',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'average_cpc',
            columnLabel_1: 'Average',
            columnLabel_2: 'CPC',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_average_cpc}
                Percentage={data?.percentage_average_cpc}
                UpDown={data?.is_higher_average_cpc}
                keys="average_cpc"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'cvr_orders',
      label: 'CVR (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cvr_orders',
            columnLabel_1: 'CVR',
            columnLabel_2: '(Orders)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_cvr_orders}
                Percentage={data?.percentage_cvr_orders}
                UpDown={data?.is_higher_cvr_orders}
                keys="cvr_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'cvr_units',
      label: 'CVR (Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cvr_units',
            columnLabel_1: 'CVR',
            columnLabel_2: '(Units)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_cvr_units}
                Percentage={data?.percentage_cvr_units}
                UpDown={data?.is_higher_cvr_units}
                keys="cvr_units"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'instore_advertised_sales',
      label: 'In-Store Advertised Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_advertised_sales',
            columnLabel_1: 'In-Store Advertised',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_advertised_sales}
                Percentage={data?.percentage_instore_advertised_sales}
                UpDown={data?.is_higher_instore_advertised_sales}
                keys="instore_advertised_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'instore_attributed_sales',
      label: 'In-Store Attributed Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_attributed_sales',
            columnLabel_1: 'In-Store Attributed',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_attributed_sales}
                Percentage={data?.percentage_instore_attributed_sales}
                UpDown={data?.is_higher_instore_attributed_sales}
                keys="instore_attributed_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'instore_orders',
      label: 'In-Store Orders (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_orders',
            columnLabel_1: 'In-Store',
            columnLabel_2: 'Orders (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_orders}
                Percentage={data?.percentage_instore_orders}
                UpDown={data?.is_higher_instore_orders}
                keys="instore_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'instore_other_sales',
      label: 'In-Store Other Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_other_sales',
            columnLabel_1: 'In-Store Other',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_other_sales}
                Percentage={data?.percentage_instore_other_sales}
                UpDown={data?.is_higher_instore_other_sales}
                keys="instore_other_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'instore_units_sold',
      label: 'In-Store Units Sold (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_units_sold',
            columnLabel_1: 'In-Store Units',
            columnLabel_2: 'Sold (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = campaignDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_units_sold}
                Percentage={data?.percentage_instore_units_sold}
                UpDown={data?.is_higher_instore_units_sold}
                keys="instore_units_sold"
                currency={currency}
              />
            </>
          );
        }
      }
    }
  ];

  const [appliedSortTing, setAppliedSortTing] = useState();

  const initialColumnOrder = columns.map((_, index) => index);
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  const options = {
    filterType: 'checkbox',
    search: false,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    selectToolbarPlacement: 'none',
    tableBodyHeight: tableHeight,
    filter: false,
    print: false,
    page: page,
    viewColumns: false,
    count: campaignDetails?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: true,
    selectableRows: true,
    draggableColumns: {
      enabled: false
    },
    columnOrder: columnOrder,
    onColumnOrderChange: (newColumnOrder) => {
      if (newColumnOrder[0] !== 0 || newColumnOrder[1] !== 1) {
        setColumnOrder([...columnOrder]);
      } else {
        setColumnOrder(newColumnOrder);
      }
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      const selectedIds = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]?.id);
      const selectedNames = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]?.name);
      const selectedTypes = rowsSelected.map((selectedIndex) => {
        return {
          campaign_type: campaignDetails?.results?.[selectedIndex]?.campaign_type,
          targeting_type: campaignDetails?.results?.[selectedIndex]?.targeting_type
        };
      });

      const selectedAdviser = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]?.advertiser);
      const ProfileName = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]?.profile_name);
      const selectedForBudgetSetting = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]);
      setSelectedArray(selectedIds);
      setSelectedCapName(selectedNames);
      const payloadForItemData = {
        selectedItemId: selectedIds.join(','),
        selectedCampaignName: selectedNames,
        selectedCampaigntype: selectedTypes,
        advertiserId: selectedAdviser[0]
      };
      setDataItem(payloadForItemData);

      const payloadKeywordData = {
        open: true,
        id: selectedIds.join(','),
        name: selectedNames,

        addKeywordModel: false
      };
      setPassDataKeyword(payloadKeywordData);
      const dailyData_name =
        selectedForBudgetSetting &&
        selectedForBudgetSetting
          .filter((item) => item.budget_type == 'both' || item.budget_type == 'daily')
          .map((item) => item.name);
      const dailyData_id =
        selectedForBudgetSetting &&
        selectedForBudgetSetting
          .filter((item) => item.budget_type == 'both' || item.budget_type == 'daily')
          .map((item) => item.id);
      const totalData_name =
        selectedForBudgetSetting &&
        selectedForBudgetSetting
          .filter((item) => item.budget_type == 'both' || item.budget_type == 'total')
          .map((item) => item.name);
      const totalData_id =
        selectedForBudgetSetting &&
        selectedForBudgetSetting
          .filter((item) => item.budget_type == 'both' || item.budget_type == 'total')
          .map((item) => item.id);

      const newPayload = {
        DailyData: {
          DailyName: dailyData_name,
          DailyId: dailyData_id
        },
        TotalData: {
          DailyName: totalData_name,
          DailyId: totalData_id
        }
      };

      const placementTypeChecking =
        selectedForBudgetSetting &&
        selectedForBudgetSetting
          .filter((item) => item.campaign_type !== 'sba' && item.campaign_type !== 'video')
          .map((item) => item.targeting_type);

      const placementTypeCheckingSbORSv =
        selectedForBudgetSetting &&
        selectedForBudgetSetting
          .filter((item) => item.campaign_type == 'sba' || item.campaign_type == 'video')
          .map((item) => item.campaign_type);

      const uniqueTypeSbSv = [...new Set(placementTypeCheckingSbORSv)];

      setCheckUniqueTypeSvSb(uniqueTypeSbSv);
      const placementAddKeywordSetting =
        selectedForBudgetSetting &&
        selectedForBudgetSetting
          .filter(
            (item) =>
              (item?.campaign_type == 'sponsoredProducts' && item?.targeting_type == 'manual') ||
              (item?.campaign_type == 'sponsoredProducts' && item?.targeting_type == 'auto')
          )
          .map((item) => item?.targeting_type);

      const placementAddKeywordSettingSbSV =
        selectedForBudgetSetting &&
        selectedForBudgetSetting
          .filter(
            (item) =>
              item?.campaign_type == 'sba' ||
              item?.campaign_type == 'video' ||
              (item?.campaign_type == 'sponsoredProducts' && item?.targeting_type == 'manual') ||
              (item?.campaign_type == 'sponsoredProducts' && item?.targeting_type == 'auto')
          )
          .map((item) => `${item?.campaign_type} ${item?.targeting_type}`);

      const uniqueKeyword = [...new Set(placementAddKeywordSetting)];
      const uniqueType = [...new Set(placementTypeChecking)];
      const ProfileNameUnique = [...new Set(ProfileName)];
      setProfileNameModal(ProfileNameUnique);
      setProfileNameModalKeyword(uniqueKeyword);
      const uniqueSbSv = [...new Set(placementAddKeywordSettingSbSV)];
      setOpenAddKeywordModalSbSv(uniqueSbSv);
      const payload_placement = {
        placementName:
          selectedForBudgetSetting &&
          selectedForBudgetSetting
            .filter((item) => item.campaign_type !== 'sba' && item.campaign_type !== 'video')
            .map((item) => item.name),
        placementId:
          selectedForBudgetSetting &&
          selectedForBudgetSetting
            .filter((item) => item.campaign_type !== 'sba' && item.campaign_type !== 'video')
            .map((item) => item.id),
        TypeLength: uniqueType
      };
      setSelectedBudget(newPayload);
      setSelectedPlacement(payload_placement);

      return null;
    },
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: PageValue,
    customToolbar: () => (
      <>
        {/* <Button sx={{ pl: 2, pr: 2, mr: 1 }} onClick={handleColumnSelectorOpen}>
          <img src={viewcolumnsIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
        </Button>

        {create && (
          <Button variant="contained" onClick={() => setModalOpen(!modalOpen)}>
            Create Campaign
          </Button>
        )}

        {video_management && (
          <Button sx={{ ml: 2 }} variant="outlined" onClick={() => navigate('/video-management')}>
            Video Management
          </Button>
        )}

        {showSelectAdviser && download && (
          <Button sx={{ pl: '30px' }} onClick={handleDownloadCsv}>
            <img src={DownloadIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
          </Button>
        )} */}
      </>
    ),
    textLabels: {
      body: {
        noMatch: showSelectAdviser ? (
          tableLoading ? (
            <Loader />
          ) : (
            'Sorry, there is no matching data to display'
          )
        ) : (
          'Please Select At List One Advertiser '
        )
      }
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      SetShowDropDown(false);
      getCampaignList(0, row);
    },
    onChangePage: (page) => {
      setPage(page);
      SetShowDropDown(false);
      getCampaignList(page, rowPerPage);
    },

    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setSearch('');
      const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
      getAdvertiserListWithPagination(
        0,
        rowPerPage,
        '',
        '',
        '',
        filterForSearch,
        advertiserFilter,
        commonDateRange,
        PerformanceFilterData,
        advanceRangesForSearch
      );
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);

      setSearch('');
      SetShowDropDown(false);
      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      // getCampaignList(page, rowPerPage)
      const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
      getAdvertiserListWithPagination(
        0,
        rowPerPage,
        '',
        changedColumn,
        direction,
        filterForSearch,
        advertiserFilter,
        commonDateRange,
        shouldApplyFilter?.current ? PerformanceFilterData : {},
        shouldApplyFilter?.current ? advanceRangesForSearch : {}
      );
    },
    customTableBodyFooterRender: (opts) => {
      const optsColumns = opts?.columns;
      const reorderedColumns = columnOrder.map((index) => optsColumns[index]);
      return (
        <>
          {commonAdvertiser?.length > 0 && (
            <TableFooter>
              <TableRow
                className="table-footer-total"
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  background: 'white',
                  zIndex: 999,
                  color: '#364152 !important',
                  borderTop: '1px solid black !important'
                }}
              >
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    position: 'sticky',
                    left: 1,
                    // minWidth: '180px',
                    background: 'white'
                    // zIndex: 100
                  }}
                ></TableCell>
                {reorderedColumns?.map((col, index) => {
                  if (col && col.display === 'true' && newDataTotal) {
                    let currency = newDataTotal[col?.name] && newDataTotal[col?.name].indexOf('$') > -1;
                    return (
                      <TableCell
                        className={`${col?.sticky ? 'column-sticky' : 'column-sticky-1'} ${
                          alignRightColumn(col?.name) && 'table-align-right'
                        }`}
                        sx={
                          col?.sticky
                            ? {
                                whiteSpace: 'nowrap',
                                position: 'sticky',
                                left: col?.leftTotal,
                                fontSize: '14px',
                                // fontSize: col?.FontSize?.length > 0 ? col?.FontSize : '',
                                minwidth: col?.name === 'status' ? '90px' : '180px',
                                background: 'white',
                                // zIndex: 100,
                                color: '#0B081B',
                                fontWeight: 'bold'
                              }
                            : {
                                textAlign: 'right'
                              }
                        }
                        key={index}
                      >
                        {col?.name == 'name' ? (
                          'Total'
                        ) : (
                          <>
                            <span className="clr3 fw700" style={{ fontSize: '14px' }}>
                              {col?.name === 'total_budget' || col?.name === 'daily_budget' ? '' : newDataTotal[col?.name] || ''}
                            </span>
                            <CompareValueWithColor
                              value={newDataTotal[`comparison_${col?.name}`] && newDataTotal[`comparison_${col?.name}`]}
                              Percentage={
                                newDataTotal[`percentage_${col?.name}`] && parseFloat(newDataTotal[`percentage_${col?.name}`])
                              }
                              UpDown={
                                typeof newDataTotal[`is_higher_${col?.name}`] != 'undefined' &&
                                newDataTotal[`is_higher_${col?.name}`] == 'true'
                                  ? true
                                  : false
                              }
                              keys={col?.name}
                              isTotalRow={true}
                              currency={currency}
                            />
                          </>
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
  };

  const handledClose = () => {
    setModalOpen(!modalOpen);
  };

  const handledEditClose = () => {
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    getAdvertiserListWithPagination(0, rowPerPage, '', '', '', filterForSearch, advertiserFilter, commonDateRange);
    setCampaignModal({ open: false, id: null });
  };

  const handledEditBrandClose = () => {
    setCampaignBrandModal({ open: false, id: null });
  };

  const handleEditVideoClose = () => {
    setCampaignVideoModal({ open: false, id: null });
  };

  const handleItemModalClose = () => {
    setItemModal({ open: false, id: null, name: null });
    dispatch(setAdgroupList([])); //Clear selected campaign adgroups in store
  };

  const handleSingleEditsClose = () => {
    setCampaignEditModal({ open: false, id: null, advertiser_id: null });
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    getAdvertiserListWithPagination(page, rowPerPage, search, '', '', filterForSearch, advertiserFilter, commonDateRange);
  };
  const handleKeywordModalClose = () => {
    setKeywordModal({ open: false, id: null });
    dispatch(setAdgroupList([])); //Clear selected campaign adgroups in store
  };
  const handledReviewClose = () => {
    setReviewModal({ open: false });
  };
  const handledLogReviewClose = () => {
    setReviewLogModal({ open: false, id: null, name: null });
    dispatch(setReviewLogData([]));
  };
  const handlePlacementEditClose = () => {
    setPlacementEditModal({ open: false, data: null });
  };

  const handleSuccessForCampaign = (id, placementData) => {
    const updatedCampaignDetails = updateCampaignName(campaignDetails, id, [placementData]);
    dispatch(setCampaignDetails(updatedCampaignDetails));

    setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
    setLoading(false);
    setTableLoading(false);
    setInlineLoader(null);
  };

  const handlePlateformEditClose = () => {
    setPlateformEditModal({ open: false, data: null });
  };

  const onGetCampaignData = (id, placementData) => {
    const updatedCampaignDetails = updateCampaignName(campaignDetails, id, [placementData]);
    dispatch(setCampaignDetails(updatedCampaignDetails));

    setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
    setLoading(false);
    setTableLoading(false);
    setInlineLoader(null);
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
      getAdvertiserListWithPagination(0, rowPerPage, data, '', '', filterForSearch, advertiserFilter, commonDateRange);
    }, 500),
    []
  );

  const handleSearchClick = () => {
    shouldApplyFilter.current = true;
    setFilterForSearch({ ...tableFilter });
    const filledPerformanceRanges = Object.entries(performanceRanges).reduce((acc, [performanceId, values]) => {
      const { min, max } = values;
      if (min?.length > 0 || max?.length > 0) {
        acc[performanceId] = values;
      }
      return acc;
    }, {});
    const comparisonValues =
      filterDataList?.Performance &&
      filterDataList?.Performance?.length > 0 &&
      filterDataList?.Performance?.map((item) => item.value);
    // Filter dataRanges based on comparisonValues

    const PerformanceFilter =
      comparisonValues &&
      Object.fromEntries(Object.entries(filledPerformanceRanges)?.filter(([key]) => comparisonValues?.includes(key)));

    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    setPerformanceFilterData(PerformanceFilter);
    setAdvanceRangesForSearch(advanceRanges);
    getAdvertiserListWithPagination(
      0,
      rowPerPage,
      search,
      appliedSortTing?.changedColumnSort,
      appliedSortTing?.directionSort,
      tableFilter,
      advertiserFilter,
      commonDateRange,
      PerformanceFilter,
      advanceRanges
    );
  };

  const handleInputChange = (performanceId, valueType, value, showError) => {
    setErrorShow((ErrorShow) => ({
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    }));

    let a = {
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    };
    let output = Object.values(a).map((item) => {
      if (typeof item === 'object' && item !== null && 'error' in item) {
        return item.error;
      } else {
        return item;
      }
    });
    let result = [...new Set(output)];

    let finalOutPut = result.length == 1 && result[0] == false ? false : true;
    setFinalShow(finalOutPut);
    setPerformanceRanges((prevRanges) => ({
      ...prevRanges,
      [performanceId]: {
        ...prevRanges[performanceId],
        [valueType]: value
      }
    }));
  };

  const handleInputChangeAdvanced = (performanceId, valueType, value, showError) => {
    setErrorShow((ErrorShow) => ({
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    }));

    let a = {
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    };
    let output = Object.values(a).map((item) => {
      if (typeof item === 'object' && item !== null && 'error' in item) {
        return item.error;
      } else {
        return item;
      }
    });
    let result = [...new Set(output)];

    let finalOutPut = result.length == 1 && result[0] == false ? false : true;
    setFinalShow(finalOutPut);
    setAdvanceRanges((prevRanges) => ({
      ...prevRanges,
      [performanceId]: {
        ...prevRanges[performanceId],
        [valueType]: value
      }
    }));
  };

  const [StatusValueForCondition, setStatusForCondition] = useState('');

  const handleChangeDropdownStatus = () => {
    setLoading(true);
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    let payload11 = {
      payload: {
        campaign_id: selectedArray,
        status: StatusValueForCondition
      },
      listingObj: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        filterForSearch,
        advertiserFilter,
        commonDateRange,
        isComparingDates
      }
    };
    // dispatch(setCommonLoader(true));
    dispatch(FilterStatusEnableAndPause(payload11)).then((res) => {
      if (res.payload.status == 200) {
        setLoading(false);
        const updatedCampaignDetails = updateCampaignBulk(campaignDetails, res?.payload?.data?.data, payload11?.payload);
        dispatch(setCampaignDetails(updatedCampaignDetails));

        SetShowDropDown(false);
        setSuccessFailModal(true);
        setSuccessFailData(res?.payload?.data?.data);
        setIsMultipleStatusConfirmationOpen(false);
        setStatusForCondition('');
      }
    });
  };

  const handleCloseConfirmation = () => {
    setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
    setConfirmationOpen(false);
  };

  const handleUpdateAdGroupReport = () => {
    setConfirmationOpen(false);
    handleSaveButton(savedDataForEdit?.value, savedDataForEdit?.advertiser, savedDataForEdit?.id, savedDataForEdit?.type);
  };

  const [selectedValue, setSelectedValue] = useState(5);
  const [isMultipleBidConfirmationOpen, setIsMultipleBidConfirmationOpen] = useState(false);

  const [isMultipleEndDateConfirmationOpen, setIsMultipleEndDateConfirmationOpen] = useState(false);
  const [isMultipleStatusConfirmationOpen, setIsMultipleStatusConfirmationOpen] = useState(false);
  const [isMultiplePlatformBidConfirmationOpen, setIsMultiplePlatformBidConfirmationOpen] = useState(false);
  const [isMultiplePlacementBidConfirmationOpen, setIsMultiplePlacementBidConfirmationOpen] = useState(false);
  const [BudgetType, setBudgetType] = useState('label');
  const [BudgetAmount, setBudgetAmount] = useState('');
  const [BudgetAmountCurrent, setBudgetAmountCurrent] = useState('$');
  const [BudgetAmountCurrentDollar, setBudgetAmountCurrentDollar] = useState('');
  const [selectedOption, setSelectedOption] = useState('+');

  const handleBudgetTypeChange = (event) => {
    resetRelevantState();
    setBudgetType(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const resetRelevantState = () => {
    setBudgetAmount('');
    setBudgetType('label');
    setSelectedOption('+');
    setBudgetAmountCurrent('$');
    setBudgetAmountCurrentDollar('');
    setCheckbox1Checked(false);
    setTextField1Value('');
    setCheckbox2Checked(false);
    setTextField2Value('');
  };

  const handleChange = (value) => {
    setSelectedValue(value);
    if (value == 1) {
      resetRelevantState();
      setIsMultipleBidConfirmationOpen(true);
    }
    if (value == 2) {
      resetRelevantState();
      setIsMultipleBidConfirmationOpen(true);
    }
    if (value == 6) {
      setIsMultipleEndDateConfirmationOpen(true);
    }
    if (value == 7) {
      setIsMultipleStatusConfirmationOpen(true);
      setStatusForCondition('paused');
    }
    if (value == 8) {
      setIsMultipleStatusConfirmationOpen(true);
      setStatusForCondition('enabled');
    }
    if (value == 10) {
      setIsMultiplePlatformBidConfirmationOpen(true);
    }
    if (value == 11) {
      setIsMultiplePlacementBidConfirmationOpen(true);
    }
    if (value == 'AddItem') {
      setProfileModal(true);
    }
    if (value == 'AddKeyword') {
      setProfileModalKeyword(true);
    }
  };

  const updateBulkCampaignDetails = (details, newObj) => {
    return {
      ...details,
      results: details.results.map((item) => {
        const updates = newObj[item.id];
        return updates ? { ...item, ...updates } : item;
      })
    };
  };

  const updateCampaignBulk = (details, response, payload) => {
    const successCount = response?.successcount;
    const totalCount = response?.totalcount;

    let successCmpIds = [];
    if (totalCount === successCount) {
      successCmpIds = payload?.campaign_id;
    } else {
      const errorMessage = response?.error_message;
      const errorCount = errorMessage?.length;
      if (totalCount !== errorCount) {
        const errorCmpIds = errorMessage.map((l) => l?.id);
        const allPayloadCmpIds = payload?.campaign_id;
        successCmpIds = allPayloadCmpIds.filter((id) => !errorCmpIds.includes(id));
      }
    }

    if (successCmpIds?.length) {
      const newObj = {};
      successCmpIds?.map((cmpId) => {
        if (payload?.status) {
          newObj[cmpId] = {
            status: payload?.status
          };
        } else if (payload?.placement_bid_multiplier) {
          const platformPayload = payload?.placement_bid_multiplier;
          let tempPlatform = {};
          platformPayload?.map((l) => {
            const tempObj = {
              [l?.key]: l?.multiplier
            };
            tempPlatform = { ...tempPlatform, ...tempObj };
          });
          newObj[cmpId] = tempPlatform;
        } else if (payload?.platform_bid_multiplier) {
          const platformPayload = payload?.platform_bid_multiplier;
          let tempPlatform = {};
          platformPayload?.map((l) => {
            const tempObj = {
              [l?.key]: l?.multiplier
            };
            tempPlatform = { ...tempPlatform, ...tempObj };
          });
          newObj[cmpId] = tempPlatform;
        } else if (payload?.end_date) {
          newObj[cmpId] = {
            end_date: payload?.end_date
          };
        } else if (payload?.sign) {
          const sign = payload?.sign;
          const byWhat = payload?.by_value;
          const budgetType = 'daily_budget' in payload ? 'daily_budget' : 'total_budget';

          const getCmp = details?.results?.find((l) => l?.id === cmpId); //Get Success Campaign Details
          const getBudget = getCmp[budgetType]; //Daily or Total Budget Current Amount
          const budgetAmt = payload[budgetType]; //Input Value

          if (sign === '+' && byWhat === 'amount') {
            const updatedBudget = getBudget + budgetAmt;
            newObj[cmpId] = {
              [budgetType]: formatNumber(updatedBudget)
            };
          } else if (sign === '-' && byWhat === 'amount') {
            const updatedBudget = getBudget - budgetAmt;
            newObj[cmpId] = {
              [budgetType]: formatNumber(updatedBudget)
            };
          } else if (sign === '+' && byWhat === 'percent') {
            const percentageValue = (getBudget * budgetAmt) / 100;
            const updatedBudget = getBudget + percentageValue;
            newObj[cmpId] = {
              [budgetType]: formatNumber(updatedBudget)
            };
          } else if (sign === '-' && byWhat === 'percent') {
            const percentageValue = (getBudget * budgetAmt) / 100;
            const updatedBudget = getBudget - percentageValue;
            newObj[cmpId] = {
              [budgetType]: formatNumber(updatedBudget)
            };
          }
        } else {
          //Set Amount Directly
          const budgetType = 'daily_budget' in payload ? 'daily_budget' : 'total_budget';
          newObj[cmpId] = {
            [budgetType]: payload[budgetType]
          };
        }
      });

      const updatedDetails = updateBulkCampaignDetails(details, newObj);
      return updatedDetails;
    } else {
      return details;
    }
  };

  const handleConfirm = () => {
    setLoading(true);
    const key = selectedValue == '1' ? 'daily_budget' : 'total_budget';
    const DailyId = SelectedBudget?.DailyData?.DailyId;
    const TotalId = SelectedBudget?.TotalData?.DailyId;
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';

    let payloadInfo = {};

    if (BudgetType == 'label') {
      const payload = {
        payload1: {
          campaign_id: selectedValue == '1' ? DailyId : TotalId,
          [key]: Number(BudgetAmount)
        },
        listingObj: {
          page,
          rowPerPage,
          search,
          changedColumn: appliedSortTing?.changedColumnSort,
          direction: appliedSortTing?.directionSort,
          filterForSearch,
          advertiserFilter,
          commonDateRange,
          isComparingDates
        }
      };

      payloadInfo = payload?.payload1;

      // dispatch(setCommonLoader(true));

      dispatch(updateMultipleBudgetCampaign(payload)).then((res) => {
        setLoading(false);
        const updatedCampaignDetails = updateCampaignBulk(campaignDetails, res?.payload?.data?.data, payloadInfo);
        dispatch(setCampaignDetails(updatedCampaignDetails));
        SetShowDropDown(false);
        setBudgetAmount('');
        setSelectedBudget([]);
        setSuccessFailModal(true);
        setSuccessFailData(res?.payload?.data?.data);
        setIsMultipleBidConfirmationOpen(false);
      });
    } else {
      const payload = {
        payload1: {
          campaign_id: selectedValue == '1' ? DailyId : TotalId,
          [key]: Number(BudgetAmountCurrentDollar),
          by_value: BudgetAmountCurrent == '$' ? 'amount' : 'percent',
          sign: selectedOption
        },
        listingObj: {
          page,
          rowPerPage,
          search,
          changedColumn: appliedSortTing?.changedColumnSort,
          direction: appliedSortTing?.directionSort,
          filterForSearch,
          advertiserFilter,
          commonDateRange,
          isComparingDates
        }
      };

      payloadInfo = payload?.payload1;

      // setBulkEditInfo(payload?.payload1);
      if (textField1Value && checkbox1Checked) {
        Object.assign(payload.payload1, { no_lower: Number(textField1Value) });
      }
      if (textField2Value && checkbox2Checked) {
        Object.assign(payload.payload1, { no_higher: Number(textField2Value) });
      }
      // dispatch(setCommonLoader(true));
      dispatch(updateMultipleBudgetCampaign(payload)).then((res) => {
        const updatedCampaignDetails = updateCampaignBulk(campaignDetails, res?.payload?.data?.data, payloadInfo);
        dispatch(setCampaignDetails(updatedCampaignDetails));
        SetShowDropDown(false);
        setCheckbox1Checked(false);
        setCheckbox2Checked(false);
        setTextField1Value('');
        setTextField2Value('');
        setBudgetType('label');
        setSuccessFailModal(true);
        setSuccessFailData(res?.payload?.data?.data);
        setBudgetAmount('');
        setBudgetAmountCurrentDollar(0);
        setSelectedBudget([]);
        setIsMultipleBidConfirmationOpen(false);
      });
    }
    setSelectedValue(5);
  };

  const handleConfirmEndDate = () => {
    // EditEndDateMultiple
    setLoading(true);
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    const payload = {
      payload1: {
        campaign_id: selectedArray,
        end_date: EditEndDateMultiple
      },
      listingObj: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        filterForSearch,
        advertiserFilter,
        commonDateRange,
        isComparingDates
      }
    };
    // dispatch(setCommonLoader(true));
    dispatch(updateMultipleEndCampaign(payload)).then((res) => {
      setLoading(false);
      const updatedCampaignDetails = updateCampaignBulk(campaignDetails, res?.payload?.data?.data, payload?.payload1);
      dispatch(setCampaignDetails(updatedCampaignDetails));
      SetShowDropDown(false);
      setIsMultipleEndDateConfirmationOpen(false);
      setSuccessFailModal(true);
      setSuccessFailData(res?.payload?.data?.data);
    });
    setSelectedValue(5);
  };

  const HandleModalCloseBudget = () => {
    setIsMultipleBidConfirmationOpen(false);
    setSelectedValue(5);
  };

  const HandleModalCloseEndDate = () => {
    setIsMultipleEndDateConfirmationOpen(false);
    setSelectedValue(5);
  };

  const HandleModalCloseStatus = () => {
    setIsMultipleStatusConfirmationOpen(false);
    setSelectedValue(5);
  };

  const HandleModalClosePlatformBid = () => {
    setIsMultiplePlatformBidConfirmationOpen(false);
    // setSelectedValue(5);
  };

  const HandleModalClosePlacementBid = () => {
    setIsMultiplePlacementBidConfirmationOpen(false);
    // setSelectedValue(5);
  };

  // const handleConfirmStatusChange = () => {
  //   handleChangeDropdownStatus();
  // };

  const handleCloseProfileModal = () => {
    setProfileModal(false);
  };

  const handleCloseProfileKeyword = () => {
    setProfileModalKeyword(false);
  };

  // const [focused, setFocused] = useState(false);
  // const handleFocus = () => {
  //   setFocused(true);
  // };

  // const handleBlur = () => {
  //   setFocused(false);
  // };

  const [desktop, setDesktop] = useState('');
  const [app, setApp] = useState('');
  const [mobile, setMobile] = useState('');

  const [ingrid, setIngrid] = useState(0);
  const [buyBox, setBuyBox] = useState(0);
  const [homePage, setHomePage] = useState(0);
  const [stockUp, setStockUp] = useState(0);

  const handleDesktopChange = (event) => {
    const value = event.target.value;
    if (value > 900) {
      setDesktop(900);
    } else {
      setDesktop(value);
    }
  };

  const handleAppChange = (event) => {
    const value = event.target.value;
    if (value > 900) {
      setApp(900);
    } else {
      setApp(value);
    }
  };

  const handleMobileChange = (event) => {
    const value = event.target.value;
    if (value > 900) {
      setMobile(900);
    } else {
      setMobile(value);
    }
  };

  const handleSubmit = (event) => {
    // Handle form submission
    event.preventDefault();
    setLoading(true);
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    const payload = {
      payload1: {
        campaign_id: selectedArray,
        platform_bid_multiplier: [
          {
            key: 'platform_bid_desktop',
            platformType: 'Desktop',
            multiplier: Number(desktop)
          },
          {
            key: 'platform_bid_app',
            platformType: 'App',
            multiplier: Number(app)
          },
          {
            key: 'platform_bid_mobile',
            platformType: 'Mobile',
            multiplier: Number(mobile)
          }
        ]
      },
      listingObj: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        filterForSearch,
        advertiserFilter,
        commonDateRange,
        isComparingDates
      }
    };
    // dispatch(setCommonLoader(true));
    dispatch(updateMultiplePlatformCampaign(payload)).then((res) => {
      setLoading(false);
      const updatedCampaignDetails = updateCampaignBulk(campaignDetails, res?.payload?.data?.data, payload?.payload1);
      dispatch(setCampaignDetails(updatedCampaignDetails));
      SetShowDropDown(false);
      setDesktop('');
      setApp('');
      setSuccessFailModal(true);
      setSuccessFailData(res?.payload?.data?.data);
      setMobile('');
      setIsMultiplePlatformBidConfirmationOpen(false);
    });
  };

  const [SIngrid, setSIngrid] = useState(true);
  const [SBuyBox, setSBuyBox] = useState(true);
  const [SHomePage, setSHomePage] = useState(true);
  const [searchCarousel, SetSearchCarousel] = useState(false);
  const [itemCarousel, SetItemCarousel] = useState(false);
  const [SStock, setSStock] = useState(true);
  const [others, setOthers] = useState(true);

  const handleSubmitMultiplePlacementBid = () => {
    setLoading(true);
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';

    if (SelectedPlacement.TypeLength == 'auto') {
      let payload11 = {
        payload: {
          campaign_id: SelectedPlacement?.placementId,
          placement_bid_multiplier: [
            {
              key: 'placement_bid_item_buybox',
              placementType: 'Buy-Box',
              multiplier: buyBox
            },
            {
              key: 'placement_bid_search_ingrid',
              placementType: 'Search Ingrid',
              multiplier: ingrid
            },
            {
              key: 'placement_bid_home_page',
              placementType: 'Home Page',
              multiplier: homePage
            },
            {
              key: 'placement_bid_stock_up',
              placementType: 'Stock Up',
              multiplier: stockUp
            }
          ]
        },
        listingObj: {
          page,
          rowPerPage,
          search,
          changedColumn: appliedSortTing?.changedColumnSort,
          direction: appliedSortTing?.directionSort,
          filterForSearch,
          advertiserFilter,
          commonDateRange,
          isComparingDates
        }
      };
      // dispatch(setCommonLoader(true));
      dispatch(CampaignPlacementAutoManual(payload11)).then((res) => {
        setLoading(false);
        const updatedCampaignDetails = updateCampaignBulk(campaignDetails, res?.payload?.data?.data, payload11?.payload);
        dispatch(setCampaignDetails(updatedCampaignDetails));

        SetShowDropDown(false);
        setIngrid(0);
        setBuyBox(0);
        setHomePage(0);
        setStockUp(0);
        setIsMultiplePlacementBidConfirmationOpen(false);
      });
    } else {
      let payload11 = {
        payload: {
          campaign_id: SelectedPlacement?.placementId,
          placement_bid_multiplier: SBuyBox
            ? [
                {
                  key: 'placement_bid_item_buybox',
                  placementType: 'Buy-Box',
                  multiplier: buyBox
                },
                {
                  key: 'placement_bid_search_ingrid',
                  placementType: 'Search Ingrid',
                  multiplier: ingrid
                }
              ]
            : [
                {
                  key: 'placement_bid_search_ingrid',
                  placementType: 'Search Ingrid',
                  multiplier: ingrid
                },
                {
                  key: 'placement_bid_item_buybox',
                  placementType: 'Buy-Box',
                  multiplier: 0
                }
              ],
          placement_ads_multiplier: [
            {
              placement: 'Search Carousel',
              status: searchCarousel ? 'included' : 'excluded'
            },
            {
              placement: 'Item Buybox',
              status: SBuyBox ? 'included' : 'excluded'
            },
            {
              placement: 'Item Carousel',
              status: itemCarousel ? 'included' : 'excluded'
            }
          ]
        },
        listingObj: {
          page,
          rowPerPage,
          search,
          changedColumn: appliedSortTing?.changedColumnSort,
          direction: appliedSortTing?.directionSort,
          filterForSearch,
          advertiserFilter,
          commonDateRange,
          isComparingDates
        }
      };
      // dispatch(setCommonLoader(true));
      dispatch(CampaignPlacementAutoManual(payload11)).then((res) => {
        setLoading(false);
        const updatedCampaignDetails = updateCampaignBulk(campaignDetails, res?.payload?.data?.data, payload11?.payload);
        dispatch(setCampaignDetails(updatedCampaignDetails));

        SetShowDropDown(false);
        setSBuyBox(true);
        SetSearchCarousel(false);
        setIngrid(0);
        setBuyBox(0);
        setHomePage(0);
        setStockUp(0);
        SetItemCarousel(false);
        setIsMultiplePlacementBidConfirmationOpen(false);
      });
    }
  };
  useEffect(() => {
    if (SelectedPlacement?.TypeLength?.length == 1) {
      setTrueValue(false);
    } else {
      setTrueValue(true);
    }
  }, [SelectedPlacement]);

  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [textField1Value, setTextField1Value] = useState('');
  const [textField2Value, setTextField2Value] = useState('');

  const handleCheckbox1Change = (event) => {
    setCheckbox1Checked(event.target.checked);
    if (event.target.checked) {
      setTextField1Value(''); // Reset text field value if checkbox is checked
    }
  };
  const handleCheckbox2Change = (event) => {
    setCheckbox2Checked(event.target.checked);
    if (event.target.checked) {
      setTextField2Value(''); // Reset text field value if checkbox is checked
    }
  };

  const handleItemDropdownChange = (value) => {
    setItemDropdown(value);
    setTableFilter({ ...tableFilter, itemDropdownValue: value });
  };

  return (
    <MainCard title="Campaign List">
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>
            <CustomFilters filterType={'campaign_filter'} />
            <Box ml={2}>
              <FormControl fullWidth sx={{ minWidth: '100px' }}>
                <InputLabel color="secondary">Attribution</InputLabel>
                <Select
                  value={attribute || []}
                  color="secondary"
                  // size="small"
                  label="Attr. window"
                  fullWidth
                  onChange={(e) => {
                    setAttribute(e.target.value);
                    setTableFilter({ ...tableFilter, attr_windows: e.target.value });
                    setFilterValue({ ...filterValue, attr_windows: e.target.value });
                  }}
                  sx={{ width: '100%' }}
                >
                  {attributeWindowList?.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            {showDropdown && update && (
              <Box className="Custom-bulk-name" sx={{ ml: 2 }}>
                <NestedDropdown
                  className="Custom-nested-dropdown"
                  menuItemsData={menuItemsData}
                  MenuProps={{ elevation: 1, className: 'Custom-menu-bulk' }}
                  ButtonProps={{
                    variant: 'contained',
                    className: 'Custom-dropDown-bulk',
                    startIcon: <img src={MultiSelectIcon} alt="multiselect icon" style={{ width: '24px', height: '24px' }} />, // Adding the start icon here
                    endIcon: <img src={arrowDownIcon} alt="multiselect icon" style={{ width: '24px', height: '24px' }} /> // Remove any end icon including a dropdown arrow
                  }}
                />
              </Box>
            )}
          </Box>
          <Box display={'flex'}>
            {create && (
              <Button
                sx={{
                  borderRadius: '8px',
                  py: '12px',
                  mr: 1
                }}
                startIcon={<Add />}
                onClick={() => setModalOpen(!modalOpen)}
                size=""
                variant="contained"
              >
                Add Campaigns
              </Button>
            )}
            <Button sx={{ p: '0px !important', minWidth: '45px' }} onClick={handleColumnSelectorOpen}>
              <Tooltip arrow title="Select/Reorder Columns">
                {' '}
                <img src={viewcolumnsIcon} alt="filter icon" />{' '}
              </Tooltip>
            </Button>
            {showSelectAdviser && download && (
              <Button sx={{ p: '0px !important', minWidth: '45px' }} onClick={handleDownloadCsv}>
                {loadingDownload ? (
                  <CircularProgress size="20px" />
                ) : (
                  <Tooltip arrow title="Download">
                    {' '}
                    <img src={DownloadIcon} alt="filter icon" />{' '}
                  </Tooltip>
                )}
              </Button>
            )}
          </Box>
        </Box>

        <Grid container sx={{ pt: 3, pb: 3 }} spacing={2}>
          {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'campaign') && (
            <Grid item xs={12} md={3}>
              <CustomAutocomplete
                multiple
                id="checkboxes-tags-demo"
                name="Campaign"
                label="Campaign"
                className="custom-auto"
                optionLabelkey="name"
                optionIdkey="id"
                options={campaignNameList || []}
                value={filterValue?.campaign__in || []}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
                inputValue={isDropdownOpen ? searchText?.campaign_search : ''}
                onInputChange={(event, value, reason) => {
                  if (reason !== 'reset') {
                    setSearchText({ ...searchText, campaign_search: value });
                  }
                }}
                onChange={(e, newValue) => {
                  handleAutoCompleteOnChange(newValue, 'campaign_search');
                }}
                selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_search')}
                placeholderCondition={filterValue?.campaign__in?.length > 0}
              />
            </Grid>
          )}
          {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'campaign_type') && (
            <Grid item xs={12} md={3}>
              <CustomAutocomplete
                multiple
                id="checkboxes-tags-demo"
                name="adgroup_name"
                color="secondary"
                label="Campaign Type"
                options={campaign_type || []}
                value={filterValue?.type || []}
                onChange={(e, value) => {
                  handleAutoCompleteOnChange(value, 'campaign_type');
                }}
                optionLabelkey="name"
                optionIdkey="id"
                inputValue={isDropdownOpenCamType ? searchText?.campaign_type : ''}
                onInputChange={(event, value, reason) => {
                  if (reason !== 'reset') {
                    setSearchText({ ...searchText, campaign_type: value });
                  }
                }}
                onOpen={() => setIsDropdownOpenCamType(true)}
                onClose={() => setIsDropdownOpenCamType(false)}
                selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_type')}
                placeholderCondition={filterValue?.type?.length > 0}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                limitTags={2}
              />
            </Grid>
          )}

          {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'state') && (
            <Grid item xs={12} md={3}>
              <CustomAutocomplete
                multiple
                id="tags-outlined"
                name="adgroup_name"
                color="secondary"
                label="Campaign State"
                options={campaigState || []}
                value={filterValue?.status || []}
                onOpen={() => setIsDropdownOpenCamSt(true)}
                onClose={() => setIsDropdownOpenCamSt(false)}
                onChange={(e, value) => {
                  handleAutoCompleteOnChange(value, 'status');
                }}
                optionLabelkey="name"
                optionIdkey="id"
                inputValue={isDropdownOpenCamSt ? searchText?.status : ''}
                onInputChange={(event, value, reason) => {
                  if (reason !== 'reset') {
                    setSearchText({ ...searchText, status: value });
                  }
                }}
                selectAllCheckboxCondiion={handleAutoCompleteSelectAll('status')}
                placeholderCondition={filterValue?.status?.length > 0}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                limitTags={2}
              />
            </Grid>
          )}

          {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'sort_by') && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel color="secondary" id="demo-simple-select-label">
                  Sory By
                </InputLabel>
                <Select
                  value={selectedSortBy || []}
                  color="secondary"
                  // size="small"
                  label="Sory By"
                  placeholder="Select Sort By"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    setSelectedSortBy(e.target.value);
                  }}
                  sx={{ width: '100%' }}
                >
                  {sortByList?.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'automation_status') && (
            <Grid item xs={12} md={3}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                name="adgroup_name"
                // size="small"
                options={automationStatusList || []}
                // value={filterValue?.type}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                color="secondary"
                getOptionLabel={(option) => option?.name}
                // onChange={(e, value) => {
                //   const idArray = value.map(item => item.value);
                //   const uniqueIdArray = [...new Set(idArray)];
                //   const idString = uniqueIdArray.join(',');
                //   const targetArray = value.map(item => item.type);
                //   const targetString = targetArray.join(',');
                //   setTableFilter({ ...tableFilter, type: idString, targeting: targetString });
                //   setFilterValue({ ...filterValue, type: value });
                // }}
                // filterSelectedOptions
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option?.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    color="secondary"
                    sx={{ widh: '250px' }}
                    size="small"
                    label="Automation Status"
                    {...params}
                    placeholder="Search Automation Status"
                  />
                )}
              />
            </Grid>
          )}

          {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'search') && (
            <Grid item xs={12} md={3}>
              <BasicPopover
                tableFilter={tableFilter}
                setTableFilter={setTableFilter}
                searchCampaign={searchCampaign}
                setSearchCampaign={setSearchCampaign}
                from={'Campaign'}
                isReset={shouldApplyFilter?.current}
              />
            </Grid>
          )}

          {filterDataList?.Performance && (
            <>
              {filterDataList?.Performance?.map((performanceItem) => (
                <Grid item xs={12} md={3} key={performanceItem.id}>
                  <FilterPerformance
                    performanceItem={performanceItem}
                    isReset={shouldApplyFilter?.current}
                    handleInputChange={handleInputChange}
                  />
                </Grid>
              ))}
            </>
          )}

          {/* Item */}
          {filterDataList && filterDataList?.Advanced?.find((item) => item?.value == 'item') && (
            // <Grid item xs={12} md={3}>

            <>
              {' '}
              {/* {setTableFilter({ ...tableFilter, itemDropdownValue: itemDropdown })} */}
              <FilterAdvance
                isReset={shouldApplyFilter?.current}
                tableFilter={filterForSearch}
                setTableFilter={setTableFilter}
                searchCampaign={searchItemId}
                setSearchCampaign={setSearchItemId}
                itemDropdown={itemDropdown}
                handleItemDropdownChange={handleItemDropdownChange}
              />
            </>
            // </Grid>
          )}

          {/* Bid */}
          {filterDataList && filterDataList?.Advanced?.find((item) => item?.value == 'actlbid_avlbid') && (
            <Grid item xs={12} md={3}>
              <Box
                border="1px solid #ccc"
                padding="0px"
                borderRadius="12px"
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ backgroundColor: '#f8fafc' }}
              >
                <Typography
                  htmlFor="price"
                  style={{ marginBottom: 0, padding: 5, border: 'none', display: 'flex', alignItems: 'center' }}
                >
                  {'Bid'}
                </Typography>
                <Box borderLeft="1px solid gray" height="20px" />
                <FormControl fullWidth sx={{ width: '40%' }}>
                  <Select
                    value={selectedSortBy}
                    color="secondary"
                    // size="small"
                    // label="Sory By"
                    placeholder="Select Sort By"
                    fullWidth
                    variant="outlined"
                    sx={{
                      '& fieldset': { border: 'none' },
                      padding: '2.5px 4px 2.5px 8px !important'
                      // width: "100px"
                    }}
                    onChange={(e) => setSelectedSortBy(e.target.value)}
                  >
                    {signList?.map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  type="number"
                  id="maxPrice"
                  sx={{
                    '& fieldset': { border: 'none' },
                    padding: '2.5px 4px 2.5px 8px !important'
                  }}
                  // size="small"
                  disableFocusRipple
                  placeholder=""
                  style={{ width: '40%', border: 'none', borderRadius: '5px', padding: '2px', outline: 'none', fontSize: '12px' }}
                />
              </Box>
            </Grid>
          )}

          {filterDataList?.Advanced && (
            <>
              {filterDataList?.Advanced?.map((AdvanceItem, index) => {
                if (AdvanceItem?.name !== 'Item') {
                  return (
                    <Grid item xs={12} md={3} key={index}>
                      <FilterAdvanced
                        isReset={shouldApplyFilter?.current}
                        AdvanceItem={AdvanceItem}
                        handleInputChangeAdvanced={handleInputChangeAdvanced}
                      />
                    </Grid>
                  );
                }
                return null; // Return null if the condition is not met
              })}
            </>
          )}

          <Grid item md={3}>
            <Button
              sx={{
                borderRadius: '8px',
                py: '12px',
                minWidth: '135px'
              }}
              disabled={finalErrorShow}
              startIcon={
                <img src={searchIcon} alt="filter icon" style={{ width: '18px', height: '18px', fontFamily: 'Inter' }} />
              }
              onClick={handleSearchClick}
              size=""
              variant="contained"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </>

      {skeletonLoader && <TableSkeleton />}
      <Box sx={{ display: skeletonLoader ? 'none' : 'block' }} className={showSelectAdviser ? 'custom-campaign-table' : ''}>
        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          data={tableLoading ? [] : showSelectAdviser ? newData : []}
          columns={getUpdatedColumns(columns, selectedColumns)}
          options={options}
          className="pnl-by-item"
        />
      </Box>

      {viewColumnsSelector && (
        <CustomColumnSelector
          open={viewColumnsSelector}
          handleClose={handleColumnSelectorClose}
          tableName="campaign_column"
          actionColumnName="id"
          defaultSelected={selectedColumns}
          columns={getUpdatedColumns(columns, selectedColumns)}
          setColumnOrder={setColumnOrder}
          columnOrder={columnOrder}
        />
      )}

      {modalOpen && <CampaignModal open={modalOpen} onClose={handledClose} />}

      {/* Open confirmation popup */}
      {isConfirmationOpen && (
        <Dialog open={isConfirmationOpen} onClose={handleCloseConfirmation}>
          <DialogTitle>Edit Campaign Details</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Are you sure you want to Edit this Campaign Details?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmation} variant="outlined" color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateAdGroupReport} variant="contained" color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* --------------------------------- edit campaign modal for Product ---------------------------------------------------*/}
      {campaignModal.open && (
        <Dialog
          open={campaignModal.open}
          keepMounted
          onClose={handledEditClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SP Campaign</Typography>
              <IconButton onClick={handledEditClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditCampaignSetting campaignModal={campaignModal} onClose={handledEditClose} />
          </DialogContent>
        </Dialog>
      )}

      {/* ---------------------------------  edit Item modal ---------------------------------------------------*/}
      {itemModal.open && (
        <Dialog
          open={itemModal.open}
          keepMounted
          onClose={handleItemModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add item</Typography>
              <IconButton onClick={handleItemModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <ProductAdGroupSetting itemModal={itemModal} onClose={handleItemModalClose} />
          </DialogContent>
        </Dialog>
      )}

      {/* ---------------------------------  edit single campaing ---------------------------------------------------*/}
      {campaignEditModal.open && (
        <Dialog
          open={campaignEditModal.open}
          keepMounted
          onClose={handleSingleEditsClose}
          maxWidth="lg"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit Campaign</Typography>
              <IconButton onClick={handleSingleEditsClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <CampaignSingleEdit campaignEditModal={campaignEditModal} onClose={handleSingleEditsClose} />
          </DialogContent>
        </Dialog>
      )}

      {/* modal ofor dailbudget  */}
      {isMultipleBidConfirmationOpen && (
        <Dialog fullWidth open={isMultipleBidConfirmationOpen} onClose={!loading && HandleModalCloseBudget}>
          <DialogTitle sx={{ fontWeight: '700', fontSize: '14px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div" sx={{ fontWeight: 700 }}>
              {selectedValue == '1' ? 'Daily' : 'Total'} Budget Setting
            </Typography>
            <Typography sx={{ fontWeight: 700, cursor: 'pointer' }} onClick={!loading && HandleModalCloseBudget}>
              {' '}
              <CloseIcon />
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
            <Box>
              <Typography variant="h5">
                Campaign (Total:
                {selectedValue == '1'
                  ? SelectedBudget && SelectedBudget?.DailyData?.DailyName?.length
                  : SelectedBudget && SelectedBudget.TotalData?.DailyName?.length}
                )
              </Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '80px',
                  width: '100%',
                  my: 2,
                  p: 1,

                  // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                  '&::-webkit-scrollbar': {
                    width: '0.1em',
                    backgroundColor: '#C5C5C5'
                  },
                  '&::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#7A8183',
                    outline: '1px solid slategrey'
                  }
                }}
              >
                {selectedValue == '1'
                  ? SelectedBudget &&
                    SelectedBudget?.DailyData?.DailyName?.map((name, index) => {
                      return <Typography key={index}>{name}</Typography>;
                    })
                  : SelectedBudget &&
                    SelectedBudget.TotalData?.DailyName?.map((name, index) => {
                      return <Typography key={index}>{name}</Typography>;
                    })}
              </Box>
            </Box>
            <FormControl fullWidth>
              <RadioGroup aria-label="BudgetType" name="budgetType" value={BudgetType} onChange={handleBudgetTypeChange}>
                <Box sx={{ display: 'flex', p: 1 }}>
                  <FormControlLabel value="label" control={<Radio />} label="Set to " />
                  <FormControl sx={{ width: 'fit-content', m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Amount"
                      type="number"
                      value={BudgetAmount}
                      onChange={(e) => setBudgetAmount(e.target.value)}
                      disabled={BudgetType !== 'label'}
                      // error={errorForAmount}
                    />

                    {/* <Box sx={{ color: 'red' }}>{errorForAmount && 'Required this field!'}</Box> */}
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex', p: 1, alignItems: 'baseline' }}>
                  <FormControlLabel value="current" control={<Radio />} label="" />
                  <FormControl sx={{ width: 'fit-content', m: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                      <FormControl sx={{ width: '33%' }} variant="outlined">
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={selectedOption}
                          disabled={BudgetType !== 'current'}
                          onChange={handleOptionChange}
                        >
                          <MenuItem value="+">Increase</MenuItem>
                          <MenuItem value="-">Decrease</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl sx={{ width: '33%' }} variant="outlined">
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={BudgetAmountCurrent}
                          disabled={BudgetType !== 'current'}
                          onChange={(e) => setBudgetAmountCurrent(e.target.value)}
                        >
                          <MenuItem value="$">By value</MenuItem>
                          <MenuItem value="%">By Percent</MenuItem>
                        </Select>
                      </FormControl>

                      <OutlinedInput
                        id="outlined-adornment-amount-2"
                        value={BudgetAmountCurrentDollar}
                        onChange={(e) => setBudgetAmountCurrentDollar(e.target.value)}
                        disabled={BudgetType !== 'current'}
                        type="number"
                        startAdornment={<InputAdornment position="start">{BudgetAmountCurrent}</InputAdornment>}
                      />
                    </Box>

                    {BudgetType === 'current' && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <Box>
                          <FormControlLabel
                            control={<Checkbox checked={checkbox1Checked} onChange={handleCheckbox1Change} />}
                            label="No lower than"
                          />
                          <OutlinedInput
                            disabled={!checkbox1Checked}
                            // label="No lower than"
                            autoFocus="false"
                            name="no lower"
                            type="number"
                            value={textField1Value}
                            onChange={(e) => setTextField1Value(e.target.value)}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            control={<Checkbox checked={checkbox2Checked} onChange={handleCheckbox2Change} />}
                            label="No Higher than"
                          />
                          {/* <TextField
                    disabled={!checkbox2Checked}
                    label="No Higher than"
                    value={textField2Value}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    onChange={(e) => setTextField2Value(e.target.value)}
                  /> */}
                          <OutlinedInput
                            disabled={!checkbox2Checked}
                            // label="No Higher than"
                            autoFocus="false"
                            type="number"
                            value={textField2Value}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            onChange={(e) => setTextField2Value(e.target.value)}
                          />
                        </Box>
                      </Box>
                    )}
                  </FormControl>
                </Box>
              </RadioGroup>

              <Box sx={{ display: 'flex', alignItems: 'baseline', flexDirection: 'column', mt: 1 }}>
                {selectedValue == '1' ? (
                  <>
                    {' '}
                    <Typography variant="h5">For 3P, Minimum daily budget is 10;</Typography>
                    <Typography variant="h5"> For 1P, Minimum daily budget is 50.</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h5">For 3P, Minimum total budget is 50;</Typography>
                    {/* <Typography variant="h5">Current Daily Budget: $111</Typography> */}
                  </>
                )}
              </Box>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={HandleModalCloseBudget} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={
                loading ||
                (BudgetType == 'label'
                  ? BudgetAmount <= 0 || BudgetAmount == ''
                  : BudgetAmountCurrentDollar <= 0 || BudgetAmountCurrentDollar == '')
              }
              onClick={handleConfirm}
            >
              {loading && <Loader style={{ marginRight: '8px' }} />} Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* enable Edit  setting  */}

      {isMultipleEndDateConfirmationOpen && (
        <Dialog fullWidth open={isMultipleEndDateConfirmationOpen} onClose={!loading && HandleModalCloseEndDate}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div">End Date Setting</Typography>
            <Typography sx={{ fontWeight: 700, cursor: 'pointer' }} onClick={!loading && HandleModalCloseEndDate}>
              <CloseIcon />
            </Typography>
          </DialogTitle>

          <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <Label>End Date :</Label> */}
            <Typography sx={{ fontSize: '16px', fontWeight: 700, color: 'black', pr: 2 }}>End Date: </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                // {...register("end_date")}
                // error={errors?.end_date}
                value={dayjs(endDateValidator(EditEndDateMultiple) ? null : EditEndDateMultiple)}
                // minDate={startDateValue ? dayjs(startDateValue).add(1, 'day') : dayjs(new Date()).add(1, 'day')}
                onChange={(value) => {
                  const dayjsDate = dayjs(value.$d);
                  const formattedDate = dayjsDate.format('YYYY-MM-DD');
                  setEditEndDateMultiple(formattedDate);
                }}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={HandleModalCloseEndDate} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button disabled={loading} color="primary" variant="contained" onClick={handleConfirmEndDate}>
              {loading && <Loader style={{ marginRight: '8px' }} />} Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* multiple status */}
      {isMultipleStatusConfirmationOpen && (
        <Dialog fullWidth open={isMultipleStatusConfirmationOpen} onClose={!loading && HandleModalCloseStatus}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div">Status Change Confirm</Typography>
            <Typography sx={{ cursor: 'pointer' }} onClick={!loading && HandleModalCloseStatus}>
              <CloseIcon />
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
              <Typography sx={{ fontSize: '16px', color: 'black', pr: 2, mb: 2, display: 'flex' }}>
                Confirm to change the status to{' '}
                <strong>&nbsp;{StatusValueForCondition.charAt(0).toUpperCase() + StatusValueForCondition.slice(1)}</strong>
              </Typography>
            </Box>
            {/* <Label>End Date :</Label> */}

            <Box sx={{ width: '-webkit-fill-available' }}>
              <Typography variant="h5">Selected Campaigns({selectedCapName?.length})</Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '80px',
                  width: '100%',
                  my: 2,
                  p: 1,

                  // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                  '&::-webkit-scrollbar': {
                    width: '0.1em',
                    backgroundColor: '#C5C5C5'
                  },
                  '&::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#7A8183',
                    outline: '1px solid slategrey'
                  }
                }}
              >
                {selectedCapName &&
                  selectedCapName?.map((name, index) => (
                    <Typography key={index}>
                      {index + 1}.{name}
                    </Typography>
                  ))}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={HandleModalCloseStatus} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button disabled={loading} color="primary" variant="contained" onClick={handleChangeDropdownStatus}>
              {loading && <Loader style={{ marginRight: '8px' }} />} Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* multiple Platform  bid  */}

      {isMultiplePlatformBidConfirmationOpen && (
        <Dialog fullWidth open={isMultiplePlatformBidConfirmationOpen} onClose={!loading && HandleModalClosePlatformBid}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div">Platform Bid Multiplier Setting</Typography>
            <Typography sx={{ cursor: 'pointer' }} onClick={!loading && HandleModalClosePlatformBid}>
              <CloseIcon />
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
            {/* <Label>End Date :</Label> */}

            <Box sx={{ width: '-webkit-fill-available' }}>
              <Typography variant="h5">Selected Campaigns({selectedCapName?.length})</Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '80px',
                  width: '100%',
                  my: 2,
                  p: 1,

                  // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                  '&::-webkit-scrollbar': {
                    width: '0.1em',
                    backgroundColor: '#C5C5C5'
                  },
                  '&::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#7A8183',
                    outline: '1px solid slategrey'
                  }
                }}
              >
                {selectedCapName &&
                  selectedCapName?.map((name, index) => (
                    <Typography key={index}>
                      {index + 1}.{name}
                    </Typography>
                  ))}
              </Box>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', marginLeft: 3, fontWeight: 700 }}>Platform Bid Multiplier</Box>
            </Box>
            <Box>
              <form>
                <Box style={{ display: 'flex', flexDirection: 'row', gap: '10px', margin: '29px' }}>
                  <TextField
                    label="Desktop"
                    variant="outlined"
                    type="number"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none'
                      },
                      '& input[type=number]': {
                        MozAppearance: 'textfield'
                      }
                    }}
                    placeholder="0-900"
                    value={desktop}
                    onChange={handleDesktopChange}
                    InputProps={{
                      endAdornment: '%'
                    }}
                  />
                  <TextField
                    label="App"
                    variant="outlined"
                    type="number"
                    placeholder="0-900"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none'
                      },
                      '& input[type=number]': {
                        MozAppearance: 'textfield'
                      }
                    }}
                    value={app}
                    onChange={handleAppChange}
                    InputProps={{
                      endAdornment: '%'
                    }}
                  />
                  <TextField
                    label="Mobile"
                    variant="outlined"
                    type="number"
                    placeholder="0-900"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none'
                      },
                      '& input[type=number]': {
                        MozAppearance: 'textfield'
                      }
                    }}
                    value={mobile}
                    onChange={handleMobileChange}
                    InputProps={{
                      endAdornment: '%'
                    }}
                  />
                </Box>
              </form>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={HandleModalClosePlatformBid} variant="outlined" color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading || mobile == '' || app == '' || desktop == ''}
              onClick={handleSubmit}
            >
              {loading && <Loader style={{ marginRight: '8px' }} />} Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/*  */}
      {/* ---------------------------------  edit Item modal ---------------------------------------------------*/}
      {/* {itemModal.open && (
        <Dialog
        open={itemModal.open}
        keepMounted
        onClose={handleItemModalClose}

        maxWidth='md'
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ borderBottom: 1, borderColor: 'divider'}}  >
          <Box sx={{display: 'flex', justifyContent: 'space-between', 
          alignItems: 'center',}}> 
          <Typography variant= "h5"> Add item</Typography>
          <IconButton onClick={handleItemModalClose}><CloseIcon /></IconButton>
          </Box>
          </DialogTitle>
          <DialogContent>
        <ProductAdGroupSetting itemModal={itemModal} onClose={handledEditClose} />
        </DialogContent>
        </Dialog>
      )}  */}
      {/* ---------------------------------  edit Keyword modal ---------------------------------------------------*/}
      {keywordModal.open && (
        <Dialog
          open={keywordModal.open}
          keepMounted
          onClose={handleKeywordModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add Keyword</Typography>
              <IconButton onClick={handleKeywordModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {/* <EditCampaignSetting campaignModal={itemModal} onClose={handleItemModalClose} /> */}
            <TargetAdGroupSetting keywordModal={keywordModal} onClose={handleKeywordModalClose} addKeywordModel={false} />
          </DialogContent>
        </Dialog>
      )}

      {/* --------------------------------- edit campaign modal for Brands ---------------------------------------------------*/}
      {campaignBrandModal.open && (
        <Dialog
          open={campaignBrandModal.open}
          keepMounted
          onClose={handledEditBrandClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SB Campaign</Typography>
              <IconButton onClick={handledEditBrandClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditCampaignBrandSetting campaignModal={campaignBrandModal} onClose={handledEditBrandClose} />
          </DialogContent>
        </Dialog>
      )}

      {/* --------------------------------- edit campaign modal for Video ---------------------------------------------------*/}
      {campaignVideoModal.open && (
        <Dialog
          open={campaignVideoModal.open}
          keepMounted
          onClose={handleEditVideoClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SV Campaign</Typography>
              <IconButton onClick={handleEditVideoClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers sx={{ width: '900px' }}>
            <EditCampaignVideoSetting campaignModal={campaignVideoModal} onClose={handleEditVideoClose} />
          </DialogContent>
        </Dialog>
      )}

      {/* --------------------------------- edit Review modal ---------------------------------------------------*/}

      {reviewModal.open && (
        <Dialog
          open={reviewModal.open}
          keepMounted
          onClose={handledReviewClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Message</Typography>
              <IconButton onClick={handledReviewClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <>
              <Box sx={{ display: 'flex', padding: 5 }}>
                <TaskAltRoundedIcon color="success" sx={{ mr: 2 }} />
                <Typography variant="h4">Campaign Review created successfully</Typography>
              </Box>
            </>
          </DialogContent>
        </Dialog>
      )}

      {/* --------------------------------- review log modal ---------------------------------------------------*/}

      {reviewLogModal.open && (
        <Dialog
          open={reviewLogModal.open}
          keepMounted
          onClose={handledLogReviewClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="div" sx={{ mr: 1 }}>
                  {' '}
                  Review Comments
                </Typography>
                <Typography> (Only show the latest 30 review records)</Typography>
              </Box>
              <IconButton onClick={handledLogReviewClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <ReviewLogModal reviewLogModal={reviewLogModal} />
          </DialogContent>
        </Dialog>
      )}

      {/* --------------------------------- Placement Bid Edit ---------------------------------------------------*/}

      {placementEditModal.open && (
        <Dialog
          open={placementEditModal.open}
          keepMounted
          onClose={handlePlacementEditClose}
          maxWidth="lg"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="div" sx={{ mr: 1 }}>
                  {' '}
                  Placement Bid Multiplier Edit
                </Typography>
              </Box>
              <IconButton onClick={handlePlacementEditClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditPlacementModal
              placementEditModal={placementEditModal}
              onGetCampaignData={handleSuccessForCampaign}
              onClose={handlePlacementEditClose}
              setInlineLoader={setInlineLoader}
            />
          </DialogContent>
        </Dialog>
      )}

      {/* --------------------------------- Platform Bid Edit ---------------------------------------------------*/}

      {/* plateformEditModal, setPlateformEditModal */}
      {plateformEditModal.open && (
        <Dialog
          open={plateformEditModal.open}
          keepMounted
          onClose={handlePlateformEditClose}
          maxWidth="lg"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="div" sx={{ mr: 1 }}>
                  {' '}
                  Platform Bid Multiplier Edit
                </Typography>
              </Box>
              <IconButton onClick={handlePlateformEditClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditPlateformModal
              plateformEditModal={plateformEditModal}
              onGetCampaignData={onGetCampaignData}
              onClose={handlePlateformEditClose}
              setInlineLoader={setInlineLoader}
            />
          </DialogContent>
        </Dialog>
      )}

      {/* placement  */}

      {isMultiplePlacementBidConfirmationOpen && (
        <Dialog
          // sx={{ maxWidth: '640px !important' }}
          open={isMultiplePlacementBidConfirmationOpen}
          onClose={!loading && HandleModalClosePlacementBid}
          // className="for-width"
          className="for-width"
        >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div">Placement Bid Multiplier Setting</Typography>
            <Typography sx={{ cursor: 'pointer' }} onClick={!loading && HandleModalClosePlacementBid}>
              <CloseIcon />
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
            {/* <Label>End Date :</Label> */}

            <Box sx={{ width: '-webkit-fill-available' }}>
              <Typography variant="h5">Selected Campaigns({SelectedPlacement?.placementName?.length})</Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '60px',
                  width: '100%',
                  my: 2,
                  p: 1,

                  // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                  '&::-webkit-scrollbar': {
                    width: '0.1em',
                    backgroundColor: '#C5C5C5'
                  },
                  '&::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#7A8183',
                    outline: '1px solid slategrey'
                  }
                }}
              >
                {SelectedPlacement &&
                  SelectedPlacement?.placementName?.map((name, index) => (
                    <Typography key={index}>
                      {index + 1}.{name}
                    </Typography>
                  ))}
              </Box>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', marginLeft: 3, fontWeight: 700 }}>Placement:</Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                width: '-webkit-fill-available !important',
                margin: '29px'
              }}
            >
              <Box className="custom-switch-table" sx={{ display: 'flex', alignItems: 'center' }}>
                <PinkSwitch
                  disabled={SelectedPlacement?.TypeLength == 'auto' ? true : true}
                  checked={SIngrid}
                  onChange={() => setSIngrid(!SIngrid)}
                  value="checked"
                />
                <Typography className="white-Space"> Search Ingrid</Typography>
              </Box>
              <Box className="custom-switch-table" sx={{ display: 'flex', alignItems: 'center' }}>
                <PinkSwitch
                  disabled={SelectedPlacement?.TypeLength == 'auto' ? true : false}
                  checked={SBuyBox}
                  onChange={() => setSBuyBox(!SBuyBox)}
                  value="checked"
                />
                <Typography className="white-Space"> Item Buy-Box</Typography>
              </Box>
              {SelectedPlacement?.TypeLength == 'auto' && (
                <>
                  <Box className="custom-switch-table" sx={{ display: 'flex', alignItems: 'center' }}>
                    <PinkSwitch
                      disabled={SelectedPlacement?.TypeLength == 'auto' ? true : false}
                      checked={SHomePage}
                      onChange={() => setSHomePage(!SHomePage)}
                      value="checked"
                    />
                    <Typography className="white-Space"> Home Page</Typography>
                  </Box>

                  <Box className="custom-switch-table" sx={{ display: 'flex', alignItems: 'center' }}>
                    <PinkSwitch
                      disabled={SelectedPlacement?.TypeLength == 'auto' ? true : false}
                      checked={SStock}
                      onChange={() => setSStock(!SStock)}
                      value="checked"
                    />
                    <Typography className="white-Space"> Stock Up</Typography>
                  </Box>
                </>
              )}

              {SelectedPlacement?.TypeLength == 'manual' && (
                <>
                  <Box className="custom-switch-table" sx={{ display: 'flex', alignItems: 'center' }}>
                    <PinkSwitch
                      // disabled={SelectedPlacement?.TypeLength == 'auto' ? true : false}
                      checked={searchCarousel}
                      onChange={() => SetSearchCarousel(!searchCarousel)}
                      value="checked"
                    />
                    <Typography className="white-Space"> Search Carousel</Typography>
                  </Box>

                  <Box className="custom-switch-table" sx={{ display: 'flex', alignItems: 'center' }}>
                    <PinkSwitch
                      // disabled={SelectedPlacement?.TypeLength == 'auto' ? true : false}
                      checked={itemCarousel}
                      onChange={() => SetItemCarousel(!itemCarousel)}
                      value="checked"
                    />
                    <Typography className="white-Space"> Item Carousel</Typography>
                  </Box>
                </>
              )}

              <Box className="custom-switch-table" sx={{ display: 'flex', alignItems: 'center' }}>
                <PinkSwitch
                  disabled={SelectedPlacement?.TypeLength == 'auto' ? true : true}
                  checked={others}
                  onChange={() => setOthers(!others)}
                  value="checked"
                />
                <Typography className="white-Space"> Others</Typography>
              </Box>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', marginLeft: 3, fontWeight: 400, alignItems: 'center' }}>
                Placement Bid Multiplier{' '}
                <span style={{ display: 'flex', marginLeft: '6px', alignItems: 'center' }}>
                  <Tooltip
                    placement="top"
                    title="For example, if a item bid is 0.5 and the Placement bid adjustment is set to 200%, the item bid of the placement will change to 1.5."
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </span>
              </Box>
            </Box>
            <Box>
              <form>
                <Box style={{ display: 'flex', flexDirection: 'row', gap: '10px', margin: '29px' }}>
                  <TextField
                    label="Search Ingrid"
                    variant="outlined"
                    type="number"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none'
                      },
                      '& input[type=number]': {
                        MozAppearance: 'textfield'
                      }
                    }}
                    placeholder="0-900"
                    value={ingrid}
                    disabled={!SIngrid}
                    onChange={(e) => {
                      e.target.value > 900 ? setIngrid(900) : setIngrid(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: '%'
                    }}
                  />
                  {SBuyBox && (
                    <TextField
                      label="Item Buy-Box"
                      variant="outlined"
                      type="number"
                      disabled={!SBuyBox}
                      placeholder="0-900"
                      sx={{
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                          display: 'none'
                        },
                        '& input[type=number]': {
                          MozAppearance: 'textfield'
                        }
                      }}
                      value={buyBox}
                      onChange={(e) => {
                        e.target.value > 900 ? setBuyBox(900) : setBuyBox(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: '%'
                      }}
                    />
                  )}
                  {SelectedPlacement?.TypeLength == 'auto' && (
                    <>
                      <TextField
                        label="Home Page:"
                        variant="outlined"
                        type="number"
                        disabled={!SHomePage}
                        placeholder="0-900"
                        sx={{
                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                            display: 'none'
                          },
                          '& input[type=number]': {
                            MozAppearance: 'textfield'
                          }
                        }}
                        value={homePage}
                        onChange={(e) => {
                          e.target.value > 900 ? setHomePage(900) : setHomePage(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: '%'
                        }}
                      />
                      <TextField
                        label="Stock Up"
                        variant="outlined"
                        type="number"
                        placeholder="0-900"
                        disabled={!SStock}
                        sx={{
                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                            display: 'none'
                          },
                          '& input[type=number]': {
                            MozAppearance: 'textfield'
                          }
                        }}
                        value={stockUp}
                        onChange={(e) => {
                          e.target.value > 900 ? setStockUp(900) : setStockUp(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: '%'
                        }}
                      />
                    </>
                  )}
                </Box>
                <DialogActions>
                  <Button disabled={loading} onClick={HandleModalClosePlacementBid} color="primary" variant="outlined">
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" disabled={loading} onClick={handleSubmitMultiplePlacementBid}>
                    {loading && <Loader style={{ marginRight: '8px' }} />} Confirm
                  </Button>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {/* Add item error and function modal */}
      {ProfileNameModal?.length !== 1 ? (
        <Dialog
          open={ProfileModal}
          keepMounted
          onClose={handleCloseProfileModal}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="div" sx={{ mr: 1 }}>
                  {' '}
                  Message
                </Typography>
              </Box>
              <IconButton onClick={handleCloseProfileModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'unset', justifyContent: '', marginTop: '15px', marginBottom: '5px' }}>
                <Typography sx={{ paddingRight: '15px', border: '' }}>
                  <HighlightOffIcon sx={{ color: 'red' }} />
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: '1.5', fontSize: '18px' }}>
                  Adding items for different profiles at the <br /> same time is not supported. Please <br /> operate separately
                </Typography>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleCloseProfileModal}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={ProfileModal}
          keepMounted
          onClose={handleCloseProfileModal}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add Item</Typography>
              <IconButton onClick={handleCloseProfileModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ overflowY: 'auto !important' }} dividers>
            {ProfileModal && (
              <ProductAdMultipleItems
                itemModal={PassDataItem}
                onClose={handleCloseProfileModal}
                updateBulkCampaignDetails={updateBulkCampaignDetails}
              />
            )}
          </DialogContent>
        </Dialog>
      )}

      {/* code for add keyword modal */}

      {(ProfileNameModalKeyword?.length == 1 &&
        ProfileNameModalKeyword[0] == 'manual' &&
        OpenAddKeywordModalSbSv?.length == 1 &&
        OpenAddKeywordModalSbSv[0] == 'sponsoredProducts manual') ||
      (OpenAddKeywordModalSbSv?.length == 1 && OpenAddKeywordModalSbSv[0] == 'sba manual') ||
      (OpenAddKeywordModalSbSv?.length == 1 && OpenAddKeywordModalSbSv[0] == 'video manual') ? (
        <Dialog
          open={ProfileModalKeyword}
          keepMounted
          onClose={handleCloseProfileKeyword}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add Keyword</Typography>
              <IconButton onClick={handleCloseProfileKeyword}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {ProfileModalKeyword && (
              <TargetAdGroupSettingKeyword
                keywordModal={PassDataKeyword}
                onClose={handleCloseProfileKeyword}
                addKeywordModel={false}
                updateBulkCampaignDetails={updateBulkCampaignDetails}
              />
            )}
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={ProfileModalKeyword}
          keepMounted
          onClose={handleCloseProfileKeyword}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="div" sx={{ mr: 1 }}>
                  {' '}
                  Message
                </Typography>
              </Box>
              <IconButton onClick={handleCloseProfileKeyword}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'unset', justifyContent: '', marginTop: '15px', marginBottom: '5px' }}>
                <Typography sx={{ paddingRight: '15px', border: '' }}>
                  <HighlightOffIcon sx={{ color: 'red' }} />
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: '1.5', fontSize: '18px' }}>
                  Since the starting prices of SP and SB/SV are <br /> different, it is recommended to add keywords <br /> for the
                  different ad types separately.
                </Typography>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginBottom: '10px', marginRight: '10px' }}>
            <Button variant="contained" color="secondary" onClick={handleCloseProfileKeyword}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {successFailModal && (
        <SuccessAndFail
          dialogOpen={successFailModal}
          SuccessFailData={SuccessFailData}
          handleOkButtonClick={() => {
            setSuccessFailModal(false);
            setLoading(false);
          }}
          label="Campaign"
        />
      )}
    </MainCard>
  );
};

export default Campaign;
