import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';

import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TableCell,
  TableFooter,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import DownloadIcon from 'assets/images/dashna/Arrow.svg';
import downIcon from 'assets/images/dashna/down.svg';
import upIcon from 'assets/images/dashna/up.svg';
import { CAMPAIGN } from 'constant/campaignConstant';
import {
  alignRightColumn,
  formateValue,
  getUpdatedColumns,
  commaStringToNumber,
  formatNumberWithTwoDecimal
} from 'helper/commonHelper';
import { debounce } from 'lodash';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { NestedDropdown } from 'mui-nested-menu';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'rsuite';
import { useAppDispatch, useAppSelector } from 'store';
import { KeywordState, adGroup_state, attributeWindowList, campaigState, pageValueKeyword } from 'store/constant';
import { setCommonLoader, setKeywordsDetails, setSkeletonLoader, setAdgroupList } from 'store/slices/userDetailsSlice';
import {
  FilterMultipleStatusEnableAndPauseKeyword,
  getAdGroupList,
  getCampaignNameList,
  getKeywordsDetails,
  getSelectedFilterList,
  updateKeywordStatus,
  updateMultipleBidKeyword,
  updateMultipleBidKeywordMultiple
} from 'store/thunk/userThunk';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CustomColumnSelector from 'ui-component/CustomColumnSelector';
import CustomFilters from 'ui-component/Filters';
import SuccessAndFail from 'ui-component/SuccessAndFailModal';
import MainCard from 'ui-component/cards/MainCard';
import CompareValueWithColor from 'ui-component/compareWithValue';
import BasicPopover from 'ui-component/popover';
import BidPopover from 'ui-component/popover/BidPopover';
import TableSkeleton from 'ui-component/skeleton/TableSkeleton';
import __ from 'underscore';
import EditCampaignBrandSetting from 'views/campaign/editBrand/editCampaignSetting';
import ProductAdGroupSetting from 'views/campaign/editProduct/editAdgroupItems';
import EditCampaignSetting from 'views/campaign/editProduct/editCampaignSetting';
import TargetAdGroupSetting from 'views/campaign/editProduct/editKeywordItems';
import EditCampaignVideoSetting from 'views/campaign/editVideo';
import FilterPerformance from 'views/filterPerformance';
import CustomAutocomplete from '../../ui-component/multi-select/CustomAutocomplete';
import actionIcon from 'assets/images/dashna/Actions.svg';
import editIcon from 'assets/images/dashna/edit.svg';
import viewcolumnsIcon from 'assets/images/dashna/viewcolumns.svg';
import Archieved from '../../assets/images/dashna/completed.svg';
import EnabledIcon from '../../assets/images/dashna/enabled.svg';
import PausedIcon from '../../assets/images/dashna/paused.svg';
import plusSimpleIcon from 'assets/images/dashna/plusSimple.svg';
//====== Colors ======
import colors from 'assets/scss/_themes-vars.module.scss';
import MultiSelectIcon from 'assets/images/dashna/multiselect.svg';
import arrowDownIcon from 'assets/images/dashna/ArrowDown.svg';
import { useMemo } from 'react';

const Keywords = () => {
  const dispatch = useAppDispatch();
  const [rowPerPage, setRowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [successFailModal, setSuccessFailModal] = useState(false);
  const [SuccessFailData, setSuccessFailData] = useState([]);
  const [page, setPage] = useState(0);
  const [groupBy, setGroupBy] = useState({ id: 2, name: 'Ad Group', value: 'adgroup' });
  const [groupByForItem, setGroupByForItem] = useState('adgroup');
  const [hoveredRow, setHoveredRow] = useState(null);
  const [edit, setEdit] = useState({
    campaign: null,
    total_budget: null,
    daily_budget: null,
    status: null,
    end_date: null
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [SuggestedBid, setSuggestedBid] = useState();
  const [tableFilter, setTableFilter] = useState({
    campaign__in: '',
    adgroup__in: '',
    campaign: '',
    match_type: '',
    status: '',
    attr_window: '',
    campaign_type: '',
    group_by: '',
    adGroup_state: '',
    keyword_status: '',
    ad_group: '',
    attr_windows: ''
  });
  const [filterForSearch, setFilterForSearch] = useState({});
  const [filterValue, setFilterValue] = useState({
    campaign__in: [],
    campaign: [],
    status: [],
    attr_window: [],
    campaign_type: [],
    keyword_status: [],
    match_type: [],
    group_by: {},
    adGroup_state: [],
    ad_group: [],
    attr_windows: []
  });
  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    campaign_search: [],
    campaign_type: [],
    status: [],
    keyword_status: [],
    ad_group_search: [],
    match_type: [],
    ad_group_status: []
  });
  const [loading, setLoading] = useState(false);

  const [searchCampaign, setSearchCampaign] = useState('');
  const [performanceRanges, setPerformanceRanges] = useState({}); //When change on the performance input
  const [PerformanceFilterData, setPerformanceFilterData] = useState(); //When click on Search button
  const [attribute, setAttribute] = useState('14');
  const [error, setError] = useState({});

  const {
    keywordsDetails,
    keywordsDetailsTotal,
    commonAdvertiser,
    campaignNameList,
    commonDateRange,
    filteredData,
    adGroupShortList,
    commonLoader,
    isComparingDates,
    skeletonLoader
  } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);
  const selectedProfiles = localStorage.getItem('selectedProfiles');

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const previousDateRange = useRef(commonDateRange);
  const isFirstRender = useRef(true);
  const shouldApplyFilter = useRef(true);

  const params = useParams();
  // const Type = params?.type;
  const [inlineLoader, setInlineLoader] = useState(null);
  const [modalopen, setModalOpen] = useState(false);
  const [keywordModal1, setKeywordModal1] = useState({ open: false, id: null, name: '' });
  const [filterDataList, setFilterDataList] = useState({});
  const IsNoSelected = ['campaign_name', 'adgroup_name', 'campaign_type'];
  const [newData, setNewData] = useState();
  const [newDataTotal, setNewDataTotal] = useState();
  const [ableToEdit, setAbleToEdit] = useState(false);
  const [ableToEditC, setAbleToEditC] = useState(false);
  const [isMultipleStatusConfirmationOpen, setIsMultipleStatusConfirmationOpen] = useState(false);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isMultipleBidConfirmationOpen, setIsMultipleBidConfirmationOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenAdgroup, setIsDropdownOpenAdgroup] = useState(false);
  const [isDropdownOpenCamAdSt, setIsDropdownOpenCamAdSt] = useState(false);
  const [isDropdownOpenCamType, setIsDropdownOpenCamType] = useState(false);
  const [isDropdownOpenCamMatchType, setIsDropdownOpenCamMatchType] = useState(false);
  const [isDropdownOpenCamSt, setIsDropdownOpenCamSt] = useState(false);
  const [isDropdownOpenCamKeywordSt, setIsDropdownOpenCamKeywordSt] = useState(false);
  const [isCsvDownloadComplete, setIsCsvDownloadComplete] = useState(false);
  const [searchText, setSearchText] = useState({
    campaign_search: null,
    campaign_type: null,
    status: null,
    keyword_status: null,
    match_type: null,
    ad_group_status: null,
    ad_group_search: null
  });
  const [viewColumnsSelector, setViewColumnsSelector] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableHeight, setTableHeight] = useState('550px');
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  //Permission checking
  const keywords = userData?.permission?.keywords || {};
  const keywordsCreate = keywords?.create;
  const keywordsUpdate = keywords?.update;
  const keywordsDownload = keywords?.download;

  const ITEM_HEIGHT = 50;

  //for resize table based on screen size
  useEffect(() => {
    const handleResize = () => {
      const calculatedHeight = window.innerHeight * 0.8;
      setTableHeight(`${calculatedHeight}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setInitialPageFilters = () => {
    setFilterValue({});
    setFilterForSearch({});
    setTableFilter({});
    setPerformanceFilterData({});
    setPerformanceRanges({});
    setSearchText({}); //Reset Type Text in Autocomplete Dropdown Filter
    setSearchCampaign(''); //Reset Search Item Id
  };

  //Reset filters when we change Profile
  useMemo(() => {
    if (!shouldApplyFilter?.current) {
      setInitialPageFilters();
    }
  }, [shouldApplyFilter?.current]);

  const handleCloseDeleteConfirmation = () => {
    setHoveredRow(null);
    setInlineLoader({ Status: null });
    setStatusLoader('');
    setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteReport = () => {
    if (savedDataForEdit) {
      setInlineLoader({ Status: savedDataForEdit?.ids });
      setLoading(true);
      dispatch(updateKeywordStatus(savedDataForEdit)).then((res) => {
        if (res.payload) {
          setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
          setHoveredRow(null);
          setLoading(false);
          setDeleteConfirmationOpen(false);
          setSuccessFailData(res?.payload?.data?.data);
          setInlineLoader({ Status: null });
          setStatusLoader('');
        } else {
          setDeleteConfirmationOpen(false);
          setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
          setHoveredRow(null);
          setLoading(false);
          setInlineLoader({ Status: null });
          setStatusLoader('');
        }
      });
      setDeleteConfirmationOpen(false);
    }
  };

  useEffect(() => {
    if (groupBy?.id == 2) {
      setAbleToEdit(true);
      setAbleToEditC(true);
    } else if (groupBy?.id == 1) {
      setAbleToEdit(false);
      setAbleToEditC(true);
    } else {
      setAbleToEditC(false);
      setAbleToEdit(false);
    }

    if (groupBy?.id == 1 || groupBy?.id == 3 || groupBy?.id == 4) {
      setSuggestedBid(true);
    } else {
      setSuggestedBid();
    }
  }, [groupBy]);

  useEffect(() => {
    if (adGroupShortList && params?.adgroupId) {
      let TempFilterValue = { ...filterValue };
      const findAdgroup = adGroupShortList?.find((adgroup) => adgroup?.id == params?.adgroupId) || {};
      TempFilterValue = { ...TempFilterValue, ad_group: [findAdgroup] };
      setFilterValue(TempFilterValue);
    }
  }, [adGroupShortList]);

  const campaign_type = [
    { id: 1, name: CAMPAIGN.CAMPAIGN_TYPE.SB, value: 'sba' },
    { id: 2, name: CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO, value: 'sponsoredProducts', type: CAMPAIGN.TARGETING_TYPE.AUTO },
    { id: 3, name: CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL, value: 'sponsoredProducts', type: CAMPAIGN.TARGETING_TYPE.MANUAL },
    { id: 4, name: CAMPAIGN.CAMPAIGN_TYPE.SV, value: 'video' }
  ];

  const match_type = [
    { id: 1, name: 'Broad', value: 'broad' },
    { id: 2, name: 'Exact', value: 'exact' },
    { id: 3, name: 'Phrase', value: 'phrase' }
  ];

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });
    return formattedData;
  };

  /* ================================ Autocomplete Filters Start ================================*/

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'campaign_search' || filterKey === 'ad_group_search') {
      paramKey = 'id';
    } else if (
      filterKey === 'campaign_type' ||
      filterKey === 'status' ||
      filterKey === 'keyword_status' ||
      filterKey === 'match_type' ||
      filterKey === 'ad_group_status'
    ) {
      paramKey = 'value';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'campaign_type') {
      baseArray = [...campaign_type];
      tableStateFilterKey = 'type';
    } else if (filterKey === 'status') {
      baseArray = [...campaigState];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'keyword_status') {
      baseArray = [...KeywordState];
      tableStateFilterKey = 'keyword_status';
    } else if (filterKey === 'ad_group_search') {
      baseArray = [...adGroupShortList];
      tableStateFilterKey = 'ad_group';
    } else if (filterKey === 'match_type') {
      baseArray = [...match_type];
      tableStateFilterKey = 'match_type';
    } else if (filterKey === 'ad_group_status') {
      baseArray = [...adGroup_state];
      tableStateFilterKey = 'adGroup_state';
    }

    let targetString = '';
    if (filterKey === 'campaign_type') {
      const targetArray = newValue?.map((item) => item?.type);
      targetString = targetArray.filter((item) => item !== undefined).join(',');
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: '' }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: targetString }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'campaign_type') {
      baseArray = [...campaign_type];
      tableStateFilterKey = 'type';
    } else if (filterKey === 'status') {
      baseArray = [...campaigState];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'keyword_status') {
      baseArray = [...KeywordState];
      tableStateFilterKey = 'keyword_status';
    } else if (filterKey === 'ad_group_search') {
      baseArray = [...adGroupShortList];
      tableStateFilterKey = 'ad_group';
    } else if (filterKey === 'match_type') {
      baseArray = [...match_type];
      tableStateFilterKey = 'match_type';
    } else if (filterKey === 'ad_group_status') {
      baseArray = [...adGroup_state];
      tableStateFilterKey = 'adGroup_state';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  useEffect(() => {
    const finalData = keywordsDetails?.results?.map((val) => {
      return {
        ...formateData(Object.entries(val)),
        details: {
          images: val?.images,
          item_id: val?.item_id,
          product_name: val?.product_name
        }
      };
    });
    if (keywordsDetailsTotal) {
      const TotalRowData = formateData(Object.entries(keywordsDetailsTotal));

      setNewDataTotal(TotalRowData);
    }
    if (keywordsDetails?.results?.length == 0) {
      dispatch(setSkeletonLoader(false));
    }

    setNewData(finalData);

    let tempColumns = JSON.parse(JSON.stringify(columns));
    if (filteredData[0]?.keyword_column) {
      filteredData[0]?.keyword_column?.map((col) => {
        let columnsIndex = -1;
        if (col?.name === 'created_at' || col?.name === 'date') {
          columnsIndex = tempColumns?.findIndex((column) => column?.name === 'created_at' || column?.name === 'date');
        } else {
          columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
        }

        if (columnsIndex !== -1) {
          tempColumns[columnsIndex].options.display = col?.display;
        }
      });
    }
    setSelectedColumns(tempColumns);
  }, [keywordsDetails]);

  useEffect(() => {
    if (newData?.length > 0) {
      dispatch(setSkeletonLoader(false));
    }
  }, [newData]);

  //Remove Skeleton loader when csv downloaded
  useEffect(() => {
    if (isCsvDownloadComplete) {
      setLoadingDownload(false);
      setIsCsvDownloadComplete(false);
      dispatch(setSkeletonLoader(false));
    }
  }, [isCsvDownloadComplete]);

  useEffect(() => {
    setTableLoading(commonLoader);
  }, [commonLoader]);

  useEffect(() => {
    let TemptableFilter = { ...tableFilter };
    let TempFilterValue = { ...filterValue };
    if (campaignNameList?.length > 0 && params?.id) {
      const find = campaignNameList?.findIndex((item) => item?.id == params?.id);

      if (find === -1) {
        TempFilterValue.campaign__in = [];
        TempFilterValue.ad_group = [];
      } else {
        TempFilterValue.campaign__in = [campaignNameList[find]];

        if (params?.type === 'Adgroup' && params?.adgroupId) {
          const findAdgroup = adGroupShortList?.find((adgroup) => adgroup?.id == params?.adgroupId) || {};
          TemptableFilter.adgroup__in = params?.adgroupId;
          TempFilterValue.ad_group = [findAdgroup];
        }
        if (params?.type === 'Campaign' && params?.id) {
          TemptableFilter.campaign__in = campaignNameList[find].id;
        }
      }
    } else {
      TempFilterValue.campaign__in = [];
      TempFilterValue.ad_group = [];
    }

    setTableFilter(TemptableFilter);
    setFilterValue(TempFilterValue);
  }, [campaignNameList]);

  useEffect(() => {
    dispatch(getSelectedFilterList());

    window.scrollTo(0, 0);
    SetShowDropDown(false);
    applyTableFilter();
  }, []);

  useEffect(() => {
    if (filteredData && filteredData?.length > 0) {
      if (filteredData[0]?.keyword_column) {
        let tempColumns = JSON.parse(JSON.stringify(columns));

        filteredData[0]?.keyword_column?.map((col) => {
          let columnsIndex = -1;
          if (col?.name === 'created_at' || col?.name === 'date') {
            columnsIndex = tempColumns?.findIndex((column) => column?.name === 'created_at' || column?.name === 'date');
          } else {
            columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
          }

          if (columnsIndex !== -1) {
            tempColumns[columnsIndex].options.display = col?.display;
          }
        });
        setSelectedColumns(tempColumns);
      }

      setFilterDataList(filteredData[0]?.keyword_filter);
      let TemptableFilter = { ...tableFilter };
      let TempFilterValue = { ...filterValue };

      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'state') === -1) {
        TemptableFilter.status = '';
        TempFilterValue.status = [];
      }

      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'keyword_status') === -1) {
        TemptableFilter.keyword_status = '';
        TempFilterValue.keyword_status = [];
      }
      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'campaign') === -1) {
        TemptableFilter.campaign__in = '';
        TempFilterValue.campaign__in = [];
      }
      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'adgroup') === -1) {
        TemptableFilter.ad_group = '';
        TempFilterValue.ad_group = [];
      }
      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'campaign_type') === -1) {
        TemptableFilter.campaign_type = '';
        TemptableFilter.targeting = '';
        TempFilterValue.campaign_typ = [];
      }
      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'match_type') === -1) {
        TemptableFilter.match_type = '';
        TempFilterValue.match_type = [];
      }
      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'search') === -1) {
        TemptableFilter.campaign = '';
        TempFilterValue.campaign = [];
      }

      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'group_by') === -1) {
        setGroupBy({ id: 2, name: 'Ad Group', value: 'adgroup' });
      }

      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'adgroup_state') === -1) {
        TemptableFilter.adGroup_state = '';
        TempFilterValue.adGroup_state = [];
      }

      if (filteredData[0]?.keyword_filter?.Common?.findIndex((item) => item?.value == 'attr_window') === -1) {
        TemptableFilter.attr_windows = '';
        TempFilterValue.attr_windows = [];
      }
      setTableFilter(TemptableFilter);
      setFilterValue(TempFilterValue);
      if (Object.keys(performanceRanges).length > 0) {
        Object.keys(performanceRanges).map((appliedFilter) => {
          let tempPerformanceRanges = { ...performanceRanges };
          if (filteredData[0]?.campaign_filter?.Performance.findIndex((obj) => obj.value === appliedFilter) === -1) {
            delete tempPerformanceRanges[appliedFilter];
          }
          setPerformanceRanges(tempPerformanceRanges);
        });
      }
    }
  }, [filteredData]);

  const [showSelectAdviser, setShowAdviser] = useState(false);

  const getKeywordsList = (newpage, pageRow, isDownload) => {
    if (commonAdvertiser.length > 0) {
      setShowAdviser(true);
      let tempTableFilter = {};
      if (params?.id) {
        shouldApplyFilter.current = true;

        if (params?.type === 'Adgroup' && params?.adgroupId) {
          tempTableFilter = {
            ...tableFilter,
            campaign__in: params?.id,
            adgroup__in: params?.adgroupId
          };
        } else {
          tempTableFilter = {
            ...tableFilter,
            campaign__in: params?.id
          };
        }
        setTableFilter(tempTableFilter);
        setFilterForSearch(tempTableFilter);
      }
      getAdvertiserKeywordsListWithPagination(
        newpage !== 'undefined' || newpage !== null ? newpage : page,
        pageRow || rowPerPage,
        search,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        shouldApplyFilter?.current ? PerformanceFilterData : {},
        shouldApplyFilter?.current && params?.id ? tempTableFilter : shouldApplyFilter?.current ? filterForSearch : {},
        isDownload
      );
    } else {
      setShowAdviser(false);
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      //Fetch Advertiser Profiles
      if (commonAdvertiser?.length) {
        let id = commonAdvertiser;
        dispatch(getCampaignNameList({ id }));
        dispatch(getAdGroupList({ id }));
      }

      setInitialPageFilters();
      shouldApplyFilter.current = false;
      getKeywordsList(0, rowPerPage);
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  useEffect(() => {
    shouldApplyFilter.current = true;
    if (JSON.stringify(previousDateRange.current) !== JSON.stringify(commonDateRange)) {
      getKeywordsList(0, rowPerPage);
      previousDateRange.current = commonDateRange;
    }
  }, [commonDateRange]);

  // for stop the skeleton loader when no advertiser id is selected
  useEffect(() => {
    if (!selectedProfiles) {
      dispatch(setSkeletonLoader(false)); // Stop loader if no profile selected
    } else {
      setNewData([]);
      dispatch(setSkeletonLoader(true));
    }
  }, [selectedProfiles, dispatch]);

  const applyTableFilter = () => {
    let tempTableFilter = { ...tableFilter };
    if (params?.id) {
      tempTableFilter = {
        ...tempTableFilter,
        campaign__in: params?.id
      };
    }

    setTableFilter(tempTableFilter);
  };

  //Replace value while inline update
  const updateKeywordDetails = (details, ids, updates) => {
    return {
      ...details,
      results: details.results.map((campaign) =>
        ids.includes(campaign.id)
          ? {
              ...campaign,
              ...updates.reduce((acc, update) => ({ ...acc, ...update }), {})
            }
          : campaign
      )
    };
  };

  const resetFailedKeywordAttribute = (updatedCampaignDetails, campaignDetails, errorMessages, key) => {
    const failedIds = errorMessages.map((error) => error.campaign_id);
    const updatedResults = updatedCampaignDetails.results.map((campaign) => {
      if (failedIds.includes(campaign.id)) {
        const originalCampaign = campaignDetails.results.find((orig) => orig.id === campaign.id);
        return {
          ...campaign,
          [key]: originalCampaign ? originalCampaign[key] : campaign[key]
        };
      }
      return campaign;
    });

    return {
      ...updatedCampaignDetails,
      results: updatedResults
    };
  };

  const getAdvertiserKeywordsListWithPagination = (
    page,
    rowPerPage,
    search = '',
    changedColumn = appliedSortTing?.changedColumnSort,
    direction = appliedSortTing?.directionSort,
    commonAdvertiserList,
    PerformanceData,
    tableFilter = {
      campaign__in: '',
      adgroup__in: '',
      campaign: '',
      status: '',
      keyword_status: '',
      attr_window: '',
      campaign_type: '',
      group_by: '',
      adGroup_state: '',
      ad_group: '',
      attr_windows: ''
    },
    is_csv = false
  ) => {
    !is_csv && setTableLoading(true);
    const commonDate = commonDateRange;
    if ((commonDate?.startDate && commonDate?.endDate) || params?.id) {
      if (changedColumn === 'details') {
        changedColumn = 'item_id';
      }

      dispatch(
        getKeywordsDetails({
          page,
          rowPerPage,
          search,
          changedColumn,
          direction,
          commonAdvertiserList,
          PerformanceData,
          tableFilter,
          groupBy,
          commonDate,
          is_csv,
          isComparingDates,
          setIsCsvDownloadComplete,
          setLoadingDownload
        })
      ).then(() => {
        setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
        setTableLoading(false);
      });
    } else {
      dispatch(setSkeletonLoader(false));
    }
  };

  const [savedDataForEdit, setSaveDataForEdit] = useState();
  const [statusLoader, setStatusLoader] = useState('');

  const handleSaveButton = (v, id, type) => {
    setStatusLoader(type);
    const payload = {
      ids: id,
      newPayload: {
        state: v
      },
      defaultData: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: commonAdvertiser,
        PerformanceData: PerformanceFilterData || '',
        tableFilter: filterForSearch,
        groupBy,
        commonDate: commonDateRange,
        isComparingDates
      },
      oldData: keywordsDetails
    };
    setSaveDataForEdit(payload);
    setDeleteConfirmationOpen(true);
  };

  const handleSaveButtonBid = (v, id) => {
    const payload = {
      ids: id,
      newPayload: {
        bid_amount: v
      },
      defaultData: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: commonAdvertiser,
        PerformanceData: PerformanceFilterData || '',
        tableFilter: filterForSearch,
        groupBy,
        commonDate: commonDateRange,
        isComparingDates
      },
      oldData: keywordsDetails
    };
    setSaveDataForEdit(payload);
    setDeleteConfirmationOpen(true);
  };

  const handleColumnSelectorOpen = () => {
    setViewColumnsSelector(true);
  };

  const handleColumnSelectorClose = () => {
    setViewColumnsSelector(false);
  };

  const handleCustomTableHead = ({
    columnMeta,
    handleToggleColumn,
    sortOrder,
    columnName,
    columnLabel_1,
    columnLabel_2 = '',
    customCss = {},
    isLabelAlignRight = false,
    isParentLabelAlignRight = false
  }) => {
    return (
      <TableCell
        sx={{
          position: 'sticky',
          top: '0px',
          zIndex: 1,
          cursor: columnMeta?.sort ? 'pointer' : 'text',
          ...customCss
        }}
        onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
        key={columnMeta?.index}
      >
        <Box display="flex" alignItems="center" justifyContent={isParentLabelAlignRight ? 'flex-end' : 'flex-start'} gap={'5px'}>
          <Box
            display="flex"
            alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Tooltip title={columnMeta?.sort ? 'Sort' : ''}>
              <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
              {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
            </Tooltip>
          </Box>
          {!__.isEmpty(sortOrder) && (
            <Box>
              {sortOrder?.direction === 'asc' && sortOrder?.name === columnName ? <img src={upIcon} alt="icon" /> : null}
              {sortOrder?.direction === 'desc' && sortOrder?.name === columnName ? <img src={downIcon} alt="icon" /> : null}
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  let columns = [
    {
      name: 'keywordtext',
      label: 'Keyword',
      default: true,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        sticky: true,
        leftTotal: 40,
        FontSize: '22px',
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          const customCss = { zIndex: 101, left: '40px', minWidth: '380px' };
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'keywordtext',
            columnLabel_1: 'Keyword',
            customCss
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results ? keywordsDetails?.results[rowData?.rowIndex] : {};
          return (
            <Link
              underline="none"
              sx={{ cursor: 'pointer' }}
              onClick={() => window.open(`${data?.keyword_walmart_url}`, '_blank')}
            >
              <Typography>
                {value?.length > 40 ? (
                  <Tooltip
                    placement="top"
                    sx={{ fontSize: '10px' }}
                    title={
                      <Typography variant="body1" sx={{ fontSize: 16 }}>
                        {value}
                      </Typography>
                    }
                    arrow
                  >
                    {value.substring(0, 40)}...
                  </Tooltip>
                ) : (
                  value
                )}
              </Typography>
            </Link>
          );
        },
        ...(showSelectAdviser && {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 40,
              minWidth: '380px',
              background: 'white',
              zIndex: 100
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 40,
              minWidth: '380px',
              background: 'white',
              zIndex: 101
            }
          })
        })
      }
    },
    {
      name: 'state',
      label: 'Status ',
      default: true,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        sticky: true,
        leftTotal: 380,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          const customCss = { zIndex: 101, left: '380px', width: '90px' };
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'state',
            columnLabel_1: 'Status',
            customCss
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results ? keywordsDetails?.results[rowData?.rowIndex] : [];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {statusLoader == 'status' && inlineLoader?.Status && inlineLoader?.Status === data?.id ? (
                <Loader />
              ) : (
                <Select
                  size="small"
                  value={value}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none !important'
                    },
                    '.MuiSelect-select': {
                      paddingLeft: '0 !important' // Remove left padding
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none !important'
                    }
                  }}
                  onChange={(e) => handleSaveButton(e.target.value, data?.id, 'status')}
                  disabled={!keywordsUpdate}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={
                          selected === 'enabled' ? EnabledIcon : selected === 'paused' ? PausedIcon : Archieved // Assuming Archived uses the Archieved
                        }
                        alt={selected}
                        width={22}
                        height={22}
                        style={{ marginRight: '8px' }}
                      />
                    </Box>
                  )}
                >
                  <MenuItem value="enabled">
                    <img src={EnabledIcon} alt="EnabledIcon" width={22} height={22} style={{ marginRight: '8px' }} />
                    Enabled
                  </MenuItem>
                  <MenuItem value="paused">
                    {' '}
                    <img src={PausedIcon} alt="PausedIcon" width={22} height={22} style={{ marginRight: '8px' }} />
                    Paused
                  </MenuItem>
                  <MenuItem value="archived">
                    <img src={Archieved} alt="Scheduled" width={22} height={22} style={{ marginRight: '8px' }} /> Archived
                  </MenuItem>
                </Select>
              )}
            </Box>
          );
        },
        ...(showSelectAdviser && {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              minWidth: '90px',
              left: 380,
              background: 'white',
              zIndex: 100
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              minWidth: '90px',
              left: 380,
              background: 'white',
              zIndex: 101
            }
          })
        })
      }
    },
    {
      name: 'details',
      label: 'Item',
      default: groupByForItem && groupByForItem == 'aditem' ? false : true,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        display: groupByForItem && groupByForItem == 'aditem' ? true : false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'details',
            columnLabel_1: 'Item'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {value?.images ? (
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={value?.images} />
              ) : (
                <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
              )}
              <Box>
                <Typography>
                  {value?.product_name?.length > 40 ? (
                    <Tooltip
                      placement="top"
                      sx={{ fontSize: '10px' }}
                      title={
                        <Typography variant="body1" sx={{ fontSize: 16 }}>
                          {value?.product_name}
                        </Typography>
                      }
                      arrow
                    >
                      {value?.product_name.substring(0, 40)}...
                    </Tooltip>
                  ) : (
                    value?.product_name
                  )}
                </Typography>

                {value?.item_id ? (
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${value?.item_id}`, '_blank')}
                  >
                    <Typography>{value?.item_id}</Typography>
                  </Link>
                ) : (
                  <>------</>
                )}
              </Box>
            </Box>
          );
        }
      }
    },
    {
      name: 'profile_name',
      label: 'Profile',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'profile_name',
            columnLabel_1: 'Profile Name'
          });
        },
        customBodyRender: (value) => {
          return <Typography className="clr3">{value}</Typography>;
        }
      }
    },

    {
      name: 'campaign_name',
      label: 'Campaign Name',
      options: {
        filter: true,
        sort: true,
        display: ableToEditC ? true : false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'campaign_name',
            columnLabel_1: 'Campaign Name'
          });
        },
        customBodyRender: (value, tableMeta) => {
          const data = keywordsDetails?.results ? keywordsDetails?.results[tableMeta.rowIndex] : [];
          return (
            <Typography
              {...(keywordsUpdate && {
                onMouseEnter: () => setHoveredRow({ campaignId: data?.id }),
                onMouseLeave: () => setHoveredRow(null)
              })}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between' }}
            >
              <Box className="link-dashna-ui">
                {value?.length > 30 ? (
                  <Tooltip
                    placement="top"
                    sx={{ fontSize: '10px' }}
                    title={<Typography sx={{ fontSize: 16 }}>{value}</Typography>}
                    arrow
                  >
                    <Link
                      href={`/campaign/${commaStringToNumber(data?.campaign_id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={'ffInter'}
                      underline="none"
                      sx={{ cursor: 'pointer' }}
                    >
                      {value.substring(0, 30)}...
                    </Link>
                  </Tooltip>
                ) : (
                  <Link
                    href={`/campaign/${commaStringToNumber(data?.campaign_id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={'ffInter'}
                    underline="none"
                    sx={{ cursor: 'pointer' }}
                  >
                    {value}
                  </Link>
                )}
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    handleClose();
                    if (targetingType == 'sba') {
                      setCampaignBrandModal({ ...campaignBrandModal, open: true, id: data?.campaign });
                    }
                    if (targetingType == 'video') {
                      setCampaignVideoModal({ ...campaignVideoModal, open: true, id: data?.campaign });
                    } else {
                      setCampaignModal({ ...campaignModal, open: true, id: data?.campaign });
                    }
                  }}
                >
                  <Tooltip sx={{ fontSize: '10px' }} title={'Edit'} arrow>
                    {' '}
                    <img src={editIcon} alt="Icon" />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={(e) => handleClick(e, data?.campaign, data)}>
                  <Tooltip sx={{ fontSize: '10px' }} title={'Action'} arrow>
                    {' '}
                    <img src={actionIcon} alt="icon" />
                  </Tooltip>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                      borderColor: colors?.grey100,
                      borderRadius: '6px'
                    }
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setItemModal({ ...itemModal, open: true });
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                      <img src={plusSimpleIcon} alt="Icon" />
                      <Typography
                        sx={{
                          color: colors?.grey900,
                          '&:hover': {
                            color: colors?.darkTextHover,
                            fontWeight: 700
                          }
                        }}
                      >
                        Add Items
                      </Typography>
                    </Box>
                  </MenuItem>
                  {isManual && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setKeywordModal({ ...keywordModal, open: true });
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                        <img src={plusSimpleIcon} alt="Icon" />
                        <Typography
                          sx={{
                            color: colors?.grey900,
                            '&:hover': {
                              color: colors?.darkTextHover,
                              fontWeight: 700
                            }
                          }}
                        >
                          Add Keyword
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                  {targetingType === 'sponsoredProducts' && (
                    <MenuItem onClick={handleAddAdGroup}>
                      <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                        <img src={plusSimpleIcon} alt="Icon" />
                        <Typography
                          sx={{
                            color: colors?.grey900,
                            '&:hover': {
                              color: colors?.darkTextHover,
                              fontWeight: 700
                            }
                          }}
                        >
                          Add Adgroup
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Typography>
          );
        }
      }
    },
    {
      name: 'adgroup_name',
      label: 'Ad Group Name',
      options: {
        filter: true,
        sort: true,
        width: 550,
        display: ableToEdit ? true : false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'adgroup_name',
            columnLabel_1: 'Ad Group Name'
          });
        }
      }
    },
    {
      name: 'campaign_type',
      label: 'Campaign Type',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'campaign_type',
            columnLabel_1: 'Campaign',
            columnLabel_2: 'Type'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results ? keywordsDetails?.results[rowData?.rowIndex] : [];
          let type = '';
          if (value == 'sponsoredProducts') {
            type =
              data?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                ? CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL
                : CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO;
          } else if (value == 'sba') {
            type = CAMPAIGN.CAMPAIGN_TYPE.SB;
          } else if (value == 'video') {
            type = CAMPAIGN.CAMPAIGN_TYPE.SV;
          }
          return <Chip sx={{ height: '20px' }} className={'ffInter fs12'} label={type} color="primary" variant="outlined" />;
        }
      }
    },
    {
      name: groupByForItem == 'aditem' ? 'date' : 'created_at',
      label: 'Add Date',
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'date',
            columnLabel_1: 'Add Date'
          });
        },
        customBodyRender: (value) => {
          return <>{moment(value).format('YY-MM-DD')}</>;
        }
      }
    },
    {
      name: 'matchtype',
      label: 'Match Type',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'matchtype',
            columnLabel_1: 'Match Type'
          });
        },
        customBodyRender: (value) => {
          return <>{value.charAt(0).toUpperCase() + value.slice(1)}</>;
        }
      }
    },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true,
        display: SuggestedBid ? false : true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'suggested_bid',
            columnLabel_1: 'Suggested Bid'
          });
        },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },
    {
      name: 'bid_amount',
      label: 'Bid',
      options: {
        filter: true,
        sort: true,
        display: groupByForItem == 'keyword' ? false : true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'bid_amount',
            columnLabel_1: 'Bid',
            isParentLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          value = formatNumberWithTwoDecimal(Number(value));
          const data = keywordsDetails?.results ? keywordsDetails?.results[rowData?.rowIndex] : [];
          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              onMouseEnter={() => setHoveredRow({ campaign: data?.id })}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {edit.campaign == data?.id && keywordsUpdate ? (
                <TextField
                  sx={{ minWidth: '100px' }}
                  autoFocus={true}
                  defaultValue={value}
                  disabled={loading}
                  // autoFocus
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{loading ? <Loader /> : ''}</InputAdornment>
                  }}
                  onBlur={(e) => {
                    if (e.target.value != value) {
                      handleSaveButtonBid(e.target.value, data?.id);
                    } else {
                      setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
                    }
                  }}
                />
              ) : (
                <Box
                  onClick={
                    hoveredRow?.campaign === data?.id && ableToEdit && keywordsUpdate
                      ? () => {
                          setEdit({
                            ...edit,
                            campaign: data?.id,
                            total_budget: null,
                            daily_budget: null,
                            status: null,
                            end_date: null
                          });
                        }
                      : undefined
                  }
                  className=""
                >
                  {data?.campaign_type === 'sponsoredProducts' ? (
                    <Box lineHeight="7px" textAlign="right">
                      <BidPopover data={data} value={value} hoveredRow={hoveredRow} />
                    </Box>
                  ) : (
                    <Typography className="cursor-pointer" color="primary">
                      ${value}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          );
        }
      }
    },

    {
      name: 'impressions',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'impressions',
            columnLabel_1: 'Impressions'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_impressions}
                Percentage={data?.percentage_impressions}
                UpDown={data?.is_higher_impressions}
                keys="impressions"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'clicks',
            columnLabel_1: 'Clicks'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_clicks}
                Percentage={data?.percentage_clicks}
                UpDown={data?.is_higher_clicks}
                keys="clicks"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ctr',
            columnLabel_1: 'CTR'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_ctr}
                Percentage={data?.percentage_ctr}
                UpDown={data?.is_higher_ctr}
                keys="ctr"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'total_attributed_orders',
      label: 'Orders',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'total_attributed_orders',
            columnLabel_1: 'Orders'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_total_attributed_orders}
                Percentage={data?.percentage_total_attributed_orders}
                UpDown={data?.is_higher_total_attributed_orders}
                keys="total_attributed_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'ad_spend',
      label: 'Spend',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ad_spend',
            columnLabel_1: 'Spend'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_ad_spend}
                Percentage={data?.percentage_ad_spend}
                UpDown={data?.is_higher_ad_spend}
                keys="ad_spend"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'total_attributed_sales',
      label: 'Ad Sales',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'total_attributed_sales',
            columnLabel_1: 'Ad Sales'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_total_attributed_sales}
                Percentage={data?.percentage_total_attributed_sales}
                UpDown={data?.is_higher_total_attributed_sales}
                keys="total_attributed_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'roas',
      label: 'RoAS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'roas',
            columnLabel_1: 'RoAS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_roas}
                Percentage={data?.percentage_roas}
                UpDown={data?.is_higher_roas}
                keys="roas"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'units_sold',
      label: 'Units Sold',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'units_sold',
            columnLabel_1: 'Unit',
            columnLabel_2: 'Sold',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_units_sold}
                Percentage={data?.percentage_units_sold}
                UpDown={data?.is_higher_units_sold}
                keys="units_sold"
                currency={currency}
              />
            </>
          );
        }
        // customBodyRender: (value) => { return "$" + (value ? value : "0")}
      }
    },
    {
      name: 'acos',
      label: 'ACoS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'acos',
            columnLabel_1: 'ACoS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_acos}
                Percentage={data?.percentage_acos}
                UpDown={data?.is_higher_acos}
                keys="acos"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'average_cpc',
      label: 'Average CPC',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'average_cpc',
            columnLabel_1: 'Average',
            columnLabel_2: 'CPC',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_average_cpc}
                Percentage={data?.percentage_average_cpc}
                UpDown={data?.is_higher_average_cpc}
                keys="average_cpc"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cpa_orders',
      label: 'CPA (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cpa_orders',
            columnLabel_1: 'CPA',
            columnLabel_2: '(Orders)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_cpa_orders}
                Percentage={data?.percentage_cpa_orders}
                UpDown={data?.is_higher_cpa_orders}
                keys="cpa_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cpa_units',
      label: 'CPA (Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cpa_units',
            columnLabel_1: 'CPA',
            columnLabel_2: '(Units)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_cpa_units}
                Percentage={data?.percentage_cpa_units}
                UpDown={data?.is_higher_cpa_units}
                keys="cpa_units"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cvr_orders',
      label: 'CVR (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cvr_orders',
            columnLabel_1: 'CVR',
            columnLabel_2: '(Orders)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_cvr_orders}
                Percentage={data?.percentage_cvr_orders}
                UpDown={data?.is_higher_cvr_orders}
                keys="cvr_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cvr_units',
      label: 'CVR (Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cvr_units',
            columnLabel_1: 'CVR',
            columnLabel_2: '(Units)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_cvr_units}
                Percentage={data?.percentage_cvr_units}
                UpDown={data?.is_higher_cvr_units}
                keys="cvr_units"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_advertised_sales',
      label: 'In-Store Advertised Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        display: groupByForItem == 'aditem' ? false : true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_advertised_sales',
            columnLabel_1: 'In-Store Advertised',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_advertised_sales}
                Percentage={data?.percentage_instore_advertised_sales}
                UpDown={data?.is_higher_instore_advertised_sales}
                keys="instore_advertised_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_attributed_sales',
      label: 'In-Store Attributed Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        display: groupByForItem == 'aditem' ? false : true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_attributed_sales',
            columnLabel_1: 'In-Store Attributed',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_attributed_sales}
                Percentage={data?.percentage_instore_attributed_sales}
                UpDown={data?.is_higher_instore_attributed_sales}
                keys="instore_attributed_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_orders',
      label: 'In-Store Orders (Beta)',
      options: {
        filter: true,
        sort: true,
        display: groupByForItem == 'aditem' ? false : true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_orders',
            columnLabel_1: 'In-Store',
            columnLabel_2: 'Orders (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_orders}
                Percentage={data?.percentage_instore_orders}
                UpDown={data?.is_higher_instore_orders}
                keys="instore_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_other_sales',
      label: 'In-Store Other Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        display: groupByForItem == 'aditem' ? false : true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_other_sales',
            columnLabel_1: 'In-Store Other',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_other_sales}
                Percentage={data?.percentage_instore_other_sales}
                UpDown={data?.is_higher_instore_other_sales}
                keys="instore_other_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_units_sold',
      label: 'In-Store Units Sold (Beta)',
      options: {
        filter: true,
        sort: true,
        display: groupByForItem == 'aditem' ? false : true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_units_sold',
            columnLabel_1: 'In-Store Units',
            columnLabel_2: 'Sold (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = keywordsDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_units_sold}
                Percentage={data?.percentage_instore_units_sold}
                UpDown={data?.is_higher_instore_units_sold}
                keys="instore_units_sold"
                currency={currency}
              />
            </>
          );
        }
      }
    }
  ];

  const handleSearchClick = () => {
    shouldApplyFilter.current = true;
    setFilterForSearch({ ...tableFilter });
    const filledPerformanceRanges = Object.entries(performanceRanges).reduce((acc, [performanceId, values]) => {
      const { min, max } = values;
      if (min?.length > 0 || max?.length > 0) {
        acc[performanceId] = values;
      }
      return acc;
    }, {});
    const comparisonValues = filterDataList?.Performance && filterDataList?.Performance.map((item) => item.value);
    const PerformanceFilter =
      comparisonValues &&
      Object.fromEntries(Object.entries(filledPerformanceRanges).filter(([key]) => comparisonValues.includes(key)));
    setPerformanceFilterData(PerformanceFilter);
    if (groupBy?.value == '' || groupBy?.value == 'aditem') {
      columns?.filter((item) => !IsNoSelected?.includes(item?.name));
    } else if (groupBy?.value == 'campaign') {
      columns?.filter((item) => item?.name != 'adgroup_name' && item?.name != 'item_id');
    } else if (groupBy?.value == 'adgroup') {
      columns?.filter((item) => item?.name != 'item_id');
    }
    setGroupByForItem(groupBy?.value);

    if (tableFilter.campaign__in || tableFilter.campaign) {
      getAdvertiserKeywordsListWithPagination(
        0,
        rowPerPage,
        '',
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        PerformanceFilter ? PerformanceFilter : '',
        tableFilter
      );
    } else if (params?.id && filterValue.campaign__in[0]) {
      let tempTableFilter = { ...tableFilter, campaign__in: params?.id };
      getAdvertiserKeywordsListWithPagination(
        page,
        rowPerPage,
        search,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        PerformanceFilter ? PerformanceFilter : '',
        tempTableFilter
      );
    } else {
      getAdvertiserKeywordsListWithPagination(
        0,
        rowPerPage,
        search,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        PerformanceFilter,
        tableFilter
      );
    }
  };

  const handleDownloadCsv = () => {
    setLoadingDownload(true);
    getKeywordsList(0, rowPerPage, true);
  };

  useEffect(() => {
    // setDisplayColumn(columns)
    columns?.filter((item) => item?.name != 'item_id');
  }, [hoveredRow, edit]);

  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedKeywordName, setSelectedKeywordName] = useState([]);

  const [showDropdown, SetShowDropDown] = useState(false);

  useEffect(() => {
    if (selectedArray) {
      if (selectedArray.length >= 2) {
        SetShowDropDown(true);
      } else {
        SetShowDropDown(false);
      }
    }
  }, [selectedArray]);

  const handleChangeDropdownStatus = () => {
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';

    let payload11 = {
      payload: { keyword_id: selectedArray, status: StatusValueForCondition },
      defaultData: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: advertiserFilter,
        PerformanceData: PerformanceFilterData || '',
        tableFilter: filterForSearch,
        groupBy,
        commonDate: commonDateRange,
        isComparingDates
      },
      setSuccessFailModal
    };
    setLoadingStatus(true);
    const updatedKeywordDetails = updateKeywordDetails(keywordsDetails, selectedArray, [{ state: StatusValueForCondition }]);
    dispatch(FilterMultipleStatusEnableAndPauseKeyword(payload11)).then((res) => {
      if (res.payload) {
        const { data } = res.payload;
        const { totalcount: totalCount, successcount: successCount, error_message: statusChangeFailedId } = data.data;
        const finalCampaignDetails =
          totalCount !== successCount
            ? resetFailedKeywordAttribute(updatedKeywordDetails, keywordsDetails, statusChangeFailedId, 'state')
            : updatedKeywordDetails;
        dispatch(setKeywordsDetails(finalCampaignDetails));
        setLoadingStatus(false);
        SetShowDropDown(false);
        setSuccessFailModal(true);
        setSuccessFailData(res?.payload?.data?.data);
        setStatusForCondition('');
        setIsMultipleStatusConfirmationOpen(false);
      }
    });
  };
  const [appliedSortTing, setAppliedSortTing] = useState();

  const initialColumnOrder = columns.map((_, index) => index);
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  const options = {
    filter: false,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    selectToolbarPlacement: 'none',
    tableBodyHeight: tableHeight,
    filterType: 'checkbox',
    page: page,
    count: keywordsDetails?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: true,
    selectableRows: true,
    rowsPerPage: rowPerPage,
    search: false, // Disable the search toolbar
    customToolbar: null,
    rowsPerPageOptions: pageValueKeyword,
    draggableColumns: {
      enabled: false
    },
    columnOrder: columnOrder,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      const selectedIds = rowsSelected.map((selectedIndex) => keywordsDetails?.results?.[selectedIndex]?.id);
      const selectedName = rowsSelected.map((selectedIndex) => keywordsDetails?.results?.[selectedIndex]?.keywordtext);
      setSelectedArray(selectedIds);
      setSelectedKeywordName(selectedName);
      return null;
    },
    onColumnOrderChange: (newColumnOrder) => {
      if (newColumnOrder[0] !== 0 || newColumnOrder[1] !== 1) {
        setColumnOrder([...columnOrder]);
      } else {
        setColumnOrder(newColumnOrder);
      }
    },
    textLabels: {
      body: {
        noMatch: showSelectAdviser ? (
          tableLoading ? (
            <Loader />
          ) : (
            'Sorry, there is no matching data to display'
          )
        ) : (
          'Please Select At List One Advertiser '
        )
      }
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getKeywordsList(0, row);
    },
    onChangePage: (page) => {
      setPage(page);
      getKeywordsList(page);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setSearch('');
      getAdvertiserKeywordsListWithPagination(0, rowPerPage, '', '', '', '', '', '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');

      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      // getKeywordsList(0, rowPerPage);
      getAdvertiserKeywordsListWithPagination(
        0,
        rowPerPage,
        '',
        changedColumn,
        direction,
        commonAdvertiser,
        shouldApplyFilter?.current ? PerformanceFilterData : {},
        shouldApplyFilter?.current ? filterForSearch : {}
      );
    },
    customTableBodyFooterRender: (opts) => {
      const optsColumns = opts?.columns;
      const reorderedColumns = columnOrder.map((index) => optsColumns[index]);
      return (
        <>
          {commonAdvertiser?.length > 0 && (
            <TableFooter>
              <TableRow className="table-footer-total" sx={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 999 }}>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    position: 'sticky',
                    left: 1,
                    // minWidth: '180px',
                    background: 'white',
                    zIndex: 100
                  }}
                ></TableCell>
                {reorderedColumns?.map((col, index) => {
                  if (col && col.display === 'true' && newDataTotal) {
                    let currency = newDataTotal[col?.name] && newDataTotal[col?.name].indexOf('$') > -1;
                    return (
                      <TableCell
                        className={`${col?.sticky ? 'column-sticky' : 'column-sticky-1'} ${
                          alignRightColumn(col?.name) && 'table-align-right'
                        }`}
                        sx={
                          col?.sticky
                            ? {
                                whiteSpace: 'nowrap',
                                position: 'sticky',
                                left: col?.leftTotal,
                                fontSize: '14px',
                                // fontSize: col?.FontSize?.length > 0 ? col?.FontSize : '',
                                minwidth: col?.name === 'state' ? '90px' : '180px',
                                background: 'white',
                                zIndex: 100,
                                color: '#364152',
                                fontWeight: 'bold'
                              }
                            : {}
                        }
                        key={index}
                      >
                        {col?.name == 'keywordtext' ? (
                          'Total'
                        ) : (
                          <>
                            <span style={{ color: '#364152', fontWeight: 'bold' }}>{newDataTotal[col?.name] || ''}</span>
                            <CompareValueWithColor
                              value={newDataTotal[`comparison_${col?.name}`] && newDataTotal[`comparison_${col?.name}`]}
                              Percentage={
                                newDataTotal[`percentage_${col?.name}`] && parseFloat(newDataTotal[`percentage_${col?.name}`])
                              }
                              UpDown={
                                typeof newDataTotal[`is_higher_${col?.name}`] != 'undefined' &&
                                newDataTotal[`is_higher_${col?.name}`] == 'true'
                                  ? true
                                  : false
                              }
                              keys={col?.name}
                              isTotalRow={true}
                              currency={currency}
                            />
                          </>
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserKeywordsListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  const handleModalClose = () => {
    setModalOpen(false);
    dispatch(setAdgroupList([])); //Clear selected campaign adgroups in store
  };

  const group_by = [
    { id: 1, name: 'Campaign', value: 'campaign' },
    { id: 2, name: 'Ad Group', value: 'adgroup' },
    { id: 3, name: 'Items', value: 'aditem' },
    { id: 4, name: 'Keyword', value: 'keyword' }
  ];

  const [ErrorShow, setErrorShow] = useState([]);
  const [finalErrorShow, setFinalShow] = useState(false);
  const handleInputChange = (performanceId, valueType, value, showError) => {
    setErrorShow((ErrorShow) => ({
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    }));

    let a = {
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    };
    let output = Object.values(a).map((item) => {
      if (typeof item === 'object' && item !== null && 'error' in item) {
        return item.error;
      } else {
        return item;
      }
    });
    let result = [...new Set(output)];

    let finalOutPut = result.length == 1 && result[0] == false ? false : true;
    setFinalShow(finalOutPut);

    setPerformanceRanges((prevRanges) => ({
      ...prevRanges,
      [performanceId]: {
        ...prevRanges[performanceId],
        [valueType]: value
      }
    }));
  };

  const [bidType, setBidType] = useState('label');
  const [selectedOption, setSelectedOption] = useState('+');
  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [textField1Value, setTextField1Value] = useState('');
  const [textField2Value, setTextField2Value] = useState('');
  const [bidAmount, setBidAmount] = useState('');
  const [bidAmountCurrent, setBidAmountCurrent] = useState(100);
  const [bidAmountCurrentDollar, setBidAmountCurrentDollar] = useState('');

  const handleBidTypeChange = (event) => {
    resetRelevantState();
    setBidType(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCheckbox1Change = (event) => {
    setCheckbox1Checked(event.target.checked);
    if (event.target.checked) {
      setTextField1Value(''); // Reset text field value if checkbox is checked
    }
  };

  const handleCheckbox2Change = (event) => {
    setCheckbox2Checked(event.target.checked);
    if (event.target.checked) {
      setTextField2Value(''); // Reset text field value if checkbox is checked
    }
  };

  const HandleModalCloseStatus = () => {
    setIsMultipleStatusConfirmationOpen(false);
  };

  const handleConfirmStatusChange = () => {
    handleChangeDropdownStatus();
  };

  const handleConfirm = () => {
    const allError = {};
    if (bidType === 'label' && (!bidAmount || bidAmount <= 0)) {
      allError.bidAmount = true;
    }
    setError(allError);

    if (!Object.keys(allError).length) {
      if ((bidType == 'label' && bidAmount.length !== 0) || (bidType == 'current' && bidAmountCurrent.length !== 0)) {
        if (bidType === 'label') {
          const payload = {
            payload1: {
              keyword_id: selectedArray,
              bid: parseFloat(bidAmount)
            },
            defaultData: {
              page,
              rowPerPage,
              search,
              changedColumn: appliedSortTing?.changedColumnSort,
              direction: appliedSortTing?.directionSort,
              commonAdvertiserList: commonAdvertiser,
              PerformanceData: PerformanceFilterData || '',
              tableFilter: filterForSearch,
              groupBy,
              commonDate: commonDateRange,
              isComparingDates
            },
            setSuccessFailModal
          };
          setLoadingStatus(true);
          const updatedKeywordDetails = updateKeywordDetails(keywordsDetails, selectedArray, [
            { bid_amount: parseFloat(bidAmount) }
          ]);
          dispatch(updateMultipleBidKeyword(payload)).then((res) => {
            if (res.payload) {
              const { data } = res.payload;
              const { totalcount: totalCount, successcount: successCount, error_message: statusChangeFailedId } = data.data;
              const finalCampaignDetails =
                totalCount !== successCount
                  ? resetFailedKeywordAttribute(updatedKeywordDetails, keywordsDetails, statusChangeFailedId, 'bid_amount')
                  : updatedKeywordDetails;
              dispatch(setKeywordsDetails(finalCampaignDetails));
              setLoadingStatus(false);
              SetShowDropDown(false);
              setSuccessFailModal(true);
              setSuccessFailData(res?.payload?.data?.data);
              setIsMultipleBidConfirmationOpen(false);
            }
          });
        } else {
          const payload = {
            payload1: {
              keyword_id: selectedArray,
              percentage: parseFloat(bidAmountCurrent),
              bid: parseFloat(bidAmountCurrentDollar) || 0,
              sign: selectedOption
            },
            defaultData: {
              page,
              rowPerPage,
              search,
              changedColumn: appliedSortTing?.changedColumnSort,
              direction: appliedSortTing?.directionSort,
              commonAdvertiserList: commonAdvertiser,
              PerformanceData: PerformanceFilterData || '',
              tableFilter: filterForSearch,
              groupBy,
              commonDate: commonDateRange,
              isComparingDates
            }
          };

          if (textField1Value && checkbox1Checked) {
            Object.assign(payload.payload1, { no_lower: Number(textField1Value) });
          }
          if (textField2Value && checkbox2Checked) {
            Object.assign(payload.payload1, { no_higher: Number(textField2Value) });
          }
          dispatch(setCommonLoader(true));
          dispatch(updateMultipleBidKeywordMultiple(payload)).then((res) => {
            if (res.payload) {
              setIsMultipleBidConfirmationOpen(false);
              SetShowDropDown(false);
              setSuccessFailData(res?.payload?.data?.data);
              setSuccessFailModal(true);
              dispatch(setCommonLoader(false));
            }
          });
        }
      }
    }
  };

  const [StatusValueForCondition, setStatusForCondition] = useState('');
  const handleChange = (value) => {
    if (value == 'paused') {
      setIsMultipleStatusConfirmationOpen(true);
      setStatusForCondition('paused');
    }
    if (value == 'enabled') {
      setIsMultipleStatusConfirmationOpen(true);
      setStatusForCondition('enabled');
    }
    if (value == 'archived') {
      setIsMultipleStatusConfirmationOpen(true);
      setStatusForCondition('archived');
    }
  };

  const menuItemsData = {
    label: 'Bulk Edit',
    items: [
      {
        label: 'Set Bid',
        value: 'setbid',
        callback: (event, item) => handleSetBidOpen(item.value)
      },
      {
        label: 'Change State',
        items: [
          {
            label: 'Paused',
            value: 'paused',
            callback: (event, item) => handleChange(item.value)
          },
          {
            label: 'Enabled',
            value: 'enabled',
            callback: (event, item) => handleChange(item.value)
          },
          {
            label: 'Archived',
            value: 'archived',
            callback: (event, item) => handleChange(item.value)
          }
        ]
      }
    ]
  };

  const resetRelevantState = () => {
    setError({}); //Empty error state
    setBidAmount('');
    setSelectedOption('+');
    setBidAmountCurrentDollar('');
    setTextField1Value('');
    setTextField2Value('');
    setCheckbox1Checked(false);
    setCheckbox2Checked(false);
    setBidType('label');
  };

  const handleSetBidOpen = () => {
    resetRelevantState();
    setIsMultipleBidConfirmationOpen(true);
  };

  // added code for modal
  const navigate = useNavigate();
  const [selectedAdgroup, setSelectAdgroup] = useState({ advertiser: null, campaign: null });
  const [targetingType, setTargetingType] = useState('');
  const [isManual, setIsManual] = useState('');
  const [campaignModal, setCampaignModal] = useState({ open: false, id: null });
  const [campaignBrandModal, setCampaignBrandModal] = useState({ open: false, id: null });
  const [campaignVideoModal, setCampaignVideoModal] = useState({ open: false, id: null });
  const [itemModal, setItemModal] = useState({ open: false, id: null, advertiser_id: null });
  const [keywordModal, setKeywordModal] = useState({ open: false, id: null, name: '' });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleKeywordModalClose = () => {
    setKeywordModal({ open: false, id: null });
    dispatch(setAdgroupList([])); //Clear selected campaign adgroups in store
  };

  const handleItemModalClose = () => {
    setItemModal({ open: false, id: null, name: null });
    dispatch(setAdgroupList([])); //Clear selected campaign adgroups in store
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handledEditClose = () => {
    setCampaignModal({ open: false, id: null });
  };

  const handledEditBrandClose = () => {
    setCampaignBrandModal({ open: false, id: null });
  };

  const handleEditVideoClose = () => {
    setCampaignVideoModal({ open: false, id: null });
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, value, name) => {
    setSelectAdgroup({ advertiser: name?.advertiser_id, campaign: value });
    setAnchorEl(event.currentTarget);
    setTargetingType(name?.campaign_type);
    name?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL ? setIsManual(true) : setIsManual(false);
    setCampaignModal({ ...campaignModal, id: value });
    setItemModal({
      ...itemModal,
      id: value,
      name: name?.campaign_name,
      advertiser_id: name?.advertiser_id,
      targeting_type: name?.targeting_type,
      adgroup_id: name?.adgroup_id //I add adgroup_id for auto selected adgroup in Add Items Modal
    });
    setCampaignBrandModal({ ...campaignBrandModal, id: value });
    setCampaignVideoModal({
      ...campaignVideoModal,
      id: value,
      advertiser_id: name?.advertiser_id,
      adgroup: name?.adgroup_number
    });
    setKeywordModal({
      ...keywordModal,
      id: value,
      name: name?.campaign_name,
      adgroup_id: name?.adgroup_id //I add adgroup_id for auto selected adgroup in Add Items Modal
    });
  };

  const handleAddAdGroup = () => {
    navigate('/create-adgroup', { state: { advertise: selectedAdgroup.advertiser, id: selectedAdgroup.campaign } });
  };

  return (
    <MainCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <CustomFilters filterType={'keyword_filter'} defaultFilterKey={params?.id ? 'campaign' : null} />
          <FormControl fullWidth sx={{ minWidth: '130px' }}>
            <InputLabel color="secondary">Attribution</InputLabel>
            <Select
              value={attribute || []}
              color="secondary"
              label="Attribution"
              fullWidth
              onChange={(e) => {
                setAttribute(e.target.value);
                setTableFilter({ ...tableFilter, attr_windows: e.target.value });
                setFilterValue({ ...filterValue, attr_windows: e.target.value });
              }}
              sx={{ width: '100%' }}
            >
              {attributeWindowList?.map((item) => {
                return (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>{' '}
          <FormControl fullWidth sx={{ minWidth: '130px' }}>
            <Autocomplete
              disableClearable={true}
              id="checkboxes-tags-demo"
              name="Group by"
              limitTags={2}
              options={group_by || []}
              value={group_by?.value}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              defaultValue={{ id: 2, name: 'Ad Group', value: 'adgroup' }}
              onChange={(e, value) => {
                setGroupBy(value);
                e.target.blur();
                setAppliedSortTing(); //Reset changedcolumn and direction when change group by
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option?.name}</li>;
              }}
              renderInput={(params) => <TextField color="secondary" sx={{}} {...params} label="Group by" />}
            />
          </FormControl>
          {showDropdown && (
            <Box className="Custom-bulk-name">
              <NestedDropdown
                style={{ width: '160px' }}
                className="Custom-nested-dropdown"
                menuItemsData={menuItemsData}
                MenuProps={{ elevation: 1, className: 'Custom-menu-bulk' }}
                ButtonProps={{
                  variant: 'contained',
                  className: 'Custom-dropDown-bulk',
                  startIcon: <img src={MultiSelectIcon} alt="multiselect icon" />, // Adding the start icon here
                  endIcon: <img src={arrowDownIcon} alt="multiselect icon" /> // Remove any end icon including a dropdown arrow
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {keywordsCreate && (
            <Button
              variant="contained"
              onClick={() => {
                if (filterValue?.campaign__in?.length === 1) {
                  setKeywordModal1({
                    ...keywordModal1,
                    ...filterValue?.campaign__in?.[0],
                    ...(filterValue?.ad_group?.length === 1 ? { adgroup_id: filterValue?.ad_group?.[0]?.adgroup_id } : {})
                  });
                } else {
                  if (keywordModal1?.campaign_id || keywordModal1?.ad_group) {
                    setKeywordModal1({ open: false, id: null, name: '' });
                  }
                }
                setModalOpen(true);
              }}
            >
              <AddOutlinedIcon sx={{ mr: 0.5 }} /> Add Keyword
            </Button>
          )}
          <IconButton onClick={handleColumnSelectorOpen}>
            <Tooltip arrow title="Select/Reorder Columns">
              {' '}
              <img src={viewcolumnsIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />{' '}
            </Tooltip>
          </IconButton>

          {keywordsDownload && (
            <IconButton onClick={handleDownloadCsv}>
              {loadingDownload ? (
                <CircularProgress size="20px" />
              ) : (
                <Tooltip arrow title="Download">
                  <img src={DownloadIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
                </Tooltip>
              )}
            </IconButton>
          )}
        </Box>
      </Box>
      <Grid container sx={{ pt: 3, pb: 3 }} spacing={3}>
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'state') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="campaign_state"
              name="campaign_state"
              color="secondary"
              label="Campaign Status"
              options={campaigState || []}
              value={filterValue?.status || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'status');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamSt ? searchText?.status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, status: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamSt(true)}
              onClose={() => setIsDropdownOpenCamSt(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('status')}
              placeholderCondition={filterValue?.status?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'keyword_status') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="keyword_status"
              name="keyword_status"
              color="secondary"
              label="Keyword Status"
              options={KeywordState || []}
              value={filterValue?.keyword_status || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'keyword_status');
              }}
              groupBy={(KeywordState) => KeywordState?.category || ''}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamKeywordSt ? searchText?.keyword_status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, keyword_status: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamKeywordSt(true)}
              onClose={() => setIsDropdownOpenCamKeywordSt(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('keyword_status')}
              placeholderCondition={filterValue?.statuskeyword_status?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}
        {(filterDataList && filterDataList?.Common?.find((item) => item?.value == 'campaign')) || (params && params?.id) ? (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="campaign_name"
              name="campaign_name"
              color="secondary"
              label="Campaign Name"
              options={campaignNameList || []}
              value={filterValue?.campaign__in || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'campaign_search');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpen ? searchText?.campaign_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, campaign_search: value });
                }
              }}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_search')}
              placeholderCondition={filterValue?.campaign__in?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        ) : null}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'adgroup') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="adgroup_name"
              name="adgroup_name"
              color="secondary"
              label="Ad Group"
              options={adGroupShortList || []}
              value={filterValue?.ad_group || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'ad_group_search');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenAdgroup ? searchText?.ad_group_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, ad_group_search: value });
                }
              }}
              onOpen={() => setIsDropdownOpenAdgroup(true)}
              onClose={() => setIsDropdownOpenAdgroup(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('ad_group_search')}
              placeholderCondition={filterValue?.ad_group?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'campaign_type') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="campaign_type"
              name="campaign_type"
              color="secondary"
              label="Campaign Type"
              options={campaign_type || []}
              value={filterValue?.type || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'campaign_type');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamType ? searchText?.campaign_type : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, campaign_type: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamType(true)}
              onClose={() => setIsDropdownOpenCamType(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_type')}
              placeholderCondition={filterValue?.type?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'match_type') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="match_type"
              name="match_type"
              color="secondary"
              label="Match Type"
              options={match_type || []}
              value={filterValue?.match_type || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'match_type');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamMatchType ? searchText?.match_type : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, match_type: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamMatchType(true)}
              onClose={() => setIsDropdownOpenCamMatchType(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('match_type')}
              placeholderCondition={filterValue?.match_type?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'search') && (
          <Grid item xs={12} md={3}>
            <BasicPopover
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
              searchCampaign={searchCampaign}
              setSearchCampaign={setSearchCampaign}
              from={'Keyword'}
              isReset={shouldApplyFilter?.current}
            />
          </Grid>
        )}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'adgroup_state') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="adgroup_state"
              name="adgroup_state"
              color="secondary"
              label="Adgroup Status"
              options={adGroup_state || []}
              value={filterValue?.adGroup_state || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'ad_group_status');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamAdSt ? searchText?.ad_group_status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, ad_group_status: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamAdSt(true)}
              onClose={() => setIsDropdownOpenCamAdSt(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('ad_group_status')}
              placeholderCondition={filterValue?.adGroup_state?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}
        {filterDataList?.Performance && (
          <>
            {filterDataList?.Performance?.map((performanceItem) => (
              <Grid item xs={12} md={3} key={performanceItem.id}>
                <FilterPerformance
                  performanceItem={performanceItem}
                  isReset={shouldApplyFilter?.current}
                  handleInputChange={handleInputChange}
                />
              </Grid>
            ))}
          </>
        )}{' '}
        <Grid item xs={12} md={3}>
          <Button
            className="setMarginRight"
            variant="contained"
            color="secondary"
            disabled={finalErrorShow}
            onClick={handleSearchClick}
          >
            <SearchIcon sx={{ mr: 0.5 }} />
            Search
          </Button>
        </Grid>
        <Dialog open={isDeleteConfirmationOpen} onClose={handleCloseDeleteConfirmation}>
          <DialogTitle sx={{ fontWeight: '700', fontSize: '14px' }}>Edit Keyword Details</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Are you sure you want to Edit this Keyword Details?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteConfirmation} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleDeleteReport} variant="contained" color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth open={isMultipleBidConfirmationOpen} onClose={() => setIsMultipleBidConfirmationOpen(false)}>
          <DialogTitle sx={{ fontWeight: '700', fontSize: '14px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div" sx={{ fontWeight: 700 }}>
              Set Bid
            </Typography>
            <Typography sx={{ fontWeight: 700 }} onClick={() => setIsMultipleBidConfirmationOpen(false)}>
              {' '}
              <CloseIcon />
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <Typography variant="h5"> Keyword(Total:{selectedKeywordName.length})</Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '80px',
                  width: '100%',
                  my: 2,
                  p: 1,
                  '&::-webkit-scrollbar': {
                    width: '0.1em',
                    backgroundColor: '#C5C5C5'
                  },
                  '&::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#7A8183',
                    outline: '1px solid slategrey'
                  }
                }}
              >
                {selectedKeywordName &&
                  selectedKeywordName.map((name, index) => {
                    return <Typography key={index}>{name}</Typography>;
                  })}
              </Box>
            </Box>
            <FormControl fullWidth>
              <RadioGroup aria-label="bidType" name="bidType" value={bidType} onChange={handleBidTypeChange}>
                <FormControlLabel value="label" control={<Radio />} label="Set Bid to " />

                <FormControl sx={{ width: 'fit-content', m: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="Amount"
                    type="number"
                    value={bidAmount}
                    onChange={(e) => {
                      setBidAmount(e.target.value);
                      setError({ ...error, bidAmount: false });
                    }}
                    disabled={bidType !== 'label'}
                    error={error?.bidAmount}
                  />
                  <Box sx={{ color: 'red' }}>{error?.bidAmount && bidType == 'label' && 'Please add Amount'}</Box>
                </FormControl>

                <FormControlLabel value="current" control={<Radio />} label="Set Bid to Current" />

                <FormControl sx={{ width: 'fit-content', m: 1 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <OutlinedInput
                      id="outlined-adornment-amount-1"
                      disabled={bidType !== 'current'}
                      value={bidAmountCurrent}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 4) {
                          setBidAmountCurrent(e.target.value);
                        }
                      }}
                      error={bidAmountCurrent.length == 0}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    />
                    <Box sx={{ color: 'red' }}>{bidAmountCurrent.length == 0 && bidType == 'current' && 'Please add this'}</Box>

                    <FormControl sx={{ width: '33%' }} variant="outlined">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedOption}
                        disabled={bidType !== 'current'}
                        onChange={handleOptionChange}
                      >
                        <MenuItem value="+">+</MenuItem>
                        <MenuItem value="-">-</MenuItem>
                      </Select>
                    </FormControl>

                    <OutlinedInput
                      id="outlined-adornment-amount-1"
                      disabled={bidType !== 'current'}
                      value={bidAmountCurrentDollar}
                      onChange={(e) => {
                        setBidAmountCurrentDollar(e.target.value);
                        setError({ ...error, bidAmountCurrentDollar: false });
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      // error={error?.bidAmountCurrentDollar}
                    />
                    <Box sx={{ color: 'red' }}>
                      {/* {error?.bidAmountCurrentDollar && bidType == 'current' && 'Please add Amount'} */}
                    </Box>
                  </Box>

                  {bidType === 'current' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                      <Box>
                        <FormControlLabel
                          control={<Checkbox checked={checkbox1Checked} onChange={handleCheckbox1Change} />}
                          label="No lower than"
                        />
                        <TextField
                          disabled={!checkbox1Checked}
                          label="No lower than"
                          value={textField1Value}
                          onChange={(e) => setTextField1Value(e.target.value)}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={<Checkbox checked={checkbox2Checked} onChange={handleCheckbox2Change} />}
                          label="No Higher than"
                        />
                        <TextField
                          disabled={!checkbox2Checked}
                          label="No Higher than"
                          value={textField2Value}
                          onChange={(e) => setTextField2Value(e.target.value)}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box sx={{ my: 2 }}>
                    <Typography>SP keyword bids lower than 0.3 are finally uploaded as 0.3;</Typography>
                    <Typography> SB keyword bids lower than 1 are finally;</Typography>
                    <Typography> uploaded as 1; SV keyword bids lower than 1 are finally uploaded as 1.3.</Typography>
                  </Box>
                </FormControl>

                {/* Confirm Button */}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsMultipleBidConfirmationOpen(false)} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={handleConfirm} disabled={loadingStatus}>
              {loadingStatus && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* status confirm */}
        <Dialog fullWidth open={isMultipleStatusConfirmationOpen} onClose={HandleModalCloseStatus}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div" sx={{ fontWeight: 700 }}>
              Status Change Confirm
            </Typography>
            <Typography sx={{ fontWeight: 700, cursor: 'pointer' }} onClick={HandleModalCloseStatus}>
              {' '}
              <CloseIcon />
            </Typography>
          </DialogTitle>

          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <Typography sx={{ fontSize: '16px', color: 'black', pr: 2, display: 'flex', marginLeft: '23px' }}>
              Confirm to change the status to{' '}
              <strong>&nbsp;{StatusValueForCondition.charAt(0).toUpperCase() + StatusValueForCondition.slice(1)}</strong>
            </Typography>
          </Box>
          <DialogContent dividers>
            {/* <Label>End Date :</Label> */}

            <Box sx={{ width: '-webkit-fill-available' }}>
              <Typography variant="h5">Selected Keywords({selectedKeywordName?.length})</Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '80px',
                  width: '100%',
                  my: 2,
                  p: 1,
                  '&::-webkit-scrollbar': {
                    width: '0.1em',
                    backgroundColor: '#C5C5C5'
                  },
                  '&::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#7A8183',
                    outline: '1px solid slategrey'
                  }
                }}
              >
                {selectedKeywordName &&
                  selectedKeywordName?.map((name, index) => (
                    <Typography key={index}>
                      {index + 1}.{name}
                    </Typography>
                  ))}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={HandleModalCloseStatus} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={handleConfirmStatusChange} disabled={loadingStatus}>
              {loadingStatus && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      {skeletonLoader && <TableSkeleton />}
      <Box sx={{ display: skeletonLoader ? 'none' : 'block' }} className={showSelectAdviser ? 'custom-keyword-table' : ''}>
        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          data={tableLoading ? [] : showSelectAdviser ? newData : []}
          columns={getUpdatedColumns(columns, selectedColumns, groupByForItem, 'keywords')}
          options={options}
          className="pnl-by-item"
        />
      </Box>
      {viewColumnsSelector && (
        <CustomColumnSelector
          open={viewColumnsSelector}
          handleClose={handleColumnSelectorClose}
          tableName="keyword_column"
          defaultSelected={selectedColumns}
          columns={getUpdatedColumns(columns, selectedColumns, groupByForItem, 'keywords')}
          setColumnOrder={setColumnOrder}
          columnOrder={columnOrder}
        />
      )}
      {successFailModal && (
        <SuccessAndFail
          dialogOpen={successFailModal}
          SuccessFailData={SuccessFailData}
          handleOkButtonClick={() => setSuccessFailModal(false)}
          label="Keyword"
        />
      )}

      {campaignModal.open && (
        <Dialog
          open={campaignModal.open}
          keepMounted
          onClose={handledEditClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SP Campaign</Typography>
              <IconButton onClick={handledEditClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditCampaignSetting
              campaignModal={campaignModal}
              onClose={handledEditClose}
              GetApi={() =>
                getAdvertiserKeywordsListWithPagination(
                  page,
                  rowPerPage,
                  search,
                  appliedSortTing?.changedColumnSort,
                  appliedSortTing?.directionSort,
                  commonAdvertiser,
                  PerformanceFilterData,
                  filterForSearch
                )
              }
            />
          </DialogContent>
        </Dialog>
      )}

      {itemModal.open && (
        <Dialog
          open={itemModal.open}
          keepMounted
          onClose={handleItemModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add item</Typography>
              <IconButton onClick={handleItemModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <ProductAdGroupSetting
              GetApi={() =>
                getAdvertiserKeywordsListWithPagination(
                  page,
                  rowPerPage,
                  search,
                  appliedSortTing?.changedColumnSort,
                  appliedSortTing?.directionSort,
                  commonAdvertiser,
                  PerformanceFilterData,
                  filterForSearch
                )
              }
              itemModal={itemModal}
              onClose={handleItemModalClose}
            />
          </DialogContent>
        </Dialog>
      )}

      {keywordModal.open && (
        <Dialog
          open={keywordModal.open}
          keepMounted
          onClose={handleKeywordModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add Keyword</Typography>
              <IconButton onClick={handleKeywordModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {/* <EditCampaignSetting campaignModal={itemModal} onClose={handleItemModalClose} /> */}
            <TargetAdGroupSetting
              keywordModal={keywordModal}
              onClose={handleKeywordModalClose}
              addKeywordModel={false}
              GetApi={() =>
                getAdvertiserKeywordsListWithPagination(
                  page,
                  rowPerPage,
                  search,
                  appliedSortTing?.changedColumnSort,
                  appliedSortTing?.directionSort,
                  commonAdvertiser,
                  PerformanceFilterData,
                  filterForSearch
                )
              }
            />
          </DialogContent>
        </Dialog>
      )}

      {campaignBrandModal.open && (
        <Dialog
          open={campaignBrandModal.open}
          keepMounted
          onClose={handledEditBrandClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SB Campaign</Typography>
              <IconButton onClick={handledEditBrandClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditCampaignBrandSetting campaignModal={campaignBrandModal} onClose={handledEditBrandClose} />
          </DialogContent>
        </Dialog>
      )}

      {campaignVideoModal.open && (
        <Dialog
          open={campaignVideoModal.open}
          keepMounted
          onClose={handleEditVideoClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SV Campaign</Typography>
              <IconButton onClick={handleEditVideoClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers sx={{ width: '900px' }}>
            <EditCampaignVideoSetting campaignModal={campaignVideoModal} onClose={handleEditVideoClose} />
          </DialogContent>
        </Dialog>
      )}
      {modalopen && (
        <Dialog
          open={modalopen}
          keepMounted
          onClose={handleModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add Keyword</Typography>
              <IconButton onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <TargetAdGroupSetting
              keywordModal={keywordModal1}
              onClose={handleModalClose}
              addKeywordModel={true}
              GetApi={() =>
                getAdvertiserKeywordsListWithPagination(
                  page,
                  rowPerPage,
                  search,
                  appliedSortTing?.changedColumnSort,
                  appliedSortTing?.directionSort,
                  commonAdvertiser,
                  PerformanceFilterData,
                  filterForSearch
                )
              }
            />
          </DialogContent>
        </Dialog>
      )}
    </MainCard>
  );
};

export default Keywords;
