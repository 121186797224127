import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  FormHelperText
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import __ from 'underscore';
import SelectFromTable from './searchTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { useAppSelector } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import EnterKeywordTable from './enterKeywordTable';
import {
  getAdgorupCampaignList,
  getAdvertiserById,
  // getAlldvertiserList,
  getSelectFromItemsFromId,
  getSuggestedKeyword,
  postMultipleKeyword
} from 'store/thunk/userThunk';
import { useEffect } from 'react';
import { setSuggestedkeywordData } from 'store/slices/userDetailsSlice';
import { PageValue } from 'store/constant';

const TargetAdGroupSetting = (props) => {
  const { keywordModal, onClose, addKeywordModel, GetApi } = props;

  const [value, setValue] = useState('2');
  const [loading, setLoading] = useState(false);
  const [selectedKeywordData, setSelectedKeywordData] = useState([]);
  const [selectedAdgroup, setSelectedAdgroup] = useState([]);
  const [error, setError] = useState({ adGroup: false, adItem: false });
  const [isMultipleAdgroup, setIsmultipleAdgroup] = useState(false);
  const [profileValue, setProfileValue] = useState(null);
  const [campaignValue, setCampaignValue] = useState([]);
  const { adgroupList } = useAppSelector((state) => state?.userDetailsSlice);
  const { campaignByIdData, allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [profileOption, setProfileOption] = useState([]);
  const dispatch = useAppDispatch();

  const selectedProfiles = localStorage.getItem('selectedProfiles');

  //Auto select First Profile while page load and we have allUserDetails
  useEffect(() => {
    if (allUserDetails?.length && profileOption?.length) {
      if (selectedProfiles) {
        const selectedProfileFirstId = selectedProfiles?.split(',')?.pop();
        let selectedProfile = allUserDetails
          ?.filter((l) => l?.id == selectedProfileFirstId)
          ?.map((l) => ({ id: l?.id, name: l?.name }))?.[0];
        setProfileValue(selectedProfile);
      } else {
        setProfileValue(null);
      }
    }
  }, [allUserDetails, selectedProfiles, profileOption]);

  //For Automatically selected Campaign based on selected a campaign from filters
  useEffect(() => {
    if (!campaignByIdData?.length) return;
    if (keywordModal?.campaign_id && campaignByIdData?.length) {
      const selectedFilteredCmpId = keywordModal?.campaign_id;
      const fltCmp = campaignByIdData?.filter((l) => l?.campaign_id === selectedFilteredCmpId);
      if (!__.isEmpty(fltCmp)) {
        setCampaignValue(fltCmp);
      }
    }
  }, [keywordModal, campaignByIdData]);

  //Auto selected Adgroup Name
  useEffect(() => {
    if (!campaignValue?.length && addKeywordModel) return; //Campaign Must be selected Prior to Adgroup in the case of Add Keyword Button click
    if (adgroupList?.length && adgroupList?.length === 1) {
      setSelectedAdgroup(adgroupList);
    } else {
      //Select Particular Adgroup if we have more adgroups and we have particular adgroup_id
      if (keywordModal?.adgroup_id) {
        const getAdgroup = adgroupList?.filter((l) => l?.adgroup_id === keywordModal?.adgroup_id);
        setSelectedAdgroup(getAdgroup);
      } else {
        //If user change campaign and it have multiple adgroup then selected adgroup will clear
        if (adgroupList?.length > 1) {
          setSelectedAdgroup([]);
        }
      }
      //If selected Campaign has no Adgroup then it would clear prior selected adgroup
      if (!adgroupList?.length) {
        setSelectedAdgroup([]);
      }
    }
  }, [adgroupList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // const data = null;
    // dispatch(getAlldvertiserList({ data }));
  }, []);

  useEffect(() => {
    let temp = allUserDetails?.map((item) => ({ id: item?.id, name: item?.name }));
    setProfileOption(temp);
  }, [allUserDetails]);

  useEffect(() => {
    if (selectedAdgroup?.length == 1) {
      setIsmultipleAdgroup(false);
      const adgroupId = selectedAdgroup[0]?.id;
      if (adgroupId) {
        dispatch(getSuggestedKeyword({ adgroupId }));
      }
    } else if (selectedAdgroup?.length >= 2) {
      setIsmultipleAdgroup(true);
      dispatch(setSuggestedkeywordData([]));
    } else {
      setIsmultipleAdgroup(false);
      dispatch(setSuggestedkeywordData([]));
    }
  }, [selectedAdgroup]);

  useEffect(() => {
    if (profileValue !== null) {
      const id = profileValue?.id;
      const advertiserId = profileValue?.id;
      const campaign_name = '';
      dispatch(getAdvertiserById({ id, campaign_name }));
      dispatch(getSelectFromItemsFromId({ advertiserId }));
    }
  }, [profileValue]);

  // useEffect(() => {
  //     setCampaignData([])
  //     if (campaignByIdData?.length > 0) {
  //         setCampaignData(campaignByIdData);
  //     }
  // }, [campaignByIdData])

  useEffect(() => {
    if (campaignValue?.length > 0) {
      const id = campaignValue?.map((item) => item?.id);
      dispatch(getAdgorupCampaignList({ id }));
    } else if (keywordModal?.id) {
      const id = keywordModal?.id;
      dispatch(getAdgorupCampaignList({ id }));
    }
  }, [keywordModal, campaignValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const onButtonClick = (event) => {
    event?.preventDefault();
    if (selectedAdgroup?.length > 0 && selectedKeywordData?.length > 0 && !errorMinBid) {
      const tempArray = selectedKeywordData?.map((item) => {
        const temp = {
          keywordtext: item.keywordtext,
          matchtype: item.matchtype,
          bid_amount: Number(item.bid_amount)
        };
        return temp;
      });
      var adgroupIds = [];
      selectedAdgroup?.map((item) => {
        adgroupIds.push(item?.id);
      });
      setLoading(true);
      const _request = {
        campaign_id: campaignValue.length > 0 ? campaignValue[0].id : keywordModal?.id,
        adgroup_id: adgroupIds,
        keyword: tempArray
      };
      console.log('_request ', _request);
      dispatch(postMultipleKeyword({ _request })).then((res) => {
        console.log('res ', res);
        setLoading(false);
        GetApi(); //Update Keyword Table with Latest Records
        // if (res.payload.status == 201 || res.payload.status == 200) {
        onClose();
        // }
      });
    } else {
      setError({
        adGroup: selectedAdgroup?.length > 0 ? false : true,
        adItem: selectedKeywordData?.length > 0 ? false : true,
        campaign: campaignValue?.length > 0 ? false : true,
        profile: profileValue?.length > 0 ? false : true
      });
    }
  };

  const [errorMinBid, setErrorMinBid] = useState(false);
  //   console.log('setErrorMinBid ', setErrorMinBid);

  const selectedColumns = [
    {
      name: 'keywordtext',
      label: 'Keywords',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedKeywordData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography>{value}</Typography>
                  <Typography sx={{ color: colors.primaryMain }}>{data?.item_id}</Typography>
                </Box>
              </Box>
            </>
          );
        }
      }
    },

    {
      name: 'matchtype',
      label: 'Match Type',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedKeywordData[tableMeta?.rowIndex];
          return (
            <TextField
              size="small"
              value={data?.bid_amount}
              type="number"
              onWheel={(e) => e.target.blur()}
              onBlur={(e) => {
                if (Number(e.target.value) < 0.3) {
                  const newPrice = '0.30';
                  const updatedData = [...selectedKeywordData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedKeywordData(updatedData);
                }
              }}
              onChange={(e) => {
                const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                const updatedData = [...selectedKeywordData];
                updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                setSelectedKeywordData(updatedData);
                // if (e.target.value < 1.3) {
                //   setErrorMinBid(true);
                // } else {
                setErrorMinBid(false);
                // }

                //   let temp = JSON.parse(JSON.stringify(errorMinBid));
                //   if (e.target.value < 1.3) {
                //     console.log('temp111 ', temp);
                //     temp.push(tableMeta?.rowIndex);
                //     console.log('temp ', temp);
                //     setErrorMinBid(temp);
                //   } else {
                //     let index = temp?.indexOf(tableMeta?.rowIndex);
                //     temp?.splice(index, 1);
                //     console.log('temp www', temp);
                //     setErrorMinBid(temp);
                //   }
                // }}
                // error={errorMinBid?.includes(tableMeta?.rowIndex)}
                // helperText={errorMinBid?.includes(tableMeta?.rowIndex) ? 'please Apply above 1.3' : ''}
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              inputProps={{
                step: '0.01'
              }}
            />
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <IconButton
              onClick={() => {
                var tempData = [...selectedKeywordData];
                tempData = tempData?.filter((item, index) => index != tableMeta?.rowIndex);
                setSelectedKeywordData(tempData);
              }}
            >
              <CloseIcon />
            </IconButton>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={selectedKeywordData?.length == 0}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setSelectedKeywordData([])}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: false,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    search: false,
    viewColumns: false
  };

  return (
    <>
      <form noValidate onSubmit={onButtonClick}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            {keywordModal?.name && !keywordModal?.campaign_id && (
              <Grid item sm={12}>
                <Typography variant="h4">Campaign: {keywordModal?.name}</Typography>
              </Grid>
            )}
            {addKeywordModel && (
              <>
                <Grid item sm={12}>
                  {/* {renderLabel('Profile', true)} */}
                  <Autocomplete
                    id="combo-box-demo"
                    fullWidth
                    onChange={(e, value) => {
                      if (!__.isEmpty(value)) {
                        setProfileValue(value);
                        setCampaignValue([]); //Also Clear Selected Campaign
                        setSelectedAdgroup([]); //Also Clear Selected Adgroup
                      } else {
                        setProfileValue(null);
                        setCampaignValue([]); //Also Clear Selected Campaign
                        setSelectedAdgroup([]); //Also Clear Selected Adgroup
                      }
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    value={profileValue}
                    options={profileOption?.length > 0 ? profileOption : []}
                    // {...register("campaign")}
                    // {...field}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField error={error.campaign} label="Profile" fullWidth {...params} placeholder="Select Profile" />
                    )}
                  />
                  {error.campaign && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      Please select Profile
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={12}>
                  {/* {renderLabel('Campaign', true)} */}
                  <Autocomplete
                    // multiple
                    id="combo-box-demo"
                    fullWidth
                    onChange={(e, value) => {
                      if (!__.isEmpty(value)) {
                        setCampaignValue([value]);
                      } else {
                        setCampaignValue([]);
                        setSelectedAdgroup([]); //Also Clear Selected Adgroup
                      }
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    value={campaignValue?.[0] || null}
                    options={campaignByIdData?.length > 0 ? campaignByIdData : []}
                    // {...register("campaign")}
                    // {...field}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField error={error.campaign} label="Campaign" fullWidth {...params} placeholder="Select Campaign" />
                    )}
                  />
                  {error.campaign && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      Please select Campaign
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            <Grid item sm={12}>
              {/* {renderLabel('Adgroup Name', true)} */}
              <>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  name="adgroup_name"
                  options={campaignValue?.length > 0 ? adgroupList : []}
                  getOptionLabel={(option) => option.name}
                  value={selectedAdgroup}
                  onChange={(e, value) => {
                    setSelectedAdgroup(value);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      error={error.adGroup}
                      label="Adgroup Name"
                      color="secondary"
                      {...params}
                      placeholder="Adgroup Name"
                    />
                  )}
                />
                {error.adGroup && (
                  <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                    Please select adgroup
                  </FormHelperText>
                )}
              </>
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Keyword', true)}

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Enter Keywords" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <EnterKeywordTable
                        data={adgroupList}
                        selectedKeywordData={selectedKeywordData}
                        setSelectedKeywordData={setSelectedKeywordData}
                      />
                    </TabPanel>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SelectFromTable
                        isMultipleAdgroup={isMultipleAdgroup}
                        selectedKeywordData={selectedKeywordData}
                        setSelectedKeywordData={setSelectedKeywordData}
                      />
                    </TabPanel>
                  </Box>
                </TabContext>
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedKeywordData}
                      columns={selectedColumns}
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
              {error.adItem && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  Please select at lease one keyword
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              {errorMinBid && <Typography sx={{ color: 'red', marginRight: '16px' }}>Bid amount should be min 1.3! </Typography>}
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={onClose}>
                CANCEL
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit" disabled={loading && errorMinBid}>
                {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                UPDATE
              </Button>
            </>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default TargetAdGroupSetting;
