import { Box, Button, CircularProgress, Grid, Menu, MenuItem, Tooltip } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { useState, useEffect, useRef } from 'react';
import {
  SendDashboardReport,
  SendDashboardReportPDF,
  getAdGroupList,
  getAdItemList,
  getAdvertiserState,
  getCampaignNameList,
  getDateWiseData,
  getKeywordShortList
} from 'store/thunk/userThunk';
import { useAppSelector, useAppDispatch } from 'store';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-multi-carousel/lib/styles.css';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import AccountPerformance from './accountPerformance';
import PerformanceTargeting from './performanceTargeting';
import AutoCampaignPerformance from './autoCampaignPerformance';
import ManualCampaignPerformance from './manualCampaignPerformance';
import ItemPerformance from './itemPerformance';
import LifeTimePerformance from './lifeTimePerformance';
import WeeklyPerformance from './weeklyPerformance';
import SearchIcon from '@mui/icons-material/Search';
import { Backdrop } from '@mui/material';
import SvCampaignPerformance from './svCampaignPerformance';
import SbCampaignPerformance from './sbCampaignPerformance';
import DownloadIcon from 'assets/images/dashna/Arrow.svg';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';

export default function DashboardData() {
  const theme = useTheme();
  const primary200 = theme.palette.primary[200];
  const primaryDark = theme.palette.primary.dark;
  const secondaryMain = theme.palette.secondary.main;
  const refresh = () => window.location.reload(true);
  const isSuperUser = useSelector((state) => state.superUserSlice.isSuperUser);
  const dispatch = useAppDispatch();
  const { advertiserState, commonAdvertiser, commonDateRange, dashboardReport, campaignNameList, adGroupShortList } =
    useAppSelector((state) => state?.userDetailsSlice);

  const { userData } = useAppSelector((state) => state.authorization);

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const previousDateRange = useRef(commonDateRange);
  const isFirstRender = useRef(true);
  const user = userData?.permission?.dashboard_report || {};
  const downloadReport = user?.download;

  const [open, setOpen] = useState(false);
  const [filterValue, setFilterValue] = useState({ campaign__in: [], ad_group: [], ad_item: [], keyword__in: [] });
  const [tableFilter, setTableFilter] = useState({ campaign__in: '', ad_group: '', ad_item: '', keyword__in: '' });
  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    campaign_search: [],
    ad_group_search: []
  });
  const [isDropdownOpenAdgroup, setIsDropdownOpenAdgroup] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState({ campaign_search: null, ad_group_search: null, keyword_search: null });
  const [selectedField, setSelectedField] = useState([
    { index: 0, color: primary200, name: '', display_field: '' },
    { index: 1, color: primaryDark, name: '', display_field: '' },
    { index: 2, color: secondaryMain, name: '', display_field: '' }
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  /* ================================ Autocomplete Filters Start ================================*/
  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'campaign_search' || filterKey === 'ad_group_search') {
      paramKey = 'id';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'ad_group_search') {
      baseArray = [...adGroupShortList];
      tableStateFilterKey = 'ad_group';
    }

    let targetString = '';
    if (filterKey === 'campaign_type') {
      const targetArray = newValue?.map((item) => item?.type);
      targetString = targetArray.filter((item) => item !== undefined).join(',');
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: '' }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: targetString }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'ad_group_search') {
      baseArray = [...adGroupShortList];
      tableStateFilterKey = 'ad_group';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  useEffect(() => {
    handleOpen();
    // dispatch(getKeywordShortList([]));
  }, []);

  const callDaterangeRelatedAPI = () => {
    if (commonDateRange?.startDate && commonDateRange?.endDate) {
      const _request = {
        start_date: commonDateRange?.startDate,
        end_date: commonDateRange?.endDate
      };
      if (commonAdvertiser?.length > 0) {
        console.log(1234);
        _request.advertiser_id = commonAdvertiser;
        dispatch(getAdvertiserState({ _request }));

        dispatch(getDateWiseData({ _request }))
          .then((resp) => {
            if (resp?.payload) {
              handleClose();
            }
          })
          .catch((err) => {
            if (err) {
              handleClose();
            }
          });
      }
    }
  };

  useEffect(() => {
    if (
      commonAdvertiser?.length > 0 &&
      (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current)
    ) {
      //Fetch Advertiser Profiles
      let id = commonAdvertiser;
      dispatch(getCampaignNameList({ id }));

      if (id.length) {
        dispatch(getAdGroupList({ id }));
      }
      dispatch(getAdItemList({ id }));
      dispatch(getKeywordShortList({ id }));
      setFilterValue({ campaign__in: [], ad_group: [], ad_item: [], keyword__in: [] });
      setTableFilter({ campaign__in: '', ad_group: '', ad_item: '', keyword__in: '' });

      callDaterangeRelatedAPI();
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  useEffect(() => {
    if (JSON.stringify(previousDateRange.current) !== JSON.stringify(commonDateRange)) {
      callDaterangeRelatedAPI();
      previousDateRange.current = commonDateRange;
    }
  }, [commonDateRange]);

  useEffect(() => {
    if (advertiserState?.total_data?.length > 0) {
      let tempArray = [];
      tempArray = selectedField?.map((item) => {
        return {
          ...item,
          name: advertiserState?.total_data[item?.index]?.name,
          display_field: advertiserState?.total_data[item?.index]?.display_field,
          value: advertiserState?.total_data[item?.index]?.value
        };
      });
      setSelectedField(tempArray);
    }
  }, [advertiserState]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isSuperUser !== null) {
      handleOpen();
      refresh();
    }
  }, []);

  const handleSearchClick = () => {
    const _request = {
      start_date: commonDateRange?.startDate,
      end_date: commonDateRange?.endDate,
      advertiser_id: commonAdvertiser
    };
    if (tableFilter?.campaign__in) {
      _request.campaign__in = tableFilter.campaign__in;
    }
    if (tableFilter?.ad_group) {
      _request.ad_group = tableFilter.ad_group;
    }
    if (tableFilter?.ad_item) {
      _request.ad_item = tableFilter.ad_item;
    }
    if (tableFilter?.keyword__in) {
      _request.keyword__in = tableFilter.keyword__in;
    }
    dispatch(getDateWiseData({ _request }))
      .then((resp) => {
        if (resp?.payload) {
          handleClose();
        }
      })
      .catch((err) => {
        if (err) {
          handleClose();
        }
      });
  };

  const HandleDownload = () => {
    dispatch(SendDashboardReport(dashboardReport)).then((resp) => {
      const fileContent = resp?.payload?.data;
      const blob = new Blob([fileContent], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const fileName = `DashboardData.xlsx`;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    handleCloseDownload();
  };

  const HandleDownloadPdf = () => {
    dispatch(SendDashboardReportPDF(dashboardReport)).then((resp) => {
      const fileContent = resp?.payload?.data;
      const blob = new Blob([fileContent], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const fileName = `DashboardData.pdf`;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    handleCloseDownload();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorEl(null);
  };

  return (
    <MainCard title="Dashboard">
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid container sx={{ p: 1 }} spacing={3}>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="Campaign"
              label="Campaign"
              className="custom-auto"
              optionLabelkey="name"
              optionIdkey="id"
              options={campaignNameList || []}
              value={filterValue?.campaign__in || []}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              inputValue={isDropdownOpen ? searchText?.campaign_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, campaign_search: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'campaign_search');
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_search')}
              placeholderCondition={filterValue?.campaign__in?.length > 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomAutocomplete
              multiple
              id="AdGroup_name"
              name="AdGroup_name"
              color="secondary"
              label="Ad Group"
              options={adGroupShortList || []}
              value={filterValue?.ad_group || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'ad_group_search');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenAdgroup ? searchText?.ad_group_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, ad_group_search: value });
                }
              }}
              onOpen={() => setIsDropdownOpenAdgroup(true)}
              onClose={() => setIsDropdownOpenAdgroup(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('ad_group_search')}
              placeholderCondition={filterValue?.ad_group?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex' }}>
          <Button variant="contained" color="secondary" onClick={handleSearchClick} className="setMarginRight">
            <SearchIcon sx={{ mr: 0.5 }} />
            Search
          </Button>
          {downloadReport && (
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button onClick={handleClick} sx={{ paddingRight: '4px' }}>
                <Tooltip sx={{ fontSize: '10px' }} title={'Download'} arrow>
                  <img src={DownloadIcon} alt="filter icon" />{' '}
                </Tooltip>
              </Button>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseDownload}>
                <MenuItem onClick={HandleDownloadPdf}>
                  {/* <PdfIcon /> */}
                  <PictureAsPdfIcon sx={{ marginRight: '4px' }} />
                  PDF
                </MenuItem>
                <MenuItem onClick={HandleDownload}>
                  {/* <CsvIcon /> */}
                  <FileOpenIcon sx={{ marginRight: '4px' }} />
                  Excel
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      </Box>
      <>
        {dashboardReport?.life_time_report && (
          <Box sx={{ mt: 5 }}>
            <LifeTimePerformance lifeTimeReport={dashboardReport?.life_time_report} />
          </Box>
        )}
        {dashboardReport?.monthly_report && (
          <Box sx={{ mt: 5 }}>
            <AccountPerformance monthlyReport={dashboardReport?.monthly_report} />
          </Box>
        )}
        {dashboardReport?.weekly_report && (
          <Box sx={{ mt: 5 }}>
            <WeeklyPerformance weeklyReport={dashboardReport?.weekly_report} />
          </Box>
        )}
        {dashboardReport?.performance_by_targeting && (
          <Box sx={{ mt: 5 }}>
            <PerformanceTargeting performanceByTargeting={dashboardReport?.performance_by_targeting} />
          </Box>
        )}
        {dashboardReport?.auto_campaign_performance && (
          <Box sx={{ mt: 5 }}>
            <AutoCampaignPerformance autoCampaignPerformance={dashboardReport?.auto_campaign_performance} />
          </Box>
        )}
        {dashboardReport?.manual_campaign_performance && (
          <Box sx={{ mt: 5 }}>
            <ManualCampaignPerformance manualCampaignPerformance={dashboardReport?.manual_campaign_performance} />
          </Box>
        )}

        {dashboardReport?.sv_campaign_performance && (
          <Box sx={{ mt: 5 }}>
            <SvCampaignPerformance keywordPerformance={dashboardReport?.sv_campaign_performance} />
          </Box>
        )}

        {dashboardReport?.sba_campaign_performance && (
          <Box sx={{ mt: 5 }}>
            <SbCampaignPerformance keywordPerformance={dashboardReport?.sba_campaign_performance} />
          </Box>
        )}

        {dashboardReport?.item_performance && (
          <Box sx={{ mt: 5 }}>
            <ItemPerformance itemPerformance={dashboardReport?.item_performance} />
          </Box>
        )}
      </>
    </MainCard>
  );
}
