import moment from 'moment';
import { namesForOnDemand, routeNameMapping } from 'store/constant';
import CryptoJS from 'crypto-js';
import { secretKey } from 'store/constant';

export const menuItems = {
  list: ['/company_list'],
  overview: ['/dashboard'],
  advertising: ['/profile', '/campaign', '/adgroup', '/keyword', '/items', '/bulk-operations'],
  report: ['/shared-dashboards', '/on-demand-reports', '/dashboard-report', '/default-report', '/my-report'],
  automation: ['/budget-manager'],
  'rank-tracker': ['/rank-tracker'],
  settings: ['/store', '/user-management', '/video-management']
};

export const parentMenuByChild = (child) => {
  for (const category in menuItems) {
    if (menuItems[category].includes(child)) {
      return category;
    }
  }
  return null;
};

const dollarKeys = [
  'attributed_sales_14days',
  'ad_spend',
  'advertised_sku_sales_14days',
  'other_sku_sales_14days',
  'roas',
  'estimated_spend',
  'spend',
  'ntb_revenue_14days',
  'ad_sales',
  'average_cpc',
  'total_product_amount',
  'troas',
  'yesterday_spend',
  'monthly_budget',
  'remaining_total_budget',
  'advertised_sku_sales',
  'attributed_sales',
  'instore_advertised_sales',
  'instore_attributed_sales',
  'instore_other_sales',
  'other_sku_sales',
  'ntb_revenue',
  'total_attributed_sales'
  // 'total_budget',
  // 'daily_budget'
];

const percentageKeys = [
  'cvr_units',
  'cvr_orders',
  'ctr',
  // 'unitsold',
  'percentage',
  'acos',
  'delivery_rate',
  'percentage_new_brand_orders',
  'percentage_new_brand_sales',
  'availability_rate',
  'availability_pct_last_week',
  'availability_pct',
  'buy_box_pct_last_week',
  'buy_box_pct',
  'tacos'
];

export const formateValue = (key, value) => {
  const fixedNumber = typeof value === 'number' && value % 1 != 0 ? parseFloat(value.toFixed(2).toLocaleString('en-US')) : value;
  const type = dollarKeys.includes(key)
    ? `$${Number(fixedNumber)?.toLocaleString('en-US')}`
    : percentageKeys.includes(key)
    ? `${fixedNumber}%`
    : fixedNumber?.toLocaleString('en-US');
  return type;
};

export const formatNumber = (value) => {
  return value % 1 === 0 ? value : Number(value.toFixed(2));
};

//to display 0.1 to 0.10
export const formatNumberWithTwoDecimal = (value) => {
  return value % 1 === 0 ? value : value.toFixed(2);
};

export const formateValueCampaignTotal = (key, value) => {
  const fixedNumber = typeof value === 'number' && value % 1 != 0 ? parseFloat(value?.toFixed(2).toLocaleString('en-US')) : value;
  const type = dollarKeysForTotal.includes(key)
    ? `$${Number(fixedNumber)?.toLocaleString('en-US')}`
    : percentageKeysForTotal.includes(key)
    ? `${fixedNumber}%`
    : fixedNumber?.toLocaleString('en-US');
  return type;
};

export const formateValueWithThousand = (key, value) => {
  let fixedNumber = value;
  let postLetter = '';
  // Check if the value contains suffixes and handle accordingly
  if (typeof fixedNumber === 'string') {
    fixedNumber = fixedNumber.replace(/,/g, '');
    if (fixedNumber.includes('K')) {
      postLetter = 'K';
      fixedNumber = parseFloat(fixedNumber.replace('K', ''));
    } else if (fixedNumber.includes('M')) {
      postLetter = 'M';
      fixedNumber = parseFloat(fixedNumber.replace('M', ''));
    } else if (fixedNumber.includes('B')) {
      postLetter = 'B';
      fixedNumber = parseFloat(fixedNumber.replace('B', ''));
    } else {
      fixedNumber = parseFloat(fixedNumber.replace(/,/g, ''));
    }
  }

  const formattedNumber = `${formateValue(key, fixedNumber)}${postLetter}`;

  return formattedNumber;
};

export const formatThousandNumber = (value) => {
  if (value >= 1_000_000_000) {
    return (value / 1_000_000_000).toFixed(2) + 'B';
  } else if (value >= 1_000_000) {
    return (value / 1_000_000).toFixed(2) + 'M';
  } else if (value >= 1_000) {
    return (value / 1_000).toFixed(2) + 'K';
  } else {
    return value?.toString();
  }
};

export const formateValueForCustomReport = (key, value) => {
  const fixedNumber = typeof value === 'number' && value % 1 != 0 ? value.toFixed(2) : value;
  const type = dollarKeys.includes(key) ? `$${fixedNumber}` : percentageKeys.includes(key) ? `${fixedNumber}%` : fixedNumber;
  return type;
};

export const getUserType = (value) => {
  const valueLabels = {
    1: 'Super Admin',
    2: 'Owner',
    3: 'Admin',
    4: 'User',
    5: 'Viewer'
  };
  return valueLabels[value] || 'User';
};

export const endDateValidator = (date) => {
  return date == '9999-12-31' || date == '9999-12-30' ? true : false;
};

export const DownloadCsvDataCommon = (response, FileName) => {
  const fileContent = response;
  const blob = new Blob([fileContent], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  const fileName = `${FileName}.csv`;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const commaStringToNumber = (strInput) => {
  // return Number(strInput.replace(',', ''));
  if (typeof strInput === 'number') {
    return strInput;
  }

  if (strInput?.includes(',')) {
    return Number(strInput?.replace(/,/g, ''));
  }
  return Number(strInput);
};

export function extractDate(apiResponseDate) {
  return moment(apiResponseDate).format('YYYY-MM-DD');
}

export function dateDifference(apiResponseDate) {
  const a = moment(apiResponseDate);
  const b = moment();
  const differenceInDays = a.diff(b, 'days');

  return differenceInDays;
}

export function getValueByName(nameToFind) {
  const foundItem = namesForOnDemand.find((item) => item.name === nameToFind);
  return foundItem ? foundItem.value : nameToFind;
}

export function getUpdatedColumns(columns, selectedColumns, groupby, page) {
  if (page === 'keywords' && groupby !== 'aditem') {
    selectedColumns = selectedColumns.filter((l) => l?.label !== 'Item');
  }

  const updatedColumns = columns?.map((column) => {
    const colIndex = selectedColumns?.findIndex((col) => col?.name === column?.name);
    if (colIndex !== -1) {
      return {
        ...column,
        options: {
          ...column.options,
          display: selectedColumns[colIndex]?.options?.display === undefined ? true : selectedColumns[colIndex]?.options?.display //When new user created then it is undefined so by default set to true
        }
      };
    }
    return column;
  });

  return updatedColumns;
}

const dollarKeysForTotal = [
  'attributed_sales_14days',
  'ad_spend',
  'advertised_sku_sales_14days',
  'other_sku_sales_14days',
  'roas',
  'spend',
  'ntb_revenue_14days',
  'ad_sales',
  'total_product_amount',
  'troas',
  'average_cpc',
  'yesterday_spend',
  'monthly_budget',
  'remaining_total_budget',
  'advertised_sku_sales',
  'attributed_sales',
  'instore_advertised_sales',
  'instore_attributed_sales',
  'instore_other_sales',
  'other_sku_sales',
  'ntb_revenue',
  'total_budget',
  'daily_budget'
];

const percentageKeysForTotal = [
  'cvr_units',
  'cvr_orders',
  'ctr',
  // 'unitsold',
  'percentage',
  'acos',
  'delivery_rate',
  'percentage_new_brand_orders',
  'percentage_new_brand_sales',
  'availability_rate',
  'availability_pct_last_week',
  'availability_pct',
  'buy_box_pct_last_week',
  'buy_box_pct',
  'tacos'
];

// Added code for

const data = [
  { name: 'num_ads_shown', is_up: true, is_down: false },
  { name: 'impressions', is_up: true, is_down: false },
  { name: 'attributed_orders', is_up: true, is_down: false },
  { name: 'num_ads_clicks', is_up: true, is_down: false },
  { name: 'clicks', is_up: true, is_down: false },
  { name: 'units_sold', is_up: true, is_down: false },
  { name: 'ad_sales', is_up: true, is_down: false },
  { name: 'total_attributed_sales', is_up: true, is_down: false }, //Keyword page Ad Sales column
  { name: 'attributed_sales', is_up: true, is_down: false }, //Item page Ad Sales column
  { name: 'cpa_orders', is_up: false, is_down: true },
  { name: 'cpa_units', is_up: false, is_down: true },
  { name: 'ad_spend', gray: true, gray1: false },
  { name: 'advertised_sku_sales', is_up: true, is_down: false },
  { name: 'roas', is_up: true, is_down: false },
  { name: 'acos', is_up: false, is_down: true },
  { name: 'average_cpc', is_up: false, is_down: true },
  { name: 'cvr_orders', is_up: true, is_down: false },
  { name: 'cvr_units', is_up: true, is_down: false },
  { name: 'instore_advertised_sales', is_up: true, is_down: false },
  { name: 'instore_orders', is_up: true, is_down: false },
  { name: 'instore_other_sales', is_up: true, is_down: false },
  { name: 'instore_units_sold', is_up: true, is_down: false },
  { name: 'ad_spend', is_up: true, is_down: false },
  { name: 'attributed_sales_14days', is_up: true, is_down: false },
  { name: 'ctr', is_up: true, is_down: false },
  { name: 'units_sold_14days', is_up: true, is_down: false },
  { name: 'attributed_orders_14days', is_up: true, is_down: false },
  { name: 'total_product_amount', is_up: true, is_down: false },
  { name: 'total_sales', is_up: true, is_down: false },
  { name: 'troas', is_up: true, is_down: false },
  { name: 'tacos', is_up: false, is_down: true },
  { name: 'advertised_sku_sales_14days', is_up: true, is_down: false },
  { name: 'other_sku_sales_14days', is_up: true, is_down: false },
  { name: 'ntb_revenue_14days', is_up: true, is_down: false },
  { name: 'ntb_orders_14days', is_up: true, is_down: false },
  { name: 'ntb_units_14days', is_up: true, is_down: false },
  { name: 'percentage_new_brand_orders', is_up: true, is_down: false },
  { name: 'percentage_new_brand_units', is_up: true, is_down: false },
  { name: 'total_attributed_orders', is_up: true, is_down: false },
  { name: 'percentage_new_brand_sales', is_up: true, is_down: false },
  { name: 'brand_view_revenue_14days', is_up: true, is_down: false },
  { name: 'click_revenue_14days', is_up: true, is_down: false },
  { name: 'view_revenue_14days', is_up: true, is_down: false },
  { name: 'instore_advertised_sales_14days', is_up: true, is_down: false },
  { name: 'instore_attributed_sales_14days', is_up: true, is_down: false },
  { name: 'instore_orders_14days', is_up: true, is_down: false },
  { name: 'instore_other_sales_14days', is_up: true, is_down: false },
  { name: 'instore_units_sold_14days', is_up: true, is_down: false }
];

export function allValuesTrueByValue(name, value) {
  const obj = data.find((o) => o?.name === name);
  if (!obj) {
    return false;
  }
  const key = Object.keys(obj).find((k) => obj[k] === value);
  if (!key) {
    return false;
  }
  return key;
}

// Output: false, since not all "is_up" values are true

const alignColumn = [
  { name: 'num_ads_shown' },
  { name: 'num_ads_clicks' },
  { name: 'units_sold' },
  { name: 'cpa_orders' },
  { name: 'attributed_orders' },
  { name: 'cpa_units' },
  { name: 'ctr' },
  { name: 'ad_spend' },
  { name: 'advertised_sku_sales' },
  { name: 'roas' },
  { name: 'acos' },
  { name: 'average_cpc' },
  { name: 'cvr_orders' },
  { name: 'cvr_units' },
  { name: 'instore_advertised_sales' },
  { name: 'instore_attributed_sales' },
  { name: 'instore_orders' },
  { name: 'instore_other_sales' },
  { name: 'instore_units_sold' },
  { name: 'impressions' },
  { name: 'clicks' },
  { name: 'ad_sales' },
  { name: 'tacos' },
  { name: 'troas' },
  { name: 'total_sales' },
  { name: 'total_attributed_orders' }
];

export function alignRightColumn(name) {
  const obj = alignColumn.find((o) => o?.name === name);
  if (!obj) {
    return false;
  }
  return obj;
}

export function capitalizeFirstChar(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const keysRemoveDollarSign = [
  'attributed_orders_14days',
  'num_ads_clicks',
  'acos',
  'roas',
  'units_sold_14days',
  'attributed_orders_14days',
  'ctr',
  'cvr_orders',
  'cvr_units',
  'num_ads_clicks',
  'num_ads_shown',
  'units_sold_14days'
];

//  function getRouteName(route) {
//   // Extract the base route before the second '/' (if any)
//   const baseRoute = route.split('/').slice(0, 2).join('/');
//   // Return the mapped name or fallback to the route itself
//   return routeNameMapping[baseRoute] || '';
// }

export function getRouteName(route) {
  // Split the route into segments
  const segments = route.split('/').filter(Boolean);
  // Initialize an array to hold the mapped names
  let mappedNames = [];

  // Iterate over each segment and build the full route path
  segments.forEach((segment, index) => {
    const partialRoute = `/${segments.slice(0, index + 1).join('/')}`;
    if (routeNameMapping[partialRoute]) {
      mappedNames.push(routeNameMapping[partialRoute]);
    }
  });

  // If no mappings were found, return an empty string
  return mappedNames.length > 0 ? mappedNames.join(' > ') : '';
}

//Convert simple text to encrypted text
export const encryptText = (text) => {
  const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
  return encrypted;
};

//Convert encrypted text to simple text
export const decryptText = (encryptedText) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};

//Generate Unique Random String for State and Nounce for Connect to Walmart Login URL
export const generateRandomString = (length) => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
};
