import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  FormHelperText,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Link,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import SearchTable from './searchTable';
import SelectFromTable from './selectFromTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { getAdgorupCampaignList, postMultipleAditem } from 'store/thunk/userThunk';
import EnterKeywordTable from './enterItemTable';
import { CAMPAIGN } from 'constant/campaignConstant';
import { PageValue } from 'store/constant';
import SuggestFromTable from '../suggestedTable';

const ProductAdGroupSetting = (props) => {
  const { itemModal, onClose, GetApi } = props;
  const { selectdItemData, adgroupList } = useAppSelector((state) => state?.userDetailsSlice);
  const [value, setValue] = useState('2');
  const [loading, setLoading] = useState(false);
  const [searchableData, setSearchableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedAdgroup, setSelectedAdgroup] = useState([]);
  const [keyword, setkeyword] = useState('');
  const [rowPerPage, setRowPerPage] = useState(25);

  const [error, setError] = useState({ adGroup: false, adItem: false });

  const dispatch = useAppDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedAdgroup?.length > 0 && error.adGroup == true) {
      setError({ ...error, adGroup: false });
    }
  }, [selectedAdgroup]);

  useEffect(() => {
    if (selectedData?.length > 0 && error.adItem == true) {
      setError({ ...error, adItem: false });
    }
  }, [selectedData]);

  useEffect(() => {
    const id = itemModal.id;
    if (id) {
      dispatch(getAdgorupCampaignList({ id }));
    }
  }, [itemModal]);

  //Auto selected Adgroup Name
  useEffect(() => {
    if (adgroupList?.length && adgroupList?.length === 1) {
      setSelectedAdgroup(adgroupList);
    } else {
      //Select Particular Adgroup if we have more adgroups and we have particular adgroup_id
      if (itemModal?.adgroup_id) {
        const getAdgroup = adgroupList?.filter((l) => l?.adgroup_id === itemModal?.adgroup_id);
        setSelectedAdgroup(getAdgroup);
      }
    }
  }, [adgroupList]);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const onButtonClick = (event) => {
    event.preventDefault();

    if (selectedAdgroup?.length > 0 && selectedData?.length > 0) {
      setLoading(true);
      const temp = selectedData?.map((item) => {
        return { item_id: item?.item_id, bid_amount: item.bid_amount ? item.bid_amount : '0.20' };
      });
      var adgroupIds = [];
      selectedAdgroup?.map((item) => {
        adgroupIds.push(item?.id);
      });

      const _request = {
        adgroup_id: adgroupIds,
        campaign_id: itemModal.id,
        aditems: temp
      };

      dispatch(postMultipleAditem({ _request })).then(() => {
        setLoading(false);
        onClose();
        GetApi();
      });
    } else {
      setError({ adGroup: selectedAdgroup?.length > 0 ? false : true, adItem: selectedData?.length > 0 ? false : true });
    }
  };

  const onAddAllClick = () => {
    var tempArray = [...selectedData];
    if (searchableData?.length >= 1) {
      searchableData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20',
            suggested_bid: item?.suggested_bid
          });
        }
      });
    } else {
      selectdItemData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20',
            suggested_bid: item?.suggested_bid
          });
        }
      });
    }
    setSelectedData(tempArray);
  };

  const selectedColumns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {data?.product_name?.length > 20 ? (
                    <>
                      <Tooltip
                        placement="top"
                        sx={{ fontSize: '10px' }}
                        title={
                          <Typography variant="body1" sx={{ fontSize: 16 }}>
                            {data?.product_name}
                          </Typography>
                        }
                        arrow
                      >
                        {data?.product_name?.substring(0, 25)}...
                      </Tooltip>
                    </>
                  ) : (
                    data?.product_name
                  )}

                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <TextField
                size="small"
                value={data?.bid_amount}
                type="number"
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => {
                  if (Number(e.target.value) < 0.2) {
                    const newPrice = '0.20';
                    const updatedData = [...selectedData];
                    updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                    setSelectedData(updatedData);
                  }
                }}
                onChange={(e) => {
                  const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                  const updatedData = [...selectedData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedData(updatedData);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                inputProps={{
                  step: '0.01'
                }}
              />
            </>
          );
        }
      }
    },
    {
      name: 'item_id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  var tempData = [...selectedData];
                  tempData = tempData?.filter((item) => item?.item_id != value);
                  setSelectedData(tempData);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={!selectedData?.length}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setSelectedData([])}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    viewColumns: false,
    serverSide: false,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    rowsPerPage: rowPerPage,
    toolbar: false,
    search: false,
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
    }
  };

  return (
    <>
      <form noValidate onSubmit={onButtonClick}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item sm={12}>
              <Typography variant="h4">Campaign: {itemModal?.name}</Typography>
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Adgroup Name', true)}

              <>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  name="adgroup_name"
                  options={adgroupList}
                  getOptionLabel={(option) => option.name}
                  value={selectedAdgroup}
                  onChange={(e, value) => {
                    setSelectedAdgroup(value);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField error={error.adGroup} color="secondary" {...params} placeholder="Adgroup Name" />
                  )}
                />
                {error.adGroup && (
                  <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                    Please select adgroup
                  </FormHelperText>
                )}
              </>
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Item', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Search" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Select from" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="3" />
                        <Tab label="Enter Items" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SearchTable
                        selectedData={selectedData}
                        advertiserId={setSelectedData}
                        advertiser_id={itemModal?.advertiser_id}
                      />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <SelectFromTable
                        setSearchableData={setSearchableData}
                        advertiserId={itemModal?.advertiser_id}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        onAddAllClick={onAddAllClick}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <SuggestFromTable
                        setSearchableData={setSearchableData}
                        advertiserId={itemModal?.advertiser_id}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        onAddAllClick={onAddAllClick}
                      />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: 0 }}>
                      <EnterKeywordTable
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        keyword={keyword}
                        setkeyword={setkeyword}
                        advertiserId={itemModal?.advertiser_id}
                      />
                    </TabPanel>
                  </Box>
                </TabContext>

                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '20px 30px 20px 30px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant="h5">{selectedData?.length} Item(s)Added</Typography>
                    <Typography variant="h6" sx={{ color: colors.grey500 }}>
                      Item bid is only valid for Auto Campaigns
                    </Typography>
                  </Box>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedData}
                      // columns={selectedColumns}
                      columns={
                        itemModal?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                          ? selectedColumns?.filter((item) => item?.name != 'bid_amount' && item?.name != 'suggested_bid')
                          : selectedColumns
                      }
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
              {error.adItem && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  Please select at lease one items
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={onClose}>
                CANCEL
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit" disabled={loading}>
                {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                Ok
              </Button>
            </>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ProductAdGroupSetting;
